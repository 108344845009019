import {test_artists_config_tr} from './test_config_tr';

export const artists_config_tr = {
    MISC: {
        READ_MORE: 'Devamını Oku',
        READ_LESS: 'Daha az göster',
        REFERENCES: 'Kaynakça',
    },
    FOOTER: {
        ARTIST_ARCHIVE: 'Sanatçı Arşivi',
        PREVIOUS_ARTIST: 'Önceki Sanatçı',
        NEXT_ARTIST: 'Sonraki Sanatçı',
        SEE_ARTWORKS: 'Eserlerini Görmek İçin',
        BACK_TO_ARTIST: 'Sanatçıya Dön',
    },
    ARTIST_LIST: [
        {
            ID: 0,
            TEMPLATE: 0,
            NAME: 'Yalçın Gökçebağ',
            NAME_UPPERCASE: 'YALÇIN GÖKÇEBAĞ',
            TOPIC: 'Yalçın Gökçebağ Hakkında',
            TOPIC_UPPERCASE: 'YALÇIN GÖKÇEBAĞ HAKKINDA',
            ARTWORKS: 'Yalçın Gökçebağ Eserleri',
            ARTWORKS_UPPERCASE: 'YALÇIN GÖKÇEBAĞ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/YALCIN_GOKCEBAG/Yalçin_Gokcebag.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/YALCIN_GOKCEBAG/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/YALCIN_GOKCEBAG/-1.m.webp',
            TEXT: 'Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” ve “Resmin Yaşar Kemal’i” olarak bilinen Yalçın Gökçebağ,' +
                ' eserlerinde geniş bakış açısı, ritmik düzeni, detaycılığı, fırça ve renk kullanımı ile Anadolu’yu kendi tavrıyla yansıtır.' +
                ' Genellikle Hasat ve Bodrum serileri gibi geniş bakış açıları ile kurguladığı peyzaj kompozisyonları ile bilinir.' +
                ' Yalçın Gökçebağ 1944 yılında, Denizli’nin Çal İlçesine bağlı Ortaköy’de doğmuştur.' +
                ' Köyünde gördüğü ilkokul öğreniminin ardından 1954 yılında girdiği Öğretmen Okulu sınavını kazanmış ve' +
                ' Isparta-Gönen İlk Öğretmen okulunda Resim-Müzik öğretmeni olmak üzere öğrenim görmeye başlamıştır.' +
                ' Okulunda verilen resim seminerlerinde 3 yıl boyunca Malik Aksel ve İlhami Demirci\'den gibi usta isimlerden resim dersleri almıştır.' +
                ' 1963’de Gazi Eğitim Enstitüsü Resim Bölümü\'ne girmiştir. 1966’da mezun olduktan sonra, Akşehir ilköğretim okulunda resim öğretmeni olarak görev yapmıştır.' +
                ' Bir süre öğretmenlik yaptıktan sonra 1971 yılında TRT’de kameraman olarak çalışmaya başlamıştır.' +
                ' 1990 yılında başlayarak bir süre Orta Doğu Teknik Üniversitesi (ODTÜ) Güzel Sanatlar Bölümünde öğretim görevlisi olarak çalışmıştır<sup>[1]</sup>.<br>' +
                ' Gökçebağ’ın eserleri izlenimcilik akımına benzetilse de, ressam kendini herhangi bir sanat akımına yerleştiremediğini belirtir.' +
                ' Gökçebağ, çocukluğunun geçtiği Anadolu köylerini ve TRT’deki 22 yıllık kameramanlık kariyerinde yer aldığı Anadolu belgesellerinin sanatını etkileyen en büyük unsurlar olarak gösterir<sup>[2]</sup>.' +
                ' Eserlerinde belgeselci bir bakış açısıyla çalışan Gökçebağ, pek çok folklor unsurunu detaycı bir tutum ile izleyiciye sunabilmektedir.' +
                ' Gökçebağ kompozisyonlarını geniş, tepeden bakan bir açıyla kurgulamıştır; eserlerinde sıkça rastlanan God Eye “Tanrı Gözü” bakış açısı, ressamın karakteristik tavırlarındandır<sup>[3]</sup>.' +
                ' Eserlerindeki bu tavrın temel nedeni olarak kameramanlık kariyeri sürecinde yaptığı hava çekimleri gösterilebilir<sup>[4]</sup>.' +
                ' Gökçebağ, resimlerindeki kurguyu; “İnsanlarım resmin birer küçük parçasıdırlar, resmin anlatımında doğa önemlidir. İnsanlar, kuşlar, bitkiler, resmin oluşmasındaki canlı unsurlardır.” diyerek açıklar<sup>[5]</sup>.' +
                ' Ressamın bir başka ayırt edici özelliği ise, Hasat ve Bodrum serilerinde görüldüğü üzere, benzer kompozisyonları resimlerinde çok defa işlemesidir.<br>' +
                ' 1980’ler sonrası, kameramanlık yıllarında başladığı Hasat serisinde Gökçebağ, Anadolu yaşamını kendine özgü kompozisyonları ile yansıtır.' +
                ' Resimlerinde görülen canlı renkleri ve ritmik düzeni soyut bir izlenim katar<sup>[6]</sup>.' +
                ' Bu alışagelmedik izlenim, Gökçebağ’ın tarzının ayırt edici özelliklerindendir.' +
                ' Daha yeni bir seri olan Bodrum serisi pek çok yönden Hasat serisine benzer.' +
                ' Eserlerinde Gökçebağ kendine özgü tarzıyla Bodrum’un kasabalarını ve sahillerini resimlerine yansıtmıştır.<br>' +
                ' Yurt içi ve yurt dışında 50’nin üzerinde sergisi bulunan Gökçebağ, günümüzde Ankara ve İstanbul’daki atölyelerinde sanat çalışmalarına devam etmektedir.',
            TEXT_LIMIT_DESKTOP: 1140,
            TEXT_LIMIT_MOBILE: 1140,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Fahri Dağı, Nuray Çağlayan, “Yalçın Gökçebağ’ın Eserlerindeki Milli Folklor Öğelerinin İncelenmesi”, Safran Kültür ve Turizm Araştırmaları Dergisi, sayı: 3, s 18-25.</li>' +
                '   <li>Ümmühan Kazanç, Yalçın Gökçebağ ile röportajı, Mayıs 2017, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, https://www.artfulliving.com.tr/sanat/resmin-yasar-kemali-yalcin-gokcebag-ile-dunden-bugune-i-11825</li>' +
                '   <li>Fahri Dağı, Nuray Çağlayan, “Yalçın Gökçebağ’ın Eserlerindeki Milli Folklor Öğelerinin İncelenmesi”, s 4-6.</li>' +
                '   <li>Ressam Yalçın Gökçebağ: Dokusunda Huzur Var, Yönetmen Sevim Karakaya (2014; Ankara, TRT Belgesel), TV</li>' +
                '   <li>Ayşegül Tabak, Yalçın Gökçebağ ile röportajı, Tarih belirtilmemiş, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, http://www.antikalar.com/yalcin-gokcebag</li>' +
                '   <li>Ümmühan Kazanç, Yalçın Gökçebağ ile röportajı, Mayıs 2017, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, https://www.artfulliving.com.tr/sanat/resmin-yasar-kemali-yalcin-gokcebag-ile-dunden-bugune-i-11825</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/0.d.webp', DESCRIPTION: '\'Yörük Çadırı\', 50x60 cm, TÜYB, 1992'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/1.d.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, TÜYB, 2019'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/2.d.webp', DESCRIPTION: '50x60 cm, TÜYB, 2016'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/3.d.webp', DESCRIPTION: '30x30 cm, TÜYB'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/4.d.webp', DESCRIPTION: '50x60 cm, TÜYB, 2007'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/5.d.webp', DESCRIPTION: '28x24 cm, PTÜYB, 2012'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/6.d.webp', DESCRIPTION: '39x20 cm, TÜYB, 1992'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/7.d.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, TÜYB'}
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/0.m.webp', DESCRIPTION: '\'Yörük Çadırı\', 50x60 cm, TÜYB, 1992'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/1.m.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, TÜYB, 2019'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/2.m.webp', DESCRIPTION: '50x60 cm, TÜYB, 2016'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/3.m.webp', DESCRIPTION: '30x30 cm, TÜYB'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/4.m.webp', DESCRIPTION: '50x60 cm, TÜYB, 2007'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/5.m.webp', DESCRIPTION: '28x24 cm, PTÜYB, 2012'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/6.m.webp', DESCRIPTION: '39x20 cm, TÜYB, 1992'},
                {SRC: './assets/announcements/YALCIN_GOKCEBAG/7.m.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, TÜYB'}
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/yalcin-gokcebag/" target="_blank">https://www.galerisoyut.com.tr/artist/yalcin-gokcebag/</a>'
            ]
        },
        {
            ID: 1,
            TEMPLATE: 0,
            NAME: 'Nejad Melih Devrim',
            NAME_UPPERCASE: 'NEJAD MELİH DEVRİM',
            TOPIC: 'Nejad Melih Devrim Hakkında',
            TOPIC_UPPERCASE: 'NEJAD MELİH DEVRİM HAKKINDA',
            ARTWORKS: 'Nejad Melih Devrim Eserleri',
            ARTWORKS_UPPERCASE: 'NEJAD MELİH DEVRİM ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/Nejad_Melih_Devrim.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NEJAD_MELIH_DEVRIM/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/NEJAD_MELIH_DEVRIM/-1.m.webp',
            TEXT: 'Nejad Melih Devrim, çağdaş sanat alanındaki çalışmalarıyla döneminin önemli ressamları arasında yer almaktadır.' +
                ' Coğrafi ve kültürel öğelerden etkilenen sanatçı, figüratif ve soyut arasında gidip gelen bir yol izlemiştir,' +
                ' özellikle geç dönem soyut resimleriyle Türk sanatında önemli bir yere sahiptir.' +
                ' Amerika’da Soyut Dışavurumculuk olarak bilinen ve çoğunluğu Avrupa’dan gelen sanatçılar ile başlayan hareketin' +
                ' Avrupa’daki karşılığı olan lirik soyut ve kısmen geometrik soyut içinde yer almaktadır<sup>[1]</sup>.' +
                ' Ressam Nejad Melih Devrim 1923’te İstanbul’da doğmuştur.' +
                ' Tanınmış bir ressam olan annesi Fahrelnisa Devrim (sonradan Fahrelnisa Zeid) ve ödüllü edebiyatçı olan' +
                ' babası İzzet Melih Devrim’in yönlendirmeleriyle erken yaşta sanata ilgisi başlamıştır.' +
                ' Galatarasay Lisesi’nden mezun olduktan sonra 1942-1945 yılları arasında öğrencisi olduğu İstanbul Güzel Sanatlar Akademisi’ne başlamıştır.' +
                ' Önce Bedri Rahmi Eyüboğlu, sonra Zeki Kocamemi, ardından Nurullah Berk’in atölyelerinde öğrenci olmuş ve son olarak Léopold Lévy Atölyesi’nde asistanlık yapmıştır.' +
                ' Öğrencilik yıllarında daha sonra sanatını besleyecek olan soyut İslam sanatı, kaligrafi ve Bizans sanatı üzerine çalışmalar yapmıştır.' +
                ' Bu süreçte Nejad Devrim, Abidin Dino’nun ve Fikret Adil’in de önerileriyle, Yeniler grubuna girerek en genç üyesi olmuştur<sup>[2]</sup>.' +
                ' 1944 tarihinde henüz öğrenciyken İstanbul Taksim Gazinosu’nda Bizans sanatını işlediği ilk sergisini açmıştır<sup>[3]</sup>.<br>' +
                ' Mezuniyetinin ardından 1946’da hala İkinci Dünya Savaşı’nın yıkıcı etkilerinin görüldüğü Paris’e yerleşmiştir.' +
                ' Sanatı, Paris Ekolü ’nün avant-garde sanatçılarının da etkisi altında kalarak, soyut kompozisyonlara yönelmiştir<sup>[4]</sup>.' +
                ' Bu süre içinde birçok sergilerde yer almıştır ve 1948’de bir atölye tutmuştur.' +
                ' Devrim’in soyut işlerinde Bizans mozaiklerinin etkileri bu dönemde başlamıştır.' +
                ' Soyut kompozisyonlarında yer alan bu etkinin önemi vurgulanarak Devrim’in bir Türk' +
                ' ressamı olarak Paris’in soyut sanat anlayışı içinde farklı bir yeri olduğu görülmüştür<sup>[5]</sup>.' +
                ' Mozaikler, Devrim’in Paris döneminin önemli bir serisi olan Bitki Bahçeleri’nde özgün yorumlara dönüşmüştür:' +
                ' bölünmüş, parçalanmış alanlar içinde, sert konturlar ile tekrarlanan bitkisel motifler bu serinin karakteristik özelliklerindendir.' +
                ' 1948 tarihli Bitki Bahçesi resmi bu tarzın niteliklerini açıklayan önemli bir eseri olarak ilgi çekmiştir.<br>' +
                '1950’de Paris’te Galerie Lydia Conti’de kişisel sergisini açmıştır.' +
                ' Devrim’in eserleri, aynı yıl Leo Castelli’nin New York’ta sergilemek üzere seçtiği “Paris Okulu” ressamları arasında yer alınmıştır.' +
                ' Bu sergide Fransız sanatçılar arasında yer alan Devrim, Pollock, Kline, Gorky, Rothko, de Kooning, Soulages, Manessier, Dubuffet gibi Amerikalı sanatçıların yanında resimlerini sergilemiştir<sup>[6]</sup>.' +
                ' Bu sergileri, 1951’de Galeri De Beaune’de açılan sergisi izledi. 1952 yılında Salon d’Octobre’un kurucu üyeliğini ve başkanlığını yapmıştır.' +
                ' 1954 yılında Galerie Charpentier sergilerinde yer almıştır. 1954-62 yılları arasında da Ecole de Paris sergilerine katılmıştır.<br>' +
                'Paris’te 10 yılın ardından, 1956’da başlayan ve 1985’e kadar sürecek olan seyahatleri, sanatının yeni bir döneminin başlangıcı olmuştur.' +
                ' Amerika, Çin, Orta Asya, İspanya, İngiltere, Mısır, Hollanda, Sovyetler Birliği ve Türkiye ziyaret ettiği bölgeler arasındadır.' +
                ' Devrim, bu dönemde soyut kompozisyonlara ve kent yorumlamalarına odaklanmıştır. 1985 yılında seyahatlerini' +
                ' seyrekleştirmiştir ve Polonya’nın güneyindeki Nowy-Sacz kentine yerleşmiştir.' +
                ' 1995 yılındaki ölümüne kadar orada üretimini sürdürmüştür.',
            TEXT_LIMIT_DESKTOP: 1161,
            TEXT_LIMIT_MOBILE: 1161,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Sefa Sağlam, “Direnen Bir Yenilikçi Nejad Devrim”, Hürriyet Gösteri, Sayı:56, İstanbul, Kasım 1993, s 31.</li>' +
                '   <li>Yahşi Baraz, “Türk Resminde Bir Fenomen: Nejad Melih Devrim”, Artist Dergisi, Sayı: 4/18, İstanbul Mart 2004, s 38</li>' +
                '   <li>Lydia Harambourg, “Nejad Devrim, Renk Uzmanı, Sabırsızlığın Ressamı”, Kısa Yaşam Öyküsü Fahrelnissa ile Nejad, Fajrelnissa is Nejad Gökkuşağında İki Kuşak, İstanbul, 2006, s 53-54.</li>' +
                '   <li>Ferit Edgü, “Nejad Devrim’in Ardından Nejad’ın Yaşamı ve Sanatı”, Milliyet Sanat Dergisi, Sayı: 356, Doğan Yayıncılık, İstanbul Mart 1995, s 32-36.</li>' +
                '   <li>Georges Boudaille, “Nejad Melih Devrim Günümüz Sanatçıları Kolleksiyonu”, Türkiyede Sanat (Plastik Sanatlar Dergisi), Sayı: 11, İstanbul Kasım/Aralık 1993, s 22.</li>' +
                '   <li>Yahşi Baraz, “Türk Resminde Bir Fenomen: Nejad Melih Devrim”, Artist Dergisi, Sayı 4/18, İstanbul Mart 2004, s 37.</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/0.d.webp', DESCRIPTION: '\"The Great Wall\" 89x116 cm, TÜYB, 1965'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/1.d.webp', DESCRIPTION: '\"Mesiah\" 26x41 cm, TÜYB, 1949'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/2.d.webp', DESCRIPTION: '\"Les Tulipes\" 28x20 cm, AÜYB, 1965'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/3.d.webp', DESCRIPTION: '\"Paris\" 130x160 cm, TÜYB, 1963'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/4.d.webp', DESCRIPTION: '\"A Memoire of The City\" 97x130 cm, TÜYB, 1962'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/5.d.webp', DESCRIPTION: '\"Méditerranée\" 22x27 cm, TÜYB, 1959-61'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/6.d.webp', DESCRIPTION: '\"White Abstraction\" 130x160 cm, TÜYB, 1954'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/7.d.webp', DESCRIPTION: '\"Abstrait Vert\" 22x12 cm, TÜYB, 1963'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/0.m.webp', DESCRIPTION: '\"The Great Wall\" 89x116 cm, TÜYB, 1965'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/1.m.webp', DESCRIPTION: '\"Mesiah\" 26x41 cm, TÜYB, 1949'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/2.m.webp', DESCRIPTION: '\"Les Tulipes\" 28x20 cm, AÜYB, 1965'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/3.m.webp', DESCRIPTION: '\"Paris\" 130x160 cm, TÜYB, 1963'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/4.m.webp', DESCRIPTION: '\"A Memoire of The City\" 97x130 cm, TÜYB, 1962'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/5.m.webp', DESCRIPTION: '\"Méditerranée\" 22x27 cm, TÜYB, 1959-61'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/6.m.webp', DESCRIPTION: '\"White Abstraction\" 130x160 cm, TÜYB, 1954'},
                {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/7.m.webp', DESCRIPTION: '\"Abstrait Vert\" 22x12 cm, TÜYB, 1963'},
            ],
            ARTWORK_REFERENCES: [
                // 'Chartes: <a class="gray-underline" href="https://artam.com/muzayede/301-cagdas-sanat-eserleri/nejad-melih-devrim-1923-1995-soyut-kompozisyon-20" target="_blank">' +
                // 'https://artam.com/muzayede/301-cagdas-sanat-eserleri/nejad-melih-devrim-1923-1995-soyut-kompozisyon-20</a>',
                // 'Zaman Doğarken: <a class="gray-underline" href="https://galerinev.art/tr/nejad-devrim" target="_blank">https://galerinev.art/tr/nejad-devrim</a>',
                // 'Nejadın En Büyük Erdemi Nejad Olmaktır: <a class="gray-underline" href="https://galerinev.art/tr/nejad-devrim" target="_blank">https://galerinev.art/tr/nejad-devrim</a>',
                '<a class="gray-underline" href="http://www.artnet.com/artists/nejad-melih-devrim/" target="_blank">http://www.artnet.com/artists/nejad-melih-devrim/</a>',
            ]
        },
        {
            ID: 2,
            TEMPLATE: 0,
            NAME: 'Ahmet Güneştekin',
            NAME_UPPERCASE: 'AHMET GÜNEŞTEKİN',
            TOPIC: 'Ahmet Güneştekin Hakkında',
            TOPIC_UPPERCASE: 'AHMET GÜNEŞTEKİN HAKKINDA',
            ARTWORKS: 'Ahmet Güneştekin Eserleri',
            ARTWORKS_UPPERCASE: 'AHMET GÜNEŞTEKİN ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/AHMET_GUNESTEKIN/Ahmet_Gunestekin.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_GUNESTEKIN/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_GUNESTEKIN/-1.m.webp',
            TEXT: 'Resim, kavramsal sanat ve heykel alanlarında eserler üretmekte olan Ahmet Güneştekin, 21. Yüzyıl Türkiye sanatının en önemli isimlerinden kabul edilir.' +
                ' Kendi kendini yetiştirmiş bir sanatçı olup, işlerinde coğrafi öğelere ve Anadolu, Yunan, Mezopotamya efsanelerine yer vermektedir<sup>[1]</sup>.' +
                ' Geleneksel ve çağdaş referanslarla yüklü işleri ile öne çıkan Güneştekin, 2000lerin başında figüratifi terk etmiş ve anlatıya odaklanan soyut bir tarz benimsemiştir.' +
                ' Güneştekin 1966 yılında Batman’da Garzan İşçi Kampı’nda dünyaya gelmiştir.' +
                ' Çocuk yaşta sanata ilgisi olmasına rağmen kazandığı Mimar Sinan Güzel Sanatlar Akademisini yarıda bırakıp Anadolu Üniversitesi İşletme Bölümünü okuyup mezun olmuştur.' +
                ' Bir süre ticari işler yaptıktan sonra sanata geri dönüş yapmıştır ve 1997 yılında İstanbul’da ilk profesyonel sanat atölyesini açmıştır.<br>' +
                ' Sanatçı ilk sergisini 2003 yılında Atatürk Kültür Merkezi’nde “Karanlıktan Sonraki Renkler” ismi altında açmıştır. Güneştekin’in uluslararası başarısı ise 2013 yılında,' +
                ' Marlborough Gallery ile birlikte, New York’ta gerçekleştirdiği “Ahmet Güneştekin: Recent Paintings” sergisi ile gelmiştir.' +
                ' 2018 yılında 13. Contemporary İstanbul kapsamında “Ölümsüzlük Odası” sergisi ise büyük ses getirmiştir.' +
                ' Türkiye’nin birçok bölgesine geziler düzenlemiş Güneştekin, bunun sanatının temellerinden biri olduğunu vurgulamaktadır<sup>[2]</sup>.' +
                ' Geometrinin ön planda olduğu eserlerinde yerel zanaat işlerinde rastlanılan geleneksel motiflerden ve güneş başta olmak üzere mitolojik öğelerden referanslar bulunmaktadır.' +
                ' Eserleri güçlü renklerden, tanımlı formlardan, birleştirilmiş katmanlardan ve optik illüzyonlardan oluşan sanatçı, mitolojinin tüm başlangıçların kaynağı olduğunu düşünmektedir<sup>[3]</sup>.' +
                ' Alışılagelmiş tuvalin sınırlamalarından uzak duran Güneştekin, çalışmalarında daire başta olmak üzere çeşitli şekillerde inşa edilmiş katmanlar kullanmaktadır.' +
                ' Bunları optik materyaller, kazınmış motifler ve imgeler gibi çeşitli öğeleri ile birleştirip tek bir bünyede akrilik ve yağlıboya ile işlemektedir<sup>[4]</sup>.<br>' +
                ' Güneştekin, sanatın yanı sıra belgeselcilik alanıyla da ilgilenmiştir.' +
                ' Coşkun Aral’ın yönettiği “Haberci” belgeselinin sanat yönetmenliğini üstlenen Güneştekin, 2005 yılında Türkiye’de sanata odaklanan ‘Güneşin İzinde’ belgeselini hazırlamıştır.' +
                ' Toplumsal kaygısı olan sanatçı belgesel yapımlarına ek olarak Türkiye’nin birçok bölgesinde çocuklara sanatı tanıtmaya yönelik atölyeler düzenlemektedir<sup>[5]</sup>.',
            TEXT_LIMIT_DESKTOP: 1150,
            TEXT_LIMIT_MOBILE: 1150,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Güler Ercan, Bir-Çok sergisi için Ahmet Güneştekin ile röportajı, Mayıs 2012</li>' +
                '   <li>Susanne Güsten, Sifting Through Anatolia\'s Dark Sins and Bright Cultures. International Herald Tribune, Nov 22, 2012.</li>' +
                '   <li>Susanne Güsten, Sifting Through Anatolia\'s Dark Sins and Bright Cultures. International Herald Tribune, Nov 22, 2012.</li>' +
                '   <li>Ahmet Güneştekin ile Hayat Sanat (2019; İstanbul, TRT 2 Belgesel), TV</li>' +
                '   <li>Güler Ercan, Bir-Çok sergisi için Ahmet Güneştekin ile röportajı, Mayıs 2012</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/0.d.webp', DESCRIPTION: '\"Aşk - Corona Günleri\" 150x150 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/1.d.webp', DESCRIPTION: '\"Cennetin Yedi Rüyası\" 150x200 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/2.d.webp', DESCRIPTION: '\"Maviye Yükseliş\" 100x100 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/3.d.webp', DESCRIPTION: '\"Ölümsüzlük Otu\" 150x200 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/4.d.webp', DESCRIPTION: '\"Tanrılardan Ateş Çalan Prometheus\" 50x60 cm, TÜYB, 2015'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/5.d.webp', DESCRIPTION: '\"Cennetin Dervişleri\" 130x130 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/6.d.webp', DESCRIPTION: '\"Godiva’nın Merhameti\" 180x220 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/7.d.webp', DESCRIPTION: '\"Sesli Saat\" 100x85 cm, TÜYB, 2018'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/0.m.webp', DESCRIPTION: '\"Aşk - Corona Günleri\" 150x150 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/1.m.webp', DESCRIPTION: '\"Cennetin Yedi Rüyası\" 150x200 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/2.m.webp', DESCRIPTION: '\"Maviye Yükseliş\" 100x100 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/3.m.webp', DESCRIPTION: '\"Ölümsüzlük Otu\" 150x200 cm, TÜYB, 2018'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/4.m.webp', DESCRIPTION: '\"Tanrılardan Ateş Çalan Prometheus\" 50x60 cm, TÜYB, 2015'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/5.m.webp', DESCRIPTION: '\"Cennetin Dervişleri\" 130x130 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/6.m.webp', DESCRIPTION: '\"Godiva’nın Merhameti\" 180x220 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/AHMET_GUNESTEKIN/7.m.webp', DESCRIPTION: '\"Sesli Saat\" 100x85 cm, TÜYB, 2018'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ahmet-gunestekin/" target="_blank">https://www.galerisoyut.com.tr/artist/ahmet-gunestekin/</a>',
                '<a class="gray-underline" href="https://ahmetgunestekin.com/dt_portfolios/duz-tuvaller/" target="_blank">https://ahmetgunestekin.com/dt_portfolios/duz-tuvaller/</a>',
            ]
        },
        {
            ID: 3,
            TEMPLATE: 0,
            NAME: 'Adnan Turani',
            NAME_UPPERCASE: 'ADNAN TURANİ',
            TOPIC: 'Adnan Turani Hakkında',
            TOPIC_UPPERCASE: 'ADNAN TURANİ HAKKINDA',
            ARTWORKS: 'Adnan Turani Eserleri',
            ARTWORKS_UPPERCASE: 'ADNAN TURANİ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/ADNAN_TURANI/Adnan_Turani.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ADNAN_TURANI/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/ADNAN_TURANI/-1.m.webp',
            TEXT: 'Türkiye sanatının ve sanat akademisinin önemli isimlerinden olan Adnan Turani, soyut odaklı resmin Türkiye’deki en önemli temsilcilerindendir.' +
                ' Erken dönem eserlerinde soyut bir anlayış gösteren Turani, son dönem eserlerine figürlere ve doğaya yer verse de' +
                ' resimlerine yaklaşımı soyut bir kurguya ve biçimin ifadesine dayalı olmayı sürdürmüştür<sup>[1]</sup>.<br>' +
                ' Turani 1925 yılında İstanbul’un Beşiktaş kentinde doğmuştur.' +
                ' Liseyi İstanbul Öğretmen Okulu’nda bitirip 1945 yılında Ankara Gazi Eğitim Enstitüsü’nde Resim-İş Bölümünü kazanmıştır.' +
                ' 1948 yılında mezun olduktan sonra çeşitli eğitim kurumlarında resim öğretmenliği yapıp 1953 yılında devlet bursu kapsamında' +
                ' Almanya’nın Münih Güzel Sanatlar Akademisi’nde ve Hamburg Güzel Sanatlar Akademisi’nde eğitimini sürdürmüştür.' +
                ' 1959 yılında Gazi Eğitim Enstitüsü’ne geri dönüp atölye eğitmenliği görevini üstlenip bu görevi 1970 yılına kadar sürdürmüştür.' +
                ' Aynı yıl Hacettepe Üniversitesi Edebiyat Fakültesi Sanat Tarihi bölümünde öğretim görevlisi olarak göreve başlamıştır ve Güzel Sanatlar Enstitüsü koordinatörü olmuştur.' +
                ' 1983 yılında Hacettepe Üniversitesi Güzel Sanatlar Fakültesinin kurulunda görev almış ve Resim Bölümü başkanı olmuştur.' +
                ' 1986 yılında ise Bilkent Üniversitesi’nde geçmiş olup 1993te emekliliğine kadar burada görev yapmıştır<sup>[2]</sup>.' +
                ' Hacettepe Üniversitesinde “Modern Resim Sanatını Yaratan Faktörler” konulu teziyle doktor unvanını alan Turani,' +
                ' “Dünya Sanat Tarihi”, “Türk Resim Sanatı”, “Sanat Terimleri Sözlüğü”, “Çağdaş Sanat Felsefesi” gibi çalışmaları Türkiye sanat akademisi için önemli kaynaklar yayınlamıştır.' +
                ' Yurt içinde ve yurt dışında otuzu aşkın kişisel sergi açmış, çok sayıda karma sergiye katılmış ve kırka aşkın kitap ve makale yayınlamış olan Adnan Turani,' +
                ' 1992’de Yılın Sanatçısı ödülüne, 1993’te Sedat Simavi Vakfı Ödülüne, 1998’de Anadolu Üniversitesi “Onursal Doktora” ödülüne, 2001’de Çağdaş Sanatçılar Vakfı Onur Ödülüne layık görülmüştür.<br>' +
                ' Avrupa’daki eğitimi sırasında non-figüratif bir anlayışa yönelen sanatçının üzerinde, çalıştığı atölyelerin etkisi olmuştur.' +
                ' 1970\'lere kadar süren soyut dönemi, daha sonraki araştırmalarını biçimleyici olması bakımından önem taşımaktadır.' +
                ' 1970\'lerin ardından işlerinde figür ve doğa tasvirlerine yer vermiştir ancak bu bahsedilen soyut biçim algısını korumuştur.' +
                ' Turani, yaratma sürecini; "Ben çevremde yeni biçimlemelere gidiyorum. Bu nedenle benim resmim, doğa etkisini soyut bir dilde yeniden inşa etmektir.' +
                ' Böylece doğa biçimini değil, doğa etkisinin biçimini konu ediyorum. Bu beni yeni biçim - motif sentezine sürüklüyor.' +
                ' Bu işlem, etki biçimi araştırmasıdır." sözleriyle açıklamaktadır<sup>[3]</sup>. Resim anlayışı soyut kurgulu bir etki biçimi arayışına dayanır.' +
                ' Bu bakımdan doğa ve figür biçimi değil, bu biçimlerin resimsel kurgusu öne çıkmıştır.' +
                ' Turani, 1993teki emekliliğinin ardından çalışmalarını yaşadığı kent olan Ankara’daki atölyesinde sürdürmüştür.' +
                ' Adnan Turani 2016’da vefat etmiştir.',
            TEXT_LIMIT_DESKTOP: 1161,
            TEXT_LIMIT_MOBILE: 1161,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Kurt, Efe Korkut. Çağdaş Türk Sanatında Soyut Resim, İstanbul: İTÜ, 2008.</li>' +
                '   <li>Turani, Adnan. Biyografi, Adnan Turani Resmi Web Sitesi, adnanturani.com.</li>' +
                '   <li>Kurt, Efe Korkut. Çağdaş Türk Sanatında Soyut Resim, İstanbul: İTÜ, 2008.</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/ADNAN_TURANI/0.d.webp', DESCRIPTION: '\"Trio\" 50x70 cm, KÜYB, 2011'},
                {SRC: './assets/announcements/ADNAN_TURANI/1.d.webp', DESCRIPTION: '\"Aşıklar\" 90x90 cm, TÜYB, 2003'},
                {SRC: './assets/announcements/ADNAN_TURANI/2.d.webp', DESCRIPTION: '\"Aşıklar-\" 35x35 cm, TÜYB, 1994'},
                {SRC: './assets/announcements/ADNAN_TURANI/3.d.webp', DESCRIPTION: '\"Kemancı Kız\" 70x70 cm, TÜYB, 2003'},
                {SRC: './assets/announcements/ADNAN_TURANI/4.d.webp', DESCRIPTION: '\"Müzisyenler\" 90x100 cm, TÜYB, 2015'},
                {SRC: './assets/announcements/ADNAN_TURANI/5.d.webp', DESCRIPTION: '\"Kemancı\" 40x40 cm, TÜYB, 2011'},
                {SRC: './assets/announcements/ADNAN_TURANI/6.d.webp', DESCRIPTION: '\"Kemancı Kız\" 80x80 cm, TÜYB, 2006'},
                {SRC: './assets/announcements/ADNAN_TURANI/7.d.webp', DESCRIPTION: '\"Portre\" 50x50 cm, TÜYB, 1991'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/ADNAN_TURANI/0.m.webp', DESCRIPTION: '\"Trio\" 50x70 cm, KÜYB, 2011'},
                {SRC: './assets/announcements/ADNAN_TURANI/1.m.webp', DESCRIPTION: '\"Aşıklar\" 90x90 cm, TÜYB, 2003'},
                {SRC: './assets/announcements/ADNAN_TURANI/2.m.webp', DESCRIPTION: '\"Aşıklar-\" 35x35 cm, TÜYB, 1994'},
                {SRC: './assets/announcements/ADNAN_TURANI/3.m.webp', DESCRIPTION: '\"Kemancı Kız\" 70x70 cm, TÜYB, 2003'},
                {SRC: './assets/announcements/ADNAN_TURANI/4.m.webp', DESCRIPTION: '\"Müzisyenler\" 90x100 cm, TÜYB, 2015'},
                {SRC: './assets/announcements/ADNAN_TURANI/5.m.webp', DESCRIPTION: '\"Kemancı\" 40x40 cm, TÜYB, 2011'},
                {SRC: './assets/announcements/ADNAN_TURANI/6.m.webp', DESCRIPTION: '\"Kemancı Kız\" 80x80 cm, TÜYB, 2006'},
                {SRC: './assets/announcements/ADNAN_TURANI/7.m.webp', DESCRIPTION: '\"Portre\" 50x50 cm, TÜYB, 1991'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/adnan-turani/" target="_blank">https://www.galerisoyut.com.tr/artist/adnan-turani/</a>',
            ]
        },
        {
            ID: 4,
            TEMPLATE: 0,
            NAME: 'Devrim Erbil',
            NAME_UPPERCASE: 'DEVRİM ERBİL',
            TOPIC: 'Devrim Erbil Hakkında',
            TOPIC_UPPERCASE: 'DEVRİM ERBİL HAKKINDA',
            ARTWORKS: 'Devrim Erbil Eserleri',
            ARTWORKS_UPPERCASE: 'DEVRİM ERBİL ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/DEVRIM_ERBIL/Devrim_Erbil.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/DEVRIM_ERBIL/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/DEVRIM_ERBIL/-1.m.webp',
            TEXT: 'Devrim Erbil, ulusal ve uluslararası birçok sergide yer almış ve ödüle layık bulunmuş ressam ve akademisyendir.' +
                ' Yağlıboya, baskı, vitray, mozaik gibi pek çok medyum ile eserler üretmiştir. Devrim Erbil’in sanatı;' +
                ' çağdaş sanatı gelenekle bağlantılı bir temele oturtmak isteyen yöresel kaynaklı eğilimler içinde değerlendirilebilir<sup>[1]</sup>.' +
                ' Devrim Erbil, 1937 yılında Uşak’ta doğmuştur. İlk ve Orta öğretimini Balıkesir’de yapan Erbil,' +
                ' 1954 yılında henüz lisede iken Balıkesir Amerikan Kültür Merkezinde ilk sergisini açmıştır.' +
                ' 1955’te günümüzdeki ismi Mimar Sinan Güzel Sanatlar Üniversitesi olan Devlet Güzel Sanatlar Akademisine girmiştir.' +
                ' 1959’da Halil Dikmen ve Bedri Rahmi Eyüpoğlu’nun öğrencisi olarak mezun olan Erbil, Yeni mezun döneminde Soyut 7ler,' +
                ' Mavi Grup gibi gruplarda yer almıştır. Erbil, 1960’lardan başlayarak kendine özgü tarzına dönüşecek çizgisel bir yaklaşım göstermeye başlamıştır.' +
                ' Bu dönemde yağlıboya başta olmak üzere pek çok farklı medyum ile eserlerinde yerel kültür öğeleri barındıran çizgisel ve ritmik bir üslup ile işlemiştir<sup>[2]</sup>.' +
                ' Erbil, sanat yaşamı boyunca belirli temalar etrafında çalışmalarını gerçekleştirmiştir: Anadolu kasabaları, doğa tutkusu ve yorumu,' +
                ' İstanbul gibi temaları ritmik düzenlemeler ile çalışmıştır. Devrim Erbil çalışmalarında Osmanlı süslemelerindeki geometrik kompozisyonları' +
                ' ve minyatür sanatındaki üslubu dışavurumcu ve mekânsal bir ifade ile ele almıştır<sup>[3]</sup>.<br>' +
                ' 1962 yılında 31. Venedik Bienali ve ertesi yıl 3. Paris Genç Sanatçılar Bienali’ne katılan Erbil, 1966’da 5. Tahran Bienalinde Saray Kraliyet 1.lik ödülüne layık görülmüştür.' +
                ' İlerleyen yıllarda başarısı devam eden sanatçı, Türkiye Çağdaş Ressamlar Cemiyeti, İskenderiye Bienali, DYO Sergisi gibi organizasyonlarda ödüller almıştır ve sergiler açmıştır.<br>' +
                ' Sanatının yanısıra akademik kariyerine devam eden Erbil, 1970 yılında doçent, 1981 yılında profesör olmuştur.' +
                ' 1985-1987 yılları arası Mimar Sinan Güzel Sanatlar Fakültesi Resim Bölümü başkanlığını,' +
                ' 1988-1990 yılları arası Yıldız Üniversitesi Güzel Sanatlar Fakültesi bölüm başkanlığını yapmıştır;' +
                ' 1990 yılında ise Mimar Sinan Üniversitesine Güzel Sanatlar Fakültesi’ne Dekan Yardımcısı olarak dönmüştür.' +
                ' 1991’de Devlet Sanatçısı unvanını alan Erbil, 2004 yılında emekli olana kadar Mimar Sinan Güzel Sanatlar Üniversitesi’nde akademik çalışmalarını sürdürmüştür.' +
                ' Sayısız ulusal ve uluslararası sergi açan Devrim Erbil, sanatsal çalışmalarına İstanbul’da devam etmektedir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Pehlivanoğlu, Burcu, Devrim Erbil (2012), Olcayart, 2012 basım, 1. Edisyon</li>' +
                '   <li>Devrim Erbil Hayatı ve Eserleri”, Istanbulsanatevi.com, Kasım 11, 2020</li>' +
                '   <li>Sinan Eren Erk, “Sanat Peşinde Yarım Asır: Devrim Erbil”, Devrim Erbil ile “Akademide 50 Yıl” sergisi için röportajı</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/DEVRIM_ERBIL/0.d.webp', DESCRIPTION: 'İsimsiz, 90x140 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/1.d.webp', DESCRIPTION: 'İsimsiz, 100x100 cm, TÜYB"'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/2.d.webp', DESCRIPTION: 'İsimsiz, 100x120 cm, TÜYB, 2019'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/3.d.webp', DESCRIPTION: 'İsimsiz, 100x120 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/4.d.webp', DESCRIPTION: 'İsimsiz, 140,5x89,5 cm, TÜYB, 2005'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/5.d.webp', DESCRIPTION: '"Ayasofya; İkili Bakış", 150x8150 cm, TÜYB, 2016'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/6.d.webp', DESCRIPTION: '"Derin Mavi", 150x100 cm, TÜYB, 1998'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/7.d.webp', DESCRIPTION: '"Yeşil İstanbul", 125x125 cm, TÜYB, 2006'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/DEVRIM_ERBIL/0.m.webp', DESCRIPTION: 'İsimsiz, 90x140 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/1.m.webp', DESCRIPTION: 'İsimsiz, 100x100 cm, TÜYB"'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/2.m.webp', DESCRIPTION: 'İsimsiz, 100x120 cm, TÜYB, 2019'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/3.m.webp', DESCRIPTION: 'İsimsiz, 100x120 cm, TÜYB, 2020'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/4.m.webp', DESCRIPTION: 'İsimsiz, 140,5x89,5 cm, TÜYB, 2005'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/5.m.webp', DESCRIPTION: '"Ayasofya; İkili Bakış", 150x8150 cm, TÜYB, 2016'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/6.m.webp', DESCRIPTION: '"Derin Mavi", 150x100 cm, TÜYB, 1998'},
                {SRC: './assets/announcements/DEVRIM_ERBIL/7.m.webp', DESCRIPTION: '"Yeşil İstanbul", 125x125 cm, TÜYB, 2006'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/devrim-erbil/" target="_blank">https://www.galerisoyut.com.tr/artist/devrim-erbil/</a>',
            ]
        },
        {
            ID: 5,
            TEMPLATE: 0,
            NAME: 'Nuri Abaç',
            NAME_UPPERCASE: 'NURİ ABAÇ',
            TOPIC: 'Nuri Abaç Hakkında',
            TOPIC_UPPERCASE: 'NURİ ABAÇ HAKKINDA',
            ARTWORKS: 'Nuri Abaç Eserleri',
            ARTWORKS_UPPERCASE: 'NURİ ABAÇ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/NURI_ABAC/Nuri_Abaç.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NURI_ABAC/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/NURI_ABAC/-1.m.webp',
            TEXT: 'Resimlerini gelenekler, gündelik hayat ve Anadolu söylenceleri ile bağdaştırıp dekoratif nitelikli özgün bir gerçeküstücülük ile ortaya koyan Nuri Abaç,' +
                ' 20. Yüzyıl Türkiye sanatının önemli ressamlarından olmuştur.' +
                ' Tarzının öne çıkan özelliği Karagöz-Hacivat estetiğine sahip bir yaklaşım benimsemesi ve musiki unsurlara sık sık yer vermesidir<sup>[1]</sup>.<br>' +
                ' Nuri Abaç 1926 yılında İstanbul’da doğmuştur. Tiyatro sanatçısı bir Celal Abaç ve öğretmen Sahire Abaç’ın ikinci oğludur.' +
                ' Abaç, 1944 yılında Devlet Güzel Sanatlar Akademisi, Resim Bölümüne girmiştir, eğitimine Leopold Levy Atölyesi’nde başlamıştır.' +
                ' Bir yıl ardından sonra Mimarlık Bölümüne geçiş yapmıştır, 1950 yılında ise mezun olmuştur.<br>' +
                ' Abaç, mimarlık öğrenimi ile birlikte sürdürdüğü sanat çalışmalarına 1960’larda yoğunluk vermeye başlamıştır.' +
                ' Özellikle Anadolu medeniyetlerinden esinlenmiştir.' +
                ' Hitit kabartmaları gibi tarihi eserlerin yorumsal uygulamalarını ve düşünsel sorunsallarını irdelemiş ve kendi resimsel anlatım diline ulaşmıştır.' +
                ' Bu doğrultuda ifadesel fantastik yorumları amaçlayan dışavurumcu yaklaşımlar üzerinde çalışmıştır<sup>[2]</sup>.<br>' +
                ' Abaç, 1970 sonrasında geleneksel kaynaklara yönelip geleneksel halk sanatlarını işlemeye başlamıştır.<sup>[3]</sup>' +
                ' Bu sürede resimde motifsel bir dil kullanmıştır.' +
                ' Halk oyunları, orta oyunu ve Karagöz araştırmalarının yoğunlaştığı bu dönemde Abaç, bu konulara öncelik vermiştir.<sup>[4]</sup>' +
                ' Minyatür sanatından ve kompozisyonlarından<sup>[5]</sup> da etkilenen sanatçının resimleri halkoyunları,' +
                ' davul zurna<sup>[6]</sup>, ortaoyunu sahneleri ve gölge oyunu motifleriyle ağırlık kazanır.<sup>[7]</sup><br>' +
                ' Abaç’a göre sanatçı içinde bulunduğu toplumun kültürel, psikolojik ve sosyal kökenine hassas olup birtakım işlevler üstlenmesi gereklidir.' +
                ' Kendi eserlerini de bu doğrultuda, sanatçının elinden çıkarak ve iletişim kuracağı toplumun malı olacak şeklinde yorumlamıştır.<sup>[8]</sup><br>' +
                ' Sanatının yanı sıra 1950 yılında serbest çalışmakla yaşama atılan Abaç üç yıl Devlet Su İşlerinde 1970\'den itibaren Devlet Planlama Teşkilatı\'nda görev yapıp, 1985 yılında emekli olmuştur.<br>' +
                ' 1991 yılından itibaren bir süre Hacettepe Üniversitesi, bir süre de Bilkent Üniversitesi Güzel Sanatlar Fakülteleri\'nde "Perspektif" dersinde öğretim görevlisi olarak görev yapan Nuri Abaç,' +
                ' yurt içi ve yurt dışında çok sayıda sergi açmıştır. 42. ve 47. Devlet Resim ve Heykel Sergisi ödüllerini,' +
                ' İskenderiye Bienali 3.\'lük ödülünü, 2002 yılında Ankara Sanat Fuarı Çağsav Onur Ödülü’nü kazanmıştır.<br>' +
                ' Nuri Abaç, çalışmalarını hayatının geri kalanı boyunca yaşadığı Ankara’da devam ettirmiştir. 2008 yılında vefat etmiştir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
                '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106.</li>' +
                '   <li>Gönül Gültekin, Batı Anlayışında Türk Resim Sanatı, Ankara: T.C. Ziraat Bankası. (1992).</li>' +
                '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106</li>' +
                '   <li>Şefik Kahramankaptan, “Resmi Geçit Ressam Söyleşileri”, Kültür Bakanlığı Yayınları, Ankara 2001, s.2, 3.</li>' +
                '   <li>Ahmet Dalkıran, Türk Kültüründe Davul ve Resim Sanatına Yansıması, Uluslararası Tarih ve Sosyal Araştırmalar Dergisi, sayı 21, (2019), 1-24.</li>' +
                '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
                '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/NURI_ABAC/0.d.webp', DESCRIPTION: '\"Ayakkabı Boyacısı\" 42x31 cm, TÜYB, 1982'},
                {SRC: './assets/announcements/NURI_ABAC/1.d.webp', DESCRIPTION: 'İsimsiz 35x40 cm, TÜYB, 1999'},
                {SRC: './assets/announcements/NURI_ABAC/2.d.webp', DESCRIPTION: 'İsimsiz 50x70 cm, TÜYB, 1990'},
                {SRC: './assets/announcements/NURI_ABAC/3.d.webp', DESCRIPTION: '\"Nuri Abaç -\" 50x50 cm, TÜYB, 1994'},
                {SRC: './assets/announcements/NURI_ABAC/4.d.webp', DESCRIPTION: '\"Çeşme Başı\" 40x33 cm, TÜYB, 1996'},
                {SRC: './assets/announcements/NURI_ABAC/5.d.webp', DESCRIPTION: '\"Island Ship and Fisherman\" 60x110 cm, TÜYB, 1982'},
                {SRC: './assets/announcements/NURI_ABAC/6.d.webp', DESCRIPTION: '\"Ship with Horse Head\" 40x50 cm, TÜYB, 2002'},
                {SRC: './assets/announcements/NURI_ABAC/7.d.webp', DESCRIPTION: '\"Locomotive\" 39x39 cm, TÜYB, 2004'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/NURI_ABAC/0.m.webp', DESCRIPTION: '\"Ayakkabı Boyacısı\" 42x31 cm, TÜYB, 1982'},
                {SRC: './assets/announcements/NURI_ABAC/1.m.webp', DESCRIPTION: 'İsimsiz 35x40 cm, TÜYB, 1999'},
                {SRC: './assets/announcements/NURI_ABAC/2.m.webp', DESCRIPTION: 'İsimsiz 50x70 cm, TÜYB, 1990'},
                {SRC: './assets/announcements/NURI_ABAC/3.m.webp', DESCRIPTION: '\"Nuri Abaç -\" 50x50 cm, TÜYB, 1994'},
                {SRC: './assets/announcements/NURI_ABAC/4.m.webp', DESCRIPTION: '\"Çeşme Başı\" 40x33 cm, TÜYB, 1996'},
                {SRC: './assets/announcements/NURI_ABAC/5.m.webp', DESCRIPTION: '\"Island Ship and Fisherman\" 60x110 cm, TÜYB, 1982'},
                {SRC: './assets/announcements/NURI_ABAC/6.m.webp', DESCRIPTION: '\"Ship with Horse Head\" 40x50 cm, TÜYB, 2002'},
                {SRC: './assets/announcements/NURI_ABAC/7.m.webp', DESCRIPTION: '\"Locomotive\" 39x39 cm, TÜYB, 2004'},
            ],
            ARTWORK_REFERENCES: [
                '"Ayakkabı Boyacısı" 42x31 cm, TÜYB, 1982: ' +
                '<a class="gray-underline" href="http://www.artnet.com/artists/nuri-abac/ayakkab%C4%B1-boyac%C4%B1s%C4%B1-EcOjpI_a__g8hVCScVQrQw2" target="_blank">' +
                'http://www.artnet.com/artists/nuri-abac/ayakkab%C4%B1-boyac%C4%B1s%C4%B1-EcOjpI_a__g8hVCScVQrQw2</a>',
                '35x40 cm, TÜYB, 1999: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/nuri-abac/" target="_blank">https://www.galerisoyut.com.tr/artist/nuri-abac/</a>',
                '50x70 cm, TÜYB, 1990: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/nuri-abac/" target="_blank">https://www.galerisoyut.com.tr/artist/nuri-abac/</a>',
                'Nuri Abaç - 50x50 cm, TÜYB, 1994: <a class="gray-underline" href="https://www.devmuzayede.com/urun/3311498/nuri-abac-1926-2008-tuval-uzerine-yagli-boya-1994-tarihli-imzali" target="_blank">' +
                'https://www.devmuzayede.com/urun/3311498/nuri-abac-1926-2008-tuval-uzerine-yagli-boya-1994-tarihli-imzali</a>',
                '"Çeşme Başı" 40x33 cm, TÜYB, 1996: <a class="gray-underline" href="https://www.sancakmuzayede.com/urun/3446493/nuri-abac-cesme-basi-imzali-1996-tarihli-tuval-uzeri-yagli-boya-40x33-cm" target="_blank">' +
                'https://www.sancakmuzayede.com/urun/3446493/nuri-abac-cesme-basi-imzali-1996-tarihli-tuval-uzeri-yagli-boya-40x33-cm</a>',
            ]
        },
        {
            ID: 6,
            TEMPLATE: 1,
            NAME: 'Sauna Emerging 1 Sepeti',
            NAME_UPPERCASE: 'SAUNA EMERGING 1 SEPETİ',
            TOPIC: 'Sauna Emerging 1 Sepeti Hakkında',
            TOPIC_UPPERCASE: 'SAUNA EMERGING 1 SEPETİ HAKKINDA',
            ARTIST_LIST_HEADING: 'Sauna Emerging 1 Sepeti Sanatçıları',
            ARTIST_LIST_HEADING_UPPERCASE: 'SAUNA EMERGING 1 SEPETİ SANATÇILARI',
            ARTWORKS: 'Sauna Emerging 1 Sepeti Eserleri',
            ARTIST_IMAGE_SRC: './assets/announcements/SAUNA_EMERGING_1/Art_Ist_Sauna.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SAUNA_EMERGING_1/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/SAUNA_EMERGING_1/-1.m.webp',
            TEXT: 'Artiox ve Art.Ist Sauna işbirliği çerçevesinde oluşturulan “Sauna Emerging 1” sepeti, genç ve bağımsız 11 sanatçının eserlerinden oluşmaktadır.' +
                ' Bu sepet ile birlikte gelecek vadeden sanatçıların üretmiş oldukları sanat eserlerine yatırım yaparken aynı zamanda titizlikle seçilmiş bu sanatçılara da destek olmuş olacaksınız.' +
                ' Art.Ist Sauna tarafından oluşturulan seçici kurul uzun ve detaylı bir çalışma sonucunda sepette yer alacak isimleri belirlerken sanatçıların geçmiş performanslarını' +
                ' ve istikrarlı olarak ortaya eser koyan sanatçıları seçmeye odaklanmıştır.' +
                ' Sepette yer alan eserler UniqExpo bünyesinde bulunan sergileme alanında sürekli olarak sergilenecek ve çeşitli etkinliklerde sanatseverler sanatçılar ile birlikte vakit geçirme fırsatı da bulabilecekler.' +
                ' Böylece sanatçı ile sanatseverler daha fazla iletişim halinde kalırken aynı zamanda bu değerli sanatçılarımızın gelişimlerini düzenli izleme fırsatı da bulmuş olacağız.',
            NESTED_ARTIST_LIST: [
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Erdinc_Sakin.webp',
                    HEADING: 'Erdinç Sakin',
                    TEXT: '“Anadolu’nun motiflerini işlemiş olduğum Anadolu’dan Fütürizme serisinde bazen fütürist düşünceler ağır basıyorken' +
                        ' bazen ise eski geleneklerle günümüz insanı harmanlayarak resimlerimi oluşturuyorum.“<br>' +
                        '1982 yılında Ankara’da dünyaya gelen sanatçı, 1997 yılında resim eğitimi almaya başlamıştır.' +
                        ' 2011 – 2012 yılları arasında deri modeli tasarımı sonrasında Bursa Bilim ve Teknoloji Merkezine özel tasarım ürünler ile heykeller yapmıştır.' +
                        ' 2014 yılında Kültür ve Turizm Bakanlığı’ndan sanatçı belgesi alan Sakin, 2015 yılında İtalya’ya giderek resim ve heykel alanında araştırmalar yapmıştır.' +
                        ' Yurt dışı sergileri için İtalyan küratör Adelinda Allegretti ile çalışmıştır.' +
                        ' Erdinç Sakin’in birçok eseri yurt içi ve yurt dışındaki sanatseverlerin koleksiyonlarında bulunmaktadır.' +
                        ' Resim çalışmalarına kendi özel atölyesinde devam eden sanatçı; bugüne kadar 6’sı kişisel sergi olmak üzere, 29 karma sergi ve pek çok ulusal ve uluslararası sergiye katılmıştır.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Erhan_Karagoz.webp',
                    HEADING: 'Erhan Karagöz',
                    TEXT: '“Tüm eserlerimin merkezinde yer alan kadın figürünü, hayatın içinden farklı duygu, ifade ve mimiklerle resmederek,' +
                        ' gerçek ötesi ve izleyiciyi içine çeken atmosferler yaratmanın peşindeyim. Eserlerimi, detaylarda dengesiz, estetik kaygısı yüksek ve bütünde dengeli olarak tanımlayabilirim.”<br>' +
                        '1980 yılında İstanbul’da dünyaya gelen sanatçı, 1994-1998 yılları arasında Kabataş Erkek Lisesi’nde lise eğitimini tamamladıktan sonra,' +
                        ' 2003 yılında Kocaeli Üniversitesi’nde kazandığı Kimya Bölümü mezuniyetinin ardından gönüllü olarak yer aldığı atölyelerle birlikte sanata yönelmiştir.' +
                        ' University of Wales Robert Kennedy College’da aldığı eğitimin ardından, kişisel sergiler düzenleyen Karagöz,' +
                        ' ulusal ve uluslararası birçok karma sergide yer almıştır. Portre, figüratif ve soyut olmak üzere; farklı tarz ve tekniklerde eserler üreten sanatçı,' +
                        ' resim yapmayı içsel bir yolculuk olarak görmekte ve hüzün, coşku, korku, mutsuzluk, acı gibi kavramlara resimlerinde yer vermektedir.' +
                        ' Resimlerinde farklı teknik ve medyumları kullanmasının nedenini de, farklı insanların farklı duygularını ifade ederken kullandığı farklı teknik,' +
                        ' medyum ve renklerin ifadeleri tamamlamak için kendisine yardımcı olması şeklinde belirten sanatçı, çalışmalarına İstanbul’daki atölyesinde devam etmektedir.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Kuntay_Tarik_Evren.webp',
                    HEADING: 'Küntay Tarık Evren',
                    TEXT: '“Her gün değişimlerle uyandığım dünyamda, çizgilerimle kendi iç çatışmalarımı modern çağın kaoslarıyla harmanlıyorum.' +
                        ' Benim için resimlerim bir dışa vurum; bu dışa vurumda, ying yang gibi, herkes kendinden, iyi ya da kötü bir parça görebilir.”<br>' +
                        '1989 yılında Bursa’da dünyaya gelen sanatçı, çocukluk dönemlerinde resim yapmaya başlamıştır. Sanat eğilimine amatör olarak devam eden Evren,' +
                        ' 2007 senesinde Beykent Üniversitesi’nin Görsel İletişim ve Tasarım Bölümü’nde sanat eğitimine başlamış,' +
                        ' 2009 yılında da eğitimine Milano Güzel Sanatlar Akademisi’nde devam etmiştir. ' +
                        ' Milano’da grafik tasarım ve görsel sanatlar eğitimi alan sanatçı, 2013 yılında mezun olmuştur.' +
                        ' 2015-2019 yılları arasında, Türkiye’de reklam sektöründe birçok marka ile çalışmıştır.' +
                        ' 2019 yılında reklam sektöründen ayrılmasıyla birlikte, profesyonel sanat hayatına başlamıştır.' +
                        ' Resimlerindeki plansızlığı ve iç içe geçmiş karmaşık dünyaları, bir sergi adresine ya da dört duvar arasına sıkıştırmak istemeyen sanatçı,' +
                        ' kendi akımını yaratarak 2020 yılı boyunca resimlerini Kadıköy gibi önemli meydanların duvarlarına asarak, kendi sokak sergisini yaratmıştır.' +
                        ' İzmir depreminden ilham alarak oluşturduğu, çöken hayatları anlatan “Teddy Bear” adlı eseriyle,' +
                        ' hasarlı ve yıkılmak üzere olan bir binada yapılan Mikrotopya adlı sergiye katılmıştır.' +
                        ' “Le Capitalisme” serisindeki eserleri, 2021 yılında Contemporary Barcelona’da sergilenmiştir. Eserlerinde büyük oranda dadaizmden esinlenen sanatçı, resimlerindeki akımı doddlizm olarak adlandırmaktadır.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Mine_Akcaoglu.webp',
                    HEADING: 'Mine Akçaoğlu',
                    TEXT: '“Sanatçı yapıtlarını önceki ve sonraki diye belirlediğimiz zaman kavramı üzerine kurgulamıştır.' +
                        ' Yapıtlarını oluştururken fotografik gerçeklikleri kullanır.' +
                        ' Kendi objektifinden ya da başkasının objektifinden edindiği gerçekliği resim yüzeyine taşırken tuvali yeni gerçekliğin mekanı haline getirir.' +
                        ' Fotoğraf nesnesini imge haline dönüştürürken gerçekleşen bu devinim ve değişim ile zaman kavramına atıfta bulunur.”<br>' +
                        ' 1982 yılında Kastamonu’da dünyaya gelen sanatçı, 2007 yılında Sakarya Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü’nden mezun olmuştur.' +
                        ' “Fotografik Gerçeklikten Resimsel Yeniden sunma Geçiş Sürecinde 1960 Sonrası Resim Sanatında Uzam Espas İlişkisi” başlıklı tezini başarıyla yayınlayan Akçaoğlu,' +
                        ' 2010 yılında Sakarya Üniversitesi Sosyal Bilimler Enstitüsü Resim Ana Sanat Dalı’nı tamamlamıştır.' +
                        ' Yapıtlarını oluştururken, fotografik gerçeklikleri kullanan Akçaoğlu; yapıtlarını önceki ve sonraki diye belirlediğimiz zaman kavramı üzerine kurgulamıştır.' +
                        ' Kendi objektifinden ya da başkasının objektifinden edindiği gerçekliği resim yüzeyine taşırken, tuvali yeni gerçekliğin mekanı haline getirmektedir.' +
                        ' Fotoğraf nesnesini imge haline dönüştürürken gerçekleşen bu devinim ve değişim ile zaman kavramına atıfta bulunmaktadır.' +
                        ' Çeşitli resim yarışmalarına, karma sergilere ve fuarlara katılan sanatçı, İstanbul’da özgün işlerini üretmeye devam etmektedir.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Mr_Hure.webp',
                    HEADING: 'Mr. Hure',
                    TEXT: '“Graffitinin içindeki ögeleri ve insanların sokağa bıraktığı etkileri, birbiriyle uyum içerisinde olmayan pastel,' +
                        ' canlı ve fosforlu renklerle kurgulayarak yaşadığım dönemi çalışmalarımda yansıtmaktayım.”<br>' +
                        '1987 yılında Siirt’te dünyaya gelen sanatçı; 1999 yılında, henüz 13 yaşındayken yazılara olan ilgisini fark etmiştir.' +
                        ' 2006 yılındaki Karadeniz Teknik Üniversitesi İşletme Bölümü mezuniyetinin ardından, 2009-2011 yılları arasında Eskişehir Anadolu Üniversitesi’nde eğitim almıştır.' +
                        ' Kişisel ve birçok karma sergide yer alan sanatçının aralarında Adidas, Redbull, Puma, Beşiktaş JK, Efes Pilsen, Istanblue,' +
                        ' Jack Daniels, Peugeot, Swatch, GAP, Nokia ve Superstep gibi markaların bulunduğu marka iş birlikleri ve katıldığı etkinlikler, Grafiti sanatının yaygınlaşmasını sağlamıştır.' +
                        ' Son yıllarda farklı desenler üzerine çalışan Mr. Hure, daha soyut çizimler ve grafitiler yapmaktadır. Sanatçının grafiti çalışmalarını ve street art çalışmalarını;' +
                        ' Türkiye’nin çeşitli şehirlerinin yanı sıra Almanya, Amerika, Bulgaristan, Bosna Hersek, Fransa, İtalya, Sırbistan, Suriye ve Yunanistan gibi dünyanın farklı ülkelerinde de görmek mümkündür.' +
                        ' İstanbul Havalimanı’nın girişinde çalışmalarını görebileceğiniz sanatçı, faaliyetlerini İstanbul’da sürdürmektedir. ',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Muhammet_Bakir.webp',
                    HEADING: 'Muhammet Bakır',
                    TEXT: '“Eserlerimdeki geometrik formlara indirgenmiş mekanlar, insanın iç dünyasının şekillendiği, kendisini iyi hisettiği korunaklı yerlerdir.' +
                        ' Ev, ticarethane, okul ve ibadethanelere göndermesi olan bu yapıların içerisinde barındırdığı tinsel boşluğa bakmadan,' +
                        ' mekanları çevreleyen dış dünyanın aşkın çekiciliğinin anlaşılamayacağı iddiasını taşıyan bu çalışmalar üzeri renk, leke ve dokuyla giydirilmiş metaforlar yoluyla hafıza oluşturmaktadır.”<br>' +
                        '1980 yılında Malatya’da dünyaya gelen sanatçının resme olan tutkusu, çocukluk yıllarından itibaren yaşamının bir parçası olmuştur.' +
                        ' Lise yıllarından itibaren, çeşitli fanzinlerde ve süreli yayınlarda çizimleri yayınlanan Bakır,' +
                        ' Türkiye’nin 4 farklı üniversitesinin sanat fakültelerindeki sanat eğitimini birikim süreci olarak değerlendirmiştir.' +
                        ' Resim dersi aldığı üniversiteler; İnönü Üniversitesi, Hacettepe Üniversitesi, Gazi Üniversitesi ve Yıldız Teknik Üniversitesi’dir.' +
                        ' Disiplinler arası üretimler yapan sanatçı; çoğunlukla resim, kavramsal sanat ve grafik mizah alanlarında eserler üretmektedir.' +
                        ' Ulusal ve uluslararası ödüller alan çalışmaları, yurt içinde ve yurt dışında birçok yerde sergilenmiştir. Eserleri, çeşitli özel koleksiyonlarda yer almaktadır.' +
                        ' Son dönem çalışmalarında, mekan ve figür ilişkisini ağırlıklı olarak ele almaktadır.' +
                        ' Geometrik formlara indirgenmiş mekânların içerisinde barındırdığı tinsel boşluğa bakmadan,' +
                        ' mekânları çevreleyen dış dünyanın aşkın çekiciliğinin anlaşılmayacağı iddiasını taşıyan çalışmalarında;' +
                        ' renk, leke ve dokuyla giydirilmiş metaforlar yoluyla hafıza oluşturan sanatçı, çalışmalarını İstanbul’da sürdürmektedir.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Saniye_Ozbek.webp',
                    HEADING: 'Saniye Özbek',
                    TEXT: '“Kimliksiz var olamıyorum, başkaları veya kendim tarafından durmadan tanımlanıyorum.' +
                        ' O tanımlamalar da ben değilim. Bunu akışkanlıkla ve insan olmanın kaosuyla yansıtmaya çalışıyorum.”<br>' +
                        ' Fernando Pessoa’nın dediği gibi "Olduğum şeyle olmadığım şey arasında, hayal ettiğim şeyle hayatın beni yaptığı şey arasında bir boşluğum."' +
                        ' 1993 yılında Kırıkkale’de dünyaya gelen sanatçı, 2007-2011 yılları arasında Karamürsel Lisesi’nde eğitim görmüştür.' +
                        ' 2017 yılındaki Marmara Üniversitesi Resim-İş Öğretmenliği Bölümü mezuniyetinin ardından,' +
                        ' İstanbul ve Sevilla’da stüdyoları bulunan Londra merkezli ressam Aslı Özok’a, İstanbul’da bulunan atölyesinde asistanlık yapmıştır.' +
                        ' 2015 yılından itibaren, birçok karma sergide yer alan ve sanatın iyileştirici gücünü keşfeden Özbek,' +
                        ' Altınbaş Üniversitesi’nde Sanat Terapisi Eğitimi almaya başlamıştır.' +
                        ' Eserlerinde insanların kimlik arayışını sorgulayan sanatçı; eğitim aldığı resim, heykel, grafik, seramik gibi alanlarda çalışmaktadır.' +
                        ' Çalışmalarının yanı sıra, tasarımcı olarak sektör faaliyetlerini de sürdüren Özbek, sanatsal çalışmalarına kendi atölyesinde devam etmektedir.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Suleyman_Engin.webp',
                    HEADING: 'Süleyman Engin',
                    TEXT: '“Ürettiğim eserlerde görsel estetiğin üzerinde duruyorum. İçerik ya da anlatıdan ziyade, çizgi, renk, denge, motif gibi öğeler üzerinde yoğunlaşıyorum.”<br>' +
                        '1989 yılında Aydın’da dünyaya gelen sanatçı, eğitim-öğretim hayatındaki ilk adımlarını burada attıktan sonra, 2003 yılından itibaren İzmir’de yaşamaya başlamıştır.' +
                        ' Lise öğrenimini Grafik ve Fotoğraf Bölümü’nde tamamlayan Engin, 2014 yılında lisans eğitimine başladığı Kocaeli Üniversitesi Güzel Sanatlar Fakültesi Heykel Bölümü’nün ardından,' +
                        ' aynı kurumda ve alanda yüksek lisans eğitimini sürdürmüştür.' +
                        ' Heykelle birlikte resim alanındaki üretimleri de devam eden Engin, her iki alandaki çeşitli yarışmalarda sergilemeler ve ödüller kazanmıştır.' +
                        ' Resim ve heykel alanındaki çalışmalarını Üsküdar’da bulunan atölyesinde sürdüren sanatçı, çalışmalarına bağımsız olarak devam etmektedir.' +
                        ' Resim çalışmalarında, kargaşayı ve kaosu barındıran kompozisyonlar kullanan sanatçı, bu kaosu ve kargaşayı izleyiciye renkli karakterleri eşliğinde sunmaktadır.' +
                        ' İzleyicinin, kargaşayı ve kaosu çözmesi için eseri yorumlarken bir yolculuğa çıkması gerektiğini vurgulamaktadır.' +
                        ' Resim yüzeyinde keskin renk geçişleri kullanan sanatçı, heykel çalışmalarında da renk unsurunu zaman zaman kullanmaktadır.' +
                        ' Heykelde taş, ahşap, polyester gibi malzemeler kullanan Engin, son dönemde soyutlanmış portreler üzerine çalışmaktadır.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Utku_Oksuz.webp',
                    HEADING: 'Utku Öksüz',
                    TEXT: '“Çalışmalarım da bugünün karşılığını ve yarattığımız düzenin hakikatini arıyorum.”<br>' +
                        '1991 yılında Ordu’da dünyaya gelen sanatçı, 2005-2010 yılları arasında Ordu Güzel Sanatlar Lisesi’nde Sanat eğitimini tamamlamıştır.' +
                        ' Kütahya Dumlupınar Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü’nü tamamlamasının ardından,' +
                        ' Gazi Üniversitesi Güzel Sanatlar Enstitüsü Bileşik Sanatlar Ana Sanat Dalı’nda yüksek lisans tahsilini almıştır.' +
                        ' 2017-2021 yılları arasında, özel eğitim kurumunda görsel sanatlar eğitmeni olarak görev alan Öksüz,' +
                        ' kişisel sergisinin yanında ulusal ve uluslararası birçok karma sergide yer almış olup, 7 farklı ödül almıştır.' +
                        ' “Bugünün karşılığı nedir? Öncesi hiçlikti, varlık peyda oldu. Günü geldi, ayaklandı insan.' +
                        ' Söylenmeye ve üretmeye başladı dünyasını anlamak için; kendi mitlerini, masallarını, destanlarını yarattı. Şüphesiz inandı.' +
                        ' İnandıkları yolda ilerledi, hata yaptı, yapmaya da devam etti. Düşündü, felsefe yaptı. Varlığın ardındaki mutlak hiçliği ve derin sessizliği fark etti,' +
                        ' fark ettiği vakit ise kuşkuya düştü. Kuşkularımla beraber, yokluk mu var oluş mu arayışı içerisine girdim.' +
                        ' Çünkü biliyoruz ki; bizler insanın el yordamıyla yaşadığı bu dünyada, doğruluğundan emin olabileceği mutlak bir hakikat yok,' +
                        ' hayatlarımıza dair her şey hikayelerimizden oluşmaktadır.” sözleriyle sanat bakış açısını ifade etmektedir. ',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Ummuhan_Tuncturk.webp',
                    HEADING: 'Ümmühan Tunçtürk',
                    TEXT: '“Resimlerim; kişiliğimin, estetik algımın ve yaşadığım coğrafyanın bir dışavurumudur.' +
                        ' Yerelden evrensele bir yol izleyerek kendi kimlik yansımalarımı destekleyecek eserler üretmeye çalışıyorum...”<br>' +
                        ' 1982 yılında Van’da dünyaya gelen sanatçı, Van Yüzüncü Yıl Üniversitesi Eğitim Fakültesi Resim Bölümü’ndeki mezuniyetinin ardından,' +
                        ' 2009 yılında Yüzüncü Yıl Üniversitesi Sosyal Bilimler Enstitüsü Resim-İş Eğitimi Anabilim Dalı’nda yüksek lisansını tamamlamıştır.' +
                        ' Tunçtürk, özellikle doğu kültüründe yer alan geleneksel motifleri, modern tekniklerle tuvale yansıtarak geleceğe taşımaya gayret ettiği resimleriyle;' +
                        ' İtalya, İspanya ve Fransa’nın yanı sıra İstanbul, Ankara ve Van’da kişisel ve karma sergiler açmıştır. ' +
                        ' “Kadını o estetik bedeninin dışında, kendi bakış açımla gördüğüm şekilde yansıtmaya çalışıyorum.' +
                        ' Günümüzde kadınların güzel olmasıyla ilgili bir algı var.' +
                        ' Bunu kabul etmiyorum, kadınların çok daha farklı görünebileceğini düşünüyorum ve bunu resimlerimde vurgulamaya çalışıyorum.”' +
                        ' sözleriyle sanat anlayışı hakkında fikir veren sanatçı, çalışmalarına atölyesinde devam etmektedir.',
                },
                {
                    PHOTO: './assets/announcements/SAUNA_EMERGING_1/Yasin_Canli.webp',
                    HEADING: 'Yasin Canlı',
                    TEXT: '“Resim yaparken belirli kalıplar içerisine girerek ve afiş misali bir şeyleri anlatmanın aksine anlık olarak gelişen geri plandaki hayali peyzaj etkileri ile çizgiler üzerinden hayatı anlamlandırmaya,' +
                        ' anlamaya ve algılamaya yönelik izleyici ile bir bağ kurma hedefi içerisindeyim.”<br>' +
                        ' 1998 yılında Kahramanmaraş’ta dünyaya gelen sanatçı, lise öğrenimini Kahramanmaraş Güzel Sanatlar Lisesi’nde tamamlamıştır.' +
                        ' Mezuniyetinin ardından, Eskişehir Anadolu Üniversitesi Güzel Sanatlar Eğitim Fakültesi’ni kazanmış ve 2020 yılında, Anadolu Üniversitesi Güzel Sanatlar Eğitim Fakültesi’nden mezun olmuştur.' +
                        ' Birçok ödüle sahip olan Canlı, karma sergilere sanatçı olarak katılmıştır.' +
                        ' Ağırlıklı olarak resim alanında çalışmalar üreten sanatçı; UKKSA (Uluslararası Knidos Kültür Sanat Akademisi) Doğaya Saygı ve Badem Çiçeği,' +
                        ' Antalya 5. Artoros, Antalya Aydın Özgüven Sanat ve Eskişehir Tepebaşı Belediyesi 10. Uluslararası Sanat isimli çalıştaylarda da görev almıştır.',
                },
            ],
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references"></div>',
            ARTWORKS_LIST_DESKTOP: [
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/0.d.webp',
                    DESCRIPTION: 'Erdinç Sakin, “Saygı,“ Tuval Üzerine Akrilik, 2021, 60 cm çap'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/1.d.webp',
                    DESCRIPTION: 'Erhan Karagöz, “Tell Me If You Wanna “BEE””, 160x120 cm, Tuval Üzeri Yağlıboya, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/2.d.webp',
                    DESCRIPTION: 'Küntay Tarık Evren, “Teddy Bear“, 80x60 cm, Kağıt Üzerine Mürekkep Kalem, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/3.d.webp',
                    DESCRIPTION: 'Mine Akçaoğlu, “Aynı Yerden Bakıyoruz“, 70x100 cm, Tuval Üzeri Akrilik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/4.d.webp',
                    DESCRIPTION: 'Mr. Hure, “Yengeç“, 150x150 cm, Spray ve akrilik boya.Karışık teknik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/5.d.webp',
                    DESCRIPTION: 'Muhammet Bakır, “Sessiz Seda“, 149x199 cm, Tuval Üzeri Akrilik, 2021'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/6.d.webp',
                    DESCRIPTION: 'Saniye Özbek, “Nereye Aitim?“, Tuval Üzerine Akrilik Boya, 120x120 cm, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/7.d.webp',
                    DESCRIPTION: 'Süleyman Engin, “Güneşli Bir Günde Dinazor Ailesinin Düşündükleri“, 140x150 cm, Tuval Üzeri Akrilik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/8.d.webp',
                    DESCRIPTION: 'Utku Öksüz, “Mutsuz Kent“, 150x150cm, Tuval Üzerine Akrilik Boya, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/9.d.webp',
                    DESCRIPTION: 'Ümmühan Tunçtürk, “Despotun Bir Günü”, 100x100 cm, Tuval Üzeri Akrilik, 2021'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/10.d.webp',
                    DESCRIPTION: 'Yasin Canlı, “Bu Yol Nereye Gider“, 140x120 cm, Tuval Üzerine Akrilik, 2020'
                },
            ],
            ARTWORKS_LIST_MOBILE: [
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/0.m.webp',
                    DESCRIPTION: 'Erdinç Sakin, “Saygı,“ Tuval Üzerine Akrilik, 2021, 60 cm çap'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/1.m.webp',
                    DESCRIPTION: 'Erhan Karagöz, “Tell Me If You Wanna “BEE””, 160x120 cm, Tuval Üzeri Yağlıboya, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/2.m.webp',
                    DESCRIPTION: 'Küntay Tarık Evren, “Teddy Bear“, 80x60 cm, Kağıt Üzerine Mürekkep Kalem, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/3.m.webp',
                    DESCRIPTION: 'Mine Akçaoğlu, “Aynı Yerden Bakıyoruz“, 70x100 cm, Tuval Üzeri Akrilik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/4.m.webp',
                    DESCRIPTION: 'Mr. Hure, “Yengeç“, 150x150 cm, Spray ve akrilik boya.Karışık teknik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/5.m.webp',
                    DESCRIPTION: 'Muhammet Bakır, “Sessiz Seda“, 149x199 cm, Tuval Üzeri Akrilik, 2021'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/6.m.webp',
                    DESCRIPTION: 'Saniye Özbek, “Nereye Aitim?“, Tuval Üzerine Akrilik Boya, 120x120 cm, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/7.m.webp',
                    DESCRIPTION: 'Süleyman Engin, “Güneşli Bir Günde Dinazor Ailesinin Düşündükleri“, 140x150 cm, Tuval Üzeri Akrilik, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/8.m.webp',
                    DESCRIPTION: 'Utku Öksüz, “Mutsuz Kent“, 150x150cm, Tuval Üzerine Akrilik Boya, 2020'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/9.m.webp',
                    DESCRIPTION: 'Ümmühan Tunçtürk, “Despotun Bir Günü”, 100x100 cm, Tuval Üzeri Akrilik, 2021'
                },
                {
                    SRC: './assets/announcements/SAUNA_EMERGING_1/10.m.webp',
                    DESCRIPTION: 'Yasin Canlı, “Bu Yol Nereye Gider“, 140x120 cm, Tuval Üzerine Akrilik, 2020'
                },
            ],
            ARTWORK_REFERENCES: []
        },
        {
            ID: 7,
            TEMPLATE: 0,
            NAME: 'Ergin İnan',
            NAME_UPPERCASE: 'ERGİN İNAN',
            TOPIC: 'Ergin İnan Hakkında',
            TOPIC_UPPERCASE: 'ERGİN İNAN HAKKINDA',
            ARTWORKS: 'Ergin İnan Eserleri',
            ARTWORKS_UPPERCASE: 'ERGİN İNAN ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/ERGIN_INAN/Ergin_Inan.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ERGIN_INAN/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/ERGIN_INAN/-1.m.webp',
            TEXT: 'Ergin İnan, uluslararası tanınırlığa sahip çok sayıda sergilerde bulunmuş ve ödüle layık görülmüş bir ressamdır.' +
                ' Çağdaş Türk sanatında alışagelmedik bir yaklaşıma sahip sanatçı, eserlerinde genelde karışık medyum ve baskı teknikleri kullanarak kavramsal çalışmalar yapmıştır.' +
                ' Ergin İnan’ın eserlerinde sık rastlanan imgelere; soyutlanmış insan figürleri, yazı imgeleri ve böcek tasvirleri örnek gösterilebilir.<br>' +
                'Ergin İnan 1943’te Malatya’da doğmuştur, ilk ve ortaöğretimini orada tamamlamıştır. 1963’te İstanbul Hukuk Fakültesi\'ni kazansa da devam etmemiştir.' +
                ' 1964’te İstanbul Devlet Tatbiki Güzel Sanatlar Yüksek Okulu\'na başlayan İnan, 1968 yılında mezun olmuştur.<br>' +
                'Ergin İnan’ın sanatının ayırt edici özelliklerinden biri tasavvuf felsefesini ve ruhsallığı eserlerinde kapsamlı ve derin işlemesidir.' +
                ' İnan sanat anlayışını “Sanata manevi bir dünya olarak bakmak lazım, o dünyanın içinde yaşıyorsunuz; ama kimse bilmiyor. Bunu zaman zaman dışarı vurmaya çalışıyorsunuz.” cümlesi ile açıklamıştır.' +
                ' Resimlerinde olağanüstü ve ruhsal öğeleri işleyen sanatçı, yok oluş ve var oluş arasındaki gerçekliği ifade etmiştir.' +
                ' İnan’ın eserlerinde geleneksel Türk İslam sanatından imgeler ve göndermeler de görülebilir.' +
                ' Sahaflardan topladığı eski kitapları ve sayfaları eserlerinde sıkça kullanmıştır.<br>' +
                'İnan, mezuniyetinden sonraki yıllarda akademik olarak aktivitelerini sürdürmüştür. Mezuniyetinden sonra ilk yıllarında Salzburg ve Münih’te öğrenimine devam etmiştir.' +
                ' 1978’de burslu olarak Münih ve Berlin Güzel Sanatlar akademilerinde araştırma görevlisi olarak çalışmıştır;' +
                ' ardından araştırma çalışmalarına Amsterdam, Lahey ve Londra’da devam etmiştir.' +
                ' 1982 yılında Marmara Üniversitesi Güzel Sanatlar Fakültesi\'nde eğitim üyeliğine atanan İnan, bir yıl boyunca ders vermiştir.' +
                ' 1983-1987 yılları arasında DAAD Berlinli Sanatçılar Bursu’na layık görünen sanatçı, davet üzerine çalışmalarını Berlin’de sürdürmüştür.' +
                ' Türkiye’ye dönüşünde öğretim üyesi olduğu Marmara Üniversitesi’ne profesör olarak atanmıştır.' +
                ' Ergin İnan, Marmara Üniversitesi’nin yanı sıra Mimar Sinan Güzel Sanatlar Üniversitesi\'nde ve Yeditepe Üniversitesi\'nde ders vermiştir.<br>' +
                'Ergin İnan pek çok ulusal ve uluslararası ödüle layık görülmüştür.' +
                ' Bu ödüllerden bazıları:' +
                ' 1977 İstanbul Güzel Sanat Akademisi “Özgün Baskı” Birincilik Ödülü,' +
                ' 1980 Uluslararası Grafik Bienali Madalya,' +
                ' 1981 Uluslararası 5. Cleveland Bienali 4.lük Ödülü,' +
                ' 1982 Uluslararası Minyatür Baskı Bienali Ödülü(Güney Kore),' +
                ' 1983 Uluslararası Norveç Baskı Bienali Büyük Ödülü,' +
                ' 1987 Yılın Sanatçısı Ankara Sanat Kurumu,' +
                ' 1988 Uluslararası 2. Asya-Avrupa Bienali Birincilik Ödülü(Ankara),' +
                ' 1993 Uluslararası Resim Trienali Üçüncülük Ödülü (Japonya),' +
                ' 2010 Cumhurbaşkanlığı Kültür ve Sanat Büyük Ödülü\'dür.<br>' +
                '2000 yılında emekli olan Ergin İnan, sanatsal çalışmalarına İstanbul’daki atölyesinde devam etmektedir. ',
            TEXT_LIMIT_DESKTOP: 1150,
            TEXT_LIMIT_MOBILE: 1150,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Soylu, Rasim, “Ergin İnan Resimlerinde Tasavvuf İmgeleri”, Uluslararası Sosyal ve Beşeri Bilimler Araştırma Dergisi, sayı 41, 29.08.2019</li>' +
                '   <li>Tansuğ, S., Çağdaş Türk Sanatı (1991), 2.Bs. İstanbul: Remzi Kitabevi.</li>' +
                '   <li>Özışık, C. D., Osmanlı Kültüründe Resimsel Öge Olarak Mistik Semboller ve Günümüz Sanatına Yansımaları (2010), Marmara Üniversitesi, Güzel Sanatlar Enstitüsü, İstanbul.</li>' +
                '   <li>Soylu, R., Çağdaş Türk Resim Sanatında Mistik Semboller ve Göstergebilim Analizleri (2016), Yıldız Teknik Üniversitesi, Sosyal Bilimler Enstitüsü, İstanbul.</li>' +
                '   <li>“Ergin İnan”, www.biyografya.com, Aralık 13, 2020</li>' +
                '   <li>Nazlı Şahin, “Ergin İnan Kimdir? Hayatı ve Eserleri”, www.beyazegitim.com, Aralık 13, 2020</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {
                    SRC: './assets/announcements/ERGIN_INAN/0.d.webp',
                    DESCRIPTION: '"Can Işığı Renkleri" 135x135 cm, Tuval Üzeri Akrilik ve Yağlıboya, 2021'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/1.d.webp',
                    DESCRIPTION: '"Begonvil Pembesi" 100x150 cm, Tuval Üzeri Akrilik ve Yağlıboya, -'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/2.d.webp',
                    DESCRIPTION: '"Ayrılmış Ayrı Ayrıntılardan" 170x125 cm, Ahşap Üzeri Karışık Teknik, 1994'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/3.d.webp',
                    DESCRIPTION: '"Binbir Böcek" 50x50 cm, Tuval Üzeri Akrilik ve Yağlıboya, 1996'
                },
                {SRC: './assets/announcements/ERGIN_INAN/4.d.webp', DESCRIPTION: '"Portre İkona" 70x50 cm, Ahşap Üzeri Yağlıboya, 1990'},
                {SRC: './assets/announcements/ERGIN_INAN/5.d.webp', DESCRIPTION: '"El" 31x18 cm, Kağıt Üzeri Baskı, 1997'},
                {SRC: './assets/announcements/ERGIN_INAN/6.d.webp', DESCRIPTION: '"Kompozisyon" 167x132 cm, Tuval Üzeri Karışık Teknik, 2001'},
                {SRC: './assets/announcements/ERGIN_INAN/7.d.webp', DESCRIPTION: '"Arı" 44x37 cm, Ahşap Üzeri Karışık Teknik, 2015'},
                {SRC: './assets/announcements/ERGIN_INAN/8.d.webp', DESCRIPTION: 'İsimsiz, 17x13 cm, Gravür, 1990'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {
                    SRC: './assets/announcements/ERGIN_INAN/0.m.webp',
                    DESCRIPTION: '"Can Işığı Renkleri" 135x135 cm, Tuval Üzeri Akrilik ve Yağlıboya, 2021'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/1.m.webp',
                    DESCRIPTION: '"Begonvil Pembesi" 100x150 cm, Tuval Üzeri Akrilik ve Yağlıboya, -'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/2.m.webp',
                    DESCRIPTION: '"Ayrılmış Ayrı Ayrıntılardan" 170x125 cm, Ahşap Üzeri Karışık Teknik, 1994'
                },
                {
                    SRC: './assets/announcements/ERGIN_INAN/3.m.webp',
                    DESCRIPTION: '"Binbir Böcek" 50x50 cm, Tuval Üzeri Akrilik ve Yağlıboya, 1996'
                },
                {SRC: './assets/announcements/ERGIN_INAN/4.m.webp', DESCRIPTION: '"Portre İkona" 70x50 cm, Ahşap Üzeri Yağlıboya, 1990'},
                {SRC: './assets/announcements/ERGIN_INAN/5.m.webp', DESCRIPTION: '"El" 31x18 cm, Kağıt Üzeri Baskı, 1997'},
                {SRC: './assets/announcements/ERGIN_INAN/6.m.webp', DESCRIPTION: '"Kompozisyon" 167x132 cm, Tuval Üzeri Karışık Teknik, 2001'},
                {SRC: './assets/announcements/ERGIN_INAN/7.m.webp', DESCRIPTION: '"Arı" 44x37 cm, Ahşap Üzeri Karışık Teknik, 2015'},
                {SRC: './assets/announcements/ERGIN_INAN/8.m.webp', DESCRIPTION: 'İsimsiz, 17x13 cm, Gravür, 1990'},
            ],
            ARTWORK_REFERENCES: [
                ' <a class="gray-underline" href="https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/ergin-inan/page/2/" target="_blank">' +
                'https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/ergin-inan/page/2/' +
                '</a>',
                '<a class="gray-underline" href="http://lebriz.com/pages/artist.aspx?section=130&lang=TR&artistID=13&periodID=-1&pageNo=0&exhID=0" target="_blank">' +
                'http://lebriz.com/pages/artist.aspx?section=130&lang=TR&artistID=13&periodID=-1&pageNo=0&exhID=0' +
                '</a>'
            ]
        },
        {
            ID: 8,
            TEMPLATE: 0,
            NAME: 'Adnan Çoker',
            NAME_UPPERCASE: 'ADNAN ÇOKER',
            TOPIC: 'Adnan Çoker Hakkında',
            TOPIC_UPPERCASE: 'ADNAN ÇOKER HAKKINDA',
            ARTWORKS: 'Adnan Çoker Eserleri',
            ARTWORKS_UPPERCASE: 'ADNAN ÇOKER ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/ADNAN_COKER/Adnan_Coker.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ADNAN_COKER/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/ADNAN_COKER/-1.m.webp',
            TEXT: '20. yüzyıl Türkiye sanatında önemli bir yeri olan Adnan Çoker; en çok Bizans, Selçuklu ve Osmanlı mimari formlarını resimsel konturlara dönüştürerek özgün bir estetik üslup yaratmasıyla tanınır.' +
                ' 1970\'ler sonrası bu çizgide işler üreten sanatçı resimlerinde kalıp-biçim olarak nitelendirdiği biçimleri, siyah üzerinde soyut bir mekânsal boyut içerisinde işler.' +
                ' Anıtsal mimarinin, iç uzamını dış dünyaya açan sivri kemerli kapı ve pencere motifinden yola çıkılarak oluşturulan uyum, sanatçının sözleriyle bir kalıp biçime dayanır.<br>' +
                'Adnan Çoker, 1927\'de İstanbul’da doğmuştur. 1944 yılında Afyon Lisesi’ni bitirerek Güzel Sanatlar Akademisi Yüksek Resim Bölümü\'ne girmiştir.' +
                ' 1944-1951 yılları arasında burada eğitim gören Çoker,  Zeki Kocamemi Atölyesi\'nde çalışmıştır.' +
                ' Altı yılın ardından 1951\'de Güzel Sanatlar Akademisi’ni bitirmiştir.<br>' +
                'İlk kişisel sergisini 1953’te Ankara’da, “Sergi Öncesi” adı altında açmıştır. Bunu 1954’te Ankara’da ve 1955’te İstanbul’da açtığı sergiler izlemiştir.' +
                ' Ancak sanat hayatının dönüm noktası 1955’te kazandığı devlet bursu ile Paris’e gitmesidir.<br>' +
                'Paris’te Andre Lhote ve Henri Goetz gibi isimlerin atölyelerinde çalışmıştır.' +
                ' Beş yılın ardından 1960’ta öğrenimini tamamlayarak Türkiye’ye geri dönen Çoker, aynı yıl Güzel Sanatlar Akademisi Resim Bölümü\'nde asistan olmuştur.' +
                ' Bunun ardından 1963\'te dönemin önemli isimlerinden olan A. Gürman, Sarkis, Devrim Erbil ve Tülay Tura ile "Mavi Grup" adlı resim grubunu kurmuştur.' +
                ' 1964\'te ise ikinci kez devlet bursu kazanan Çoker, eğitimini devam ettirmek için tekrardan Paris’e gitmiştir.' +
                ' W. Hayter Atölyesi\'nde gravür ve Goetz Atölyesi\'nde boya üzerine çalışan sanatçı' +
                ' bu sıralarda sonradan büyük öneme sahip olacak siyah fonlu geometrik resimlerinin temellerini atmıştır.' +
                ' 1965\'te geri dönen Çoker, Güzel Sanatlar Akademisi\'nde öğretim görevine devam etmiştir. 1969 yılında ise bu alanda doçent unvanını almıştır.' +
                ' 1970’e kadar eserleri genellikle soyut anlatımcılığına dayanan Adnan Çoker, bu yılın ardından geometrik, mimari, hacimsel ve soyut bir yaklaşımı benimsemiştir.' +
                ' Bu siyah fonlu olan işleri Çoker’e büyük başarı getirmiştir.' +
                ' Bu yılın ardından Adnan Çoker; dengeli, simetrik, kararlı, statik ve kesin bir biçimcilikle süprematistlerin,' +
                ' minimal sanatçıların anlatımcı öğelerini yadsıyan salt arınmış ve yalın biçimlere ulaşma ilkesini benimsemiştir.' +
                ' 1976\'da Güzel Sanatlar Akademisi Resim Bölümü profesörlüğüne, ardından profesörlük görevi sırasında 1977\'de İstanbul Resim ve Heykel Müzesi Müdürlüğü\'ne atanmıştır.' +
                ' 1979\'da buradan istifa etmiştir ve ardından 1983\'te Mimar Sinan Üniversitesi Güzel Sanatlar Fakültesi Resim Bölümü Başkanlığı\'na gelmiştir.' +
                ' Ancak 1985\'te bu görevinden de istifa etmiştir. Birçok kurum ve özel koleksiyonda yapıtları bulunan Çoker, 26 kişisel sergi açmış ve birçok grup sergisine katılmıştır.' +
                ' Ağustos 2022\'de aramızdan ayrılmıştır.',
            TEXT_LIMIT_DESKTOP: 1150,
            TEXT_LIMIT_MOBILE: 1150,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li></li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/ADNAN_COKER/0.d.webp', DESCRIPTION: '\"Mor Ötesi Boşluk\" 50x50 cm, TÜYB, 1979'},
                {SRC: './assets/announcements/ADNAN_COKER/1.d.webp', DESCRIPTION: '\"Mavi Kompozisyon\" 52x49 cm, KÜG, 1963'},
                {SRC: './assets/announcements/ADNAN_COKER/2.d.webp', DESCRIPTION: '\"Açık Simetri II\" 60x80 cm, TÜYB, 1975'},
                {SRC: './assets/announcements/ADNAN_COKER/3.d.webp', DESCRIPTION: '\"Simetri Geçiş II\" 89x116 cm, TÜYB, 1978'},
                {SRC: './assets/announcements/ADNAN_COKER/4.d.webp', DESCRIPTION: '\"Posa\' 92x73 cm, TÜYB, 1965'},
                {SRC: './assets/announcements/ADNAN_COKER/5.d.webp', DESCRIPTION: '\"Half Globes II\" 180x180,5 cm, TÜA, 1996'},
                {SRC: './assets/announcements/ADNAN_COKER/6.d.webp', DESCRIPTION: '\"Domes\" 150x150 cm, TÜA, 2017'},
                {SRC: './assets/announcements/ADNAN_COKER/7.d.webp', DESCRIPTION: '\"Untitled\" 179,4x179,4 cm, TÜA, 1996'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/ADNAN_COKER/0.m.webp', DESCRIPTION: '\"Mor Ötesi Boşluk\" 50x50 cm, TÜYB, 1979'},
                {SRC: './assets/announcements/ADNAN_COKER/1.m.webp', DESCRIPTION: '\"Mavi Kompozisyon\" 52x49 cm, KÜG, 1963'},
                {SRC: './assets/announcements/ADNAN_COKER/2.m.webp', DESCRIPTION: '\"Açık Simetri II\" 60x80 cm, TÜYB, 1975'},
                {SRC: './assets/announcements/ADNAN_COKER/3.m.webp', DESCRIPTION: '\"Simetri Geçiş II\" 89x116 cm, TÜYB, 1978'},
                {SRC: './assets/announcements/ADNAN_COKER/4.m.webp', DESCRIPTION: '\"Posa\' 92x73 cm, TÜYB, 1965'},
                {SRC: './assets/announcements/ADNAN_COKER/5.m.webp', DESCRIPTION: '\"Half Globes II\" 180x180,5 cm, TÜA, 1996'},
                {SRC: './assets/announcements/ADNAN_COKER/6.m.webp', DESCRIPTION: '\"Domes\" 150x150 cm, TÜA, 2017'},
                {SRC: './assets/announcements/ADNAN_COKER/7.m.webp', DESCRIPTION: '\"Untitled\" 179,4x179,4 cm, TÜA, 1996'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="http://lebriz.com/pages/artist.aspx?artistID=483&section=130&periodID=-1&pageNo=0&exhID=0&lang=TR&bhcp=1" target="_blank">' +
                'http://lebriz.com/pages/artist.aspx?artistID=483&section=130&periodID=-1&pageNo=0&exhID=0&lang=TR&bhcp=1' +
                '</a>',
            ]
        },
        {
            ID: 9,
            TEMPLATE: 0,
            NAME: 'Pablo Picasso',
            NAME_UPPERCASE: 'PABLO PICASSO',
            TOPIC: 'Pablo Picasso Hakkında',
            TOPIC_UPPERCASE: 'PABLO PICASSO HAKKINDA',
            ARTWORKS: 'Pablo Picasso Eserleri',
            ARTWORKS_UPPERCASE: 'PABLO PICASSO ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/PABLO_PICASSO/Pablo_Picasso.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/PABLO_PICASSO/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/PABLO_PICASSO/-1.m.webp',
            TEXT: '20. yüzyılın en büyük ve en etkili sanatçılarından biri olan Pablo Picasso, İspanya\'nın Malaga kentinde doğdu.<br>' +
                'Picasso\'nun babası bir çizim profesörüydü ve oğlunu akademik sanat alanında bir kariyer için yetiştirdi.' +
                ' Picasso ilk sergisini 13 yaşında açtı ve daha sonra modern sanat tarzlarıyla tam zamanlı deneyler yapabilmek için sanat okulunu bıraktı.' +
                ' İlk kez 1900\'de Paris\'e gitti ve 1901\'de Paris\'in prestijli sanat galerileriyle tanınan Rue Lafitte Caddesi\'ndeki bir galeride sergi açtı.' +
                ' 19 yaşındaki yetenekli İspanyol, o zamanlar Barselona dışında tanınmıyordu ancak şimdiden yüzlerce tablo üretmişti.' +
                ' Olumlu eleştiriler alarak, yılın geri kalanında Paris\'te kaldı ve daha sonra kalıcı olarak yerleşmek için şehre döndü.<br>' +
                '80 yılı aşkın sürede üretilen 50.000\'den fazla resim, çizim, gravür, heykel ve seramikten oluşan Picasso\'nun eserleri, örtüşen bir dizi dönemde anlatılıyor.' +
                ' Bilinen ilk dönemi -"mavi dönem"- Paris\'teki ilk sergisinden kısa bir süre sonra başladı.' +
                ' Yaşlı Gitarist (1903) gibi eserlerde Picasso, yoksulların melankolik dünyasını yansıtmak için mavi tonlarda resim yaptı.' +
                ' Mavi dönemi, sık sık sirk sahnelerini betimlediği "pembe dönem" ve ardından Picasso\'nun ilk heykel çalışmaları takip etti.' +
                ' 1907\'de Picasso, insan formunun parçalı ve çarpık temsili ile önceki Avrupa sanatından ayrılan, çığır açan eseri Avignonlu Kızlar\'ı yaptı.' +
                ' Avignonlu Kızlar, hem Afrika maske sanatının hem de Paul Cezanne\'nin Picasso üzerindeki etkisini gösterdi.' +
                ' Bu eser, 1909\'da Picasso ve Fransız ressam Georges Braque tarafından kurulan Kübist hareketin öncüsü olarak görülüyor.<br>' +
                'Analitik ve sentetik olmak üzere iki aşamaya ayrılan kübizmde, Picasso ve Braque, sanat eserinin sanatsal değere sahip olması için "gerçekliği temsil etmesi gerekmediği" modern ilkesini oluşturdu.' +
                ' Picasso\'nun başlıca kübist eserleri Sergey Diaghilev\'in Ballets Russes (1917) topluluğu için yaptığı kostümler, setler ve Üç Müzisyen (1921) eserini içeriyordu.' +
                ' Picasso ve Braque\'nin kübist deneyleri, kolaj da dahil olmak üzere birçok yeni sanatsal tekniğin icadıyla sonuçlandı.<br>' +
                'Kübizm\'den sonra Picasso, klasik ve Akdeniz temalarını araştırdı ve çalışmalarında şiddet ve ıstırap görüntüleri giderek daha fazla ortaya çıktı.' +
                ' 1937\'de bu eğilim, İspanya İç Savaşı sırasında Alman savaş uçakları tarafından yok edildiğinde Bask\'ın Guernica kasabasının yaşadığı dehşeti ve ıstırabı hatırlatan anıtsal bir eser olan başyapıt Guernica\'da doruğa ulaştı.' +
                ' Picasso, Nazi işgali sırasında Paris\'te kaldı ancak faşizme şiddetle karşı çıktı ve savaştan sonra Fransız Komünist Partisi\'ne katıldı.<br>' +
                'Picasso\'nun II. Dünya Savaşı\'ndan sonraki çalışmaları önceki eserlerine göre daha az prova edilmişti ancak  ateşli bir şekilde çalışmaya devam etti.' +
                ' Ticari ve eleştirel başarının tadını çıkardı. Fantastik eserler üretti, seramiklerle denemeler yaptı ve sanat tarihindeki diğer ustaların eserlerinin üzerini boyadı.' +
                ' Yoğun bakışları ve otoriter kişiliği ile tanınan yaşamı boyunca bir dizi yoğun ve örtüşen aşk ilişkisi yaşadı. 1973 yılında 91 yaşındaki vefatına kadar sanat üretmeye devam etti.',
            TEXT_LIMIT_DESKTOP: 1150,
            TEXT_LIMIT_MOBILE: 1180,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://www.history.com/this-day-in-history/pablo-picasso-born</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/PABLO_PICASSO/0.d.webp', DESCRIPTION: '"Sarı Picador" 24x19 cm, Ahşap Üzeri Yağlı Boya, 1889'},
                {
                    SRC: './assets/announcements/PABLO_PICASSO/1.d.webp',
                    DESCRIPTION: '"Casagemas\'ın Ölümü" 27x35 cm, Ahşap Üzeri Yağlı Boya, 1901'
                },
                {SRC: './assets/announcements/PABLO_PICASSO/2.d.webp', DESCRIPTION: '"Trajedi" 105.4x69 cm, Ahşap Üzeri Yağlı Boya, 1903'},
                {SRC: './assets/announcements/PABLO_PICASSO/3.d.webp', DESCRIPTION: '"Yaşlı Gitarist" 121.3x82.5 cm, TÜYB, 1903-1904'},
                {SRC: './assets/announcements/PABLO_PICASSO/4.d.webp', DESCRIPTION: '"Saltimbanques Ailesi" 212.8x229.6 cm, TÜYB, 1905'},
                {SRC: './assets/announcements/PABLO_PICASSO/5.d.webp', DESCRIPTION: '"Otoportre" 39x30 cm, TÜYB, 1906'},
                {SRC: './assets/announcements/PABLO_PICASSO/6.d.webp', DESCRIPTION: '"Avignonlu Kızlar" 243.9x233.7 cm, TÜYB, 1907'},
                {SRC: './assets/announcements/PABLO_PICASSO/7.d.webp', DESCRIPTION: '"Ambroise Vollard Portresi" 92x65 cm, TÜYB, 1910'},
                {SRC: './assets/announcements/PABLO_PICASSO/8.d.webp', DESCRIPTION: '"Guernica" 349x776 cm, TÜYB, 1937'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/PABLO_PICASSO/0.m.webp', DESCRIPTION: '"Sarı Picador" 24x19 cm, Ahşap Üzeri Yağlı Boya, 1889'},
                {
                    SRC: './assets/announcements/PABLO_PICASSO/1.m.webp',
                    DESCRIPTION: '"Casagemas\'ın Ölümü" 27x35 cm, Ahşap Üzeri Yağlı Boya, 1901'
                },
                {SRC: './assets/announcements/PABLO_PICASSO/2.m.webp', DESCRIPTION: '"Trajedi" 105.4x69 cm, Ahşap Üzeri Yağlı Boya, 1903'},
                {SRC: './assets/announcements/PABLO_PICASSO/3.m.webp', DESCRIPTION: '"Yaşlı Gitarist" 121.3x82.5 cm, TÜYB, 1903-1904'},
                {SRC: './assets/announcements/PABLO_PICASSO/4.m.webp', DESCRIPTION: '"Saltimbanques Ailesi" 212.8x229.6 cm, TÜYB, 1905'},
                {SRC: './assets/announcements/PABLO_PICASSO/5.m.webp', DESCRIPTION: '"Otoportre" 39x30 cm, TÜYB, 1906'},
                {SRC: './assets/announcements/PABLO_PICASSO/6.m.webp', DESCRIPTION: '"Avignonlu Kızlar" 243.9x233.7 cm, TÜYB, 1907'},
                {SRC: './assets/announcements/PABLO_PICASSO/7.m.webp', DESCRIPTION: '"Ambroise Vollard Portresi" 92x65 cm, TÜYB, 1910'},
                {SRC: './assets/announcements/PABLO_PICASSO/8.m.webp', DESCRIPTION: '"Guernica" 349x776 cm, TÜYB, 1937'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="https://www.pablo-ruiz-picasso.net/work-261.php" target="_blank">https://www.pablo-ruiz-picasso.net/work-261.php</a>',
                '<a class="gray-underline" href="https://arthive.com/pablopicasso/works/196810~The_death_of_Casagemas" target="_blank">https://arthive.com/pablopicasso/works/196810~The_death_of_Casagemas</a>',
                '<a class="gray-underline" href="https://www.wikiart.org/en/pablo-picasso/the-death-of-casagemas-1901" target="_blank">https://www.wikiart.org/en/pablo-picasso/the-death-of-casagemas-1901</a>',
            ]
        },
        {
            ID: 10,
            TEMPLATE: 0,
            NAME: 'Ahmet Oran',
            NAME_UPPERCASE: 'AHMET ORAN',
            TOPIC: 'Ahmet Oran Hakkında',
            TOPIC_UPPERCASE: 'AHMET ORAN HAKKINDA',
            ARTWORKS: 'Ahmet Oran Eserleri',
            ARTWORKS_UPPERCASE: 'AHMET ORAN ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/AHMET_ORAN/Ahmet_Oran.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_ORAN/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_ORAN/-1.m.webp',
            TEXT: 'Soyut eserleriyle tanınan Ahmet Oran, 1957 yılında Çanakkale’de doğmuştur.<br>' +
                '1977 yılında İstanbul Devlet Güzel Sanatlar Akademisi’ne giren sanatçı,' +
                ' burada öğrenciyken aynı zamanda Viyana Tatbiki Güzel Sanatlar Yüksek Okulu’nda resim, cam boyama ve grafik eğitimi almıştır.' +
                ' Prof. Carl Unger’le (1980-1985) ve sonra Prof. A. Frohner’le (1985-1987) çalışan Ahmet Oran, Salzburg ve Graz’da açtığı iki sergi ile Avrupa’da resim dünyasına adım atmıştır.' +
                ' Otuz yılı aşkın süredir yurtiçinde ve yurtdışında bireysel ve grup sergilerinde yer almaktadır.' +
                ' Eserleri, İstanbul Modern ve Linz\'deki Lentos Sanat Müzesi gibi çağdaş sanat müzelerinde sergilenmektedir.' +
                ' Sanatçı yaşamını ve çalışmalarını İstanbul ve Viyana\'da sürdürmektedir.' +
                'Eserlerini; İstanbul\'un tarihi katmanları, hareketli geçmişi, heyecan verici çağdaş yaşamı ve durmayan enerjisinden aldığı ilhamla yarattığını ifade eden Ahmet Oran\'ın resimlerinde' +
                ' rengin fiziksel ten karakteri, resmin üstündeki veya altındaki boya tabakasının bir parçasının soyulmasıyla ortaya çıkar.' +
                ' Bu matris, sanatçı tarafından farklı şekillerde işlenir.' +
                ' Genellikle, renk uzayının titreşen bir gövdesini oluşturur ancak bu, görüntü taşıyıcının gerçek sınırlarının ötesine yayılır.' +
                'Sanatçı resimlerinin rastlantısal ya da spontan olmadığını, en başından itibaren tasarlandığını vurguluyor:' +
                ' “Bütün aşamaları, sonuna kadar belli bir program dahilinde adım adım gerçekleştiriyorum.' +
                ' Her rengin sunduğu etki tabii ki farklı.' +
                ' Söz konusu olan o etki; o resmin kimliği, karakteri. Bu zaten başından planlanmış bir durum”.' +
                ' Ahmet Oran, resimlerini “bakıldığında sadece resim olarak algılanan ve hep resim olarak kalacak resimler” olarak tanımlıyor.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '' +
                // '<div class="references">' +
                // ' <ol>' +
                // '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
                // ' </ol>' +
                // '</div>' +
                '',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/AHMET_ORAN/0.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/1.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/2.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/3.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/4.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/5.d.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/6.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 120x100 cm, TÜYB'},
                {SRC: './assets/announcements/AHMET_ORAN/7.d.webp', DESCRIPTION: '"Koyu Mavi Soyutlama" 110x110 cm, TÜYB, 2021'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/AHMET_ORAN/0.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/1.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/2.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/3.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/4.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/5.m.webp', DESCRIPTION: 'İsimsiz, 120x100 cm, TÜYB, 2022'},
                {SRC: './assets/announcements/AHMET_ORAN/6.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 120x100 cm, TÜYB'},
                {SRC: './assets/announcements/AHMET_ORAN/7.m.webp', DESCRIPTION: '"Koyu Mavi Soyutlama" 110x110 cm, TÜYB, 2021'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="https://www.aysilmuzayede.com/urun/4056148/ahmet-oran-1957-untitled-tuval-uzeri-yagli-boya-olcu-120x100cm" target="_blank">' +
                'https://www.aysilmuzayede.com/urun/4056148/ahmet-oran-1957-untitled-tuval-uzeri-yagli-boya-olcu-120x100cm</a>',
                '<a class="gray-underline" href="https://www.onlinemuzayede.com/en/product/4416839/ahmet-oran-navy-blue-abstraction" target="_blank">' +
                'https://www.onlinemuzayede.com/en/product/4416839/ahmet-oran-navy-blue-abstraction</a>',
                '<a class="gray-underline" href="https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/" target="_blank">' +
                'https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/</a>',
            ]
        },
        {
            ID: 11,
            TEMPLATE: 0,
            NAME: 'Fahrelnissa Zeid',
            NAME_UPPERCASE: 'FAHRELNİSSA ZEİD',
            TOPIC: 'Fahrelnissa Zeid Hakkında',
            TOPIC_UPPERCASE: 'FAHRELNİSSA ZEİD HAKKINDA',
            ARTWORKS: 'Fahrelnissa Zeid Eserleri',
            ARTWORKS_UPPERCASE: 'FAHRELNİSSA ZEİD ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/FAHRELNISSA_ZEID/Fahrelnissa_Zeid.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/FAHRELNISSA_ZEID/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/FAHRELNISSA_ZEID/-1.m.webp',
            TEXT: '7 Ocak 1901’de Büyükada’da doğan ressam Fahrelnissa Zeid, pek çok sanatçı yetiştirmiş Kabaağaçlı ailesindendir.' +
                ' Ailenin geçmişi asker kökenli olsa da Kabaağaçlı ailesinin üyeleri sanatın farklı dallarında isimlerini tarihe yazdırmıştır.' +
                ' Halikarnas Balıkçısı Cevat Şakir’in ve ressam Aliye Berger’in kardeşi, oyuncu Şirin Devrim ve ressam Nejad Devrim’in annesi, ilk kadın seramik sanatçımız Füreya Koral’ın teyzesi olan' +
                ' ünlü ressam Fahrelnissa Zeid sanatı ve karakteriyle adını tüm dünyaya duyurmuş yetenekli bir Türk kadınıdır.' +
                ' Ailesi kendisine Fahrünnisa diye hitap etse de yurt dışında ve resimlerindeki imzada adını Fahrelnissa olarak kullanmayı tercih eder.<br>' +
                'Öğrenim görürken İzzet Melih Devrim ile evlenir, 14 yıl süren evliliğinden ressam Nejad Melih Devrim ve tiyatro oyuncusu Şirin Devrim dünyaya gelir.<br>' +
                '1928 yılında eşiyle birlikte Paris’e gider.' +
                ' Académie Ranson’da ve Roger Bissiére atölyesinde resim derslerini takip eder.' +
                ' Ardından İstanbul’a döner, İstanbul’a döndüğü dönemde güzelliği ve kültürü ile belli çevrelerde oldukça beğenilen ve popüler bir isimdir.<br>' +
                'Fahrelnissa Zeid girdiği her ortamı havasıyla değiştirir.' +
                ' Zeid’in büyüleyici etkisini deneyimlemiş galeri yöneticisi Xavier Bureau’nun cümleleri Zeid’in etkisini özetler:<br>' +
                '<i>“Fahrelnissa Zeid fark edilmeden gelip geçen biri değildi.' +
                ' Şaşaalı kıyafetleri vardı, bir anlamda Katia Granoff’la (Fransız sanat taciri) aynı ırktandı, olağandışı kıyafetleri olan büyük kadınlar.' +
                ' Onlarla sokağa çıktığınızda fark edilmemeniz mümkün değildi.' +
                ' İkonlara benziyorlardı, gerçeği olağanüstülük boyutuna taşımak gerekir.' +
                ' Zaten Fahrelnissa Zeid’in kendisinin de bir olağanüstülük boyutunda yaşar gibi bir havası vardı.' +
                ' Benim için o gerçekten de 1001 Gece Masalları’ndan çıkmış bir karakterdi çünkü o hem çok teatraldi hem de bu teatralliği çok doğal ve çok basit bir şeymiş gibi taşırdı.' +
                ' Zeid’in, Katia Granoff’un yeğeni Pierre La Roc’un portresini yapmak üzere galerinin tam ortasına şövalesini yerleştirmesini hiç unutmayacağım.' +
                ' Bu neredeyse bir törendi.<br>' +
                'Şövale ortadaydı, gün portreye adanmıştı, gereken bütün aletler ressamın etrafına dizilmişti.' +
                ' O resim yaparken insanların orada olmasından hoşlanırdı.' +
                ' Resim yaparken seyredilmekten hoşlanan pek fazla ressam yoktur ama o açıkça bunu seviyordu.”</i><sup>[1]</sup><br>' +
                'Fahrelnissa Zeid, 1933’te İzzet Melih Bey’den ayrılır ve 1934’te Irak Kralı 1. Faysal’ın kardeşi, Irak’ın Türkiye büyükelçisi Prens Zeid bin Hüseyin ile evlenir.' +
                ' Eşinin elçilik yaptığı Berlin’in yanı sıra, eşinin kral naibi olduğu dönemde bir süre Bağdat’ta yaşar.' +
                ' 1936’da oğlu Ra’ad Bin Zeid, Berlin’de dünyaya gelir.<br>' +
                '1941’de İstanbul’a dönen Zeid 1944’te ilk sergisini Maçka’daki Ralli apartmanında açar.' +
                ' Bunu duyan herkes apartmanda yapılacak sergiye kimsenin gelmeyeceğini konuşur.' +
                ' Fahrelnissa bütün eşyaları ambara yollar ve apartmanda tam 180 tane eserini sergiler.' +
                ' Sergiye yediden yetmişe herkes büyük bir ilgi gösterir.<br>' +
                'Resmin fotoğraftan farklı olduğunu söyleyen Zeid portreleriyle ilgili şu cümleleri kurar:<br>' +
                '<i>“Ben modelimi benden yeterince uzağa yerleştiririm ki o kişinin varlığı ve ruhu önünde genel bir izlenime sahip olayım,' +
                ' detayları görmeyeyim sözde figüre bakarak aslında o kişinin varlığı ve ruhu önünde genel bir izlenime sahip olayım.' +
                ' Bu nedenle benim çalışacağım model benden 6 ila 8 metre uzaklıkta olmalı.' +
                ' Bu şekilde bir nevi belirsizlik içerisinde çalışırım.' +
                ' Hiçbir şey kesin değildir ve ben de böylece kendimi savunma durumuna enerji ile dolu bir hale geçebilirim ve şöyle hissederim;' +
                ' şimdi kazanmak lazım, bu bir savaş ve savaşı kazanmak lazım.' +
                ' O lekesiz beyazlık üzerinde, benden uzak olan ve pek de iyi göremediğim o insanın ruhunu yansıtmak lazım.”</i><sup>[2]</sup><br>' +
                '1940’ların sonu, artık en bilinen yapıtları olan soyut kompozisyonlarına yönelmeye başladığı yıllardır.' +
                ' Bunlar son derece anıtsal ve ışıklı renklerin kullanıldığı, yoğun bir lirizm içeren eserlerdir.' +
                ' Fahrelnissa Zeid’in sanatındaki soyutun kaynakları üzerine, dönemin eleştirmenleri birbirinden farklı görüşler dile getirmiştir.' +
                ' Avrupalı eleştirmenler oryantalist ve politik bir ilgiyle onu ele almış ve sanatındaki öğelerde özellikle Doğu’ya dair izler aramışlardır.' +
                ' Geometrik ve serbest soyutlamacı kompozisyonlardan oluşan bu yapıtlarda kimilerine göre bir İslami estetik söz konusudur.<sup>[3]</sup><br>' +
                'Başlangıçta soyut resim yapmayı istemeyen, hatta direnen sanatçı, neden soyut resim yaptığını şöyle açıklar:<br>' +
                '<i>“Sanatçının yaşamı, anılan sanatı oluşturur, hatta değiştirir.' +
                ' Soyut resim yapmamın birkaç nedeni oldu.' +
                ' 1938’de Bağdat’a ilk gidişimde kaldığım sarayımsı evin penceresinden sabahın erken saatlerinde,' +
                ' başlarında taşıdıkları yoğurt kaseleriyle pazara giden kadınların çok hızlı geçişlerini gördüm.' +
                ' Çocukluğumda da pencereden baktığımda insanları kafesin arkasından görürdüm.' +
                ' Daha doğrusu gördüklerim kişiler değil, onların renkleriydi.' +
                ' Bu yüzden resimlerimde renkleri siyah çizgilerle böldüm.”</i><sup>[4]</sup><br>' +
                'Zeid’in 1962 tarihli tablosu ‘Atomun Parçalanışı ve Bitkisel Yaşam’ 2013 yılında Christie’s Müzayede Evi tarafından düzenlenen müzayedede,' +
                ' 2 milyon 741 bin dolara yani yaklaşık 45 milyon liraya satıldı ve Zeid, bu tablonun satışıyla Ortadoğu’nun en yüksek fiyatla satılan eserini resmeden kadın sanatçı unvanını kazandı.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>Cengiz Özkarabekir, Gökkuşağında İki Kuşak, 2006</li>' +
                '   <li>Cengiz Özkarabekir, Gökkuşağında İki Kuşak, 2006</li>' +
                '   <li>leblebitozu.com, Fahrelnissa Zeid’in 23 Resmi ve Hayatı, 2016</li>' +
                '   <li>oggusto.com, Fahrelnissa Zeid; Hayatı, Eserleri, Bilinmeyenleri, 2021</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/0.d.webp', DESCRIPTION: '\"Zeynep\" 100x64 cm, TÜYB, 1980'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/1.d.webp', DESCRIPTION: '\"Çerkez Gelini\" 135x100 cm, TÜYB, 1980-1985'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/2.d.webp', DESCRIPTION: '\"Portrait of Seteney Shami\" 100x79 cm, TÜYB, 1985'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/3.d.webp', DESCRIPTION: '\"Autoportrait\" 120x100 cm, TÜYB, 1988'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/4.d.webp', DESCRIPTION: '\"L\'Oiseau Übü\" 146x113 cm, TÜYB, 1950'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/5.d.webp', DESCRIPTION: '\"Portrait Of A Young Boy\" 124.5x100 cm, TÜYB, -'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/6.d.webp', DESCRIPTION: '\"Arabian Queen\" 91.5x71 cm, TÜYB, -'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/7.d.webp', DESCRIPTION: '\"Lost Horizon\" 77x100 cm, TÜYB, 1947'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/0.m.webp', DESCRIPTION: '\"Zeynep\" 100x64 cm, TÜYB, 1980'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/1.m.webp', DESCRIPTION: '\"Çerkez Gelini\" 135x100 cm, TÜYB, 1980-1985'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/2.m.webp', DESCRIPTION: '\"Portrait of Seteney Shami\" 100x79 cm, TÜYB, 1985'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/3.m.webp', DESCRIPTION: '\"Autoportrait\" 120x100 cm, TÜYB, 1988'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/4.m.webp', DESCRIPTION: '\"L\'Oiseau Übü\" 146x113 cm, TÜYB, 1950'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/5.m.webp', DESCRIPTION: '\"Portrait Of A Young Boy\" 124.5x100 cm, TÜYB, -'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/6.m.webp', DESCRIPTION: '\"Arabian Queen\" 91.5x71 cm, TÜYB, -'},
                {SRC: './assets/announcements/FAHRELNISSA_ZEID/7.m.webp', DESCRIPTION: '\"Lost Horizon\" 77x100 cm, TÜYB, 1947'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="http://www.artnet.com/artists/fahrelnissa-zeid/14" target="_blank">http://www.artnet.com/artists/fahrelnissa-zeid/14</a>',
            ]
        },
        {
            ID: 12,
            TEMPLATE: 0,
            NAME: 'Haluk Akakçe',
            NAME_UPPERCASE: 'HALUK AKAKÇE',
            TOPIC: 'Haluk Akakçe Hakkında',
            TOPIC_UPPERCASE: 'HALUK AKAKÇE HAKKINDA',
            ARTWORKS: 'Haluk Akakçe Eserleri',
            ARTWORKS_UPPERCASE: 'HALUK AKAKÇE ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/HALUK_AKAKCE/Haluk_Akakce.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/HALUK_AKAKCE/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/HALUK_AKAKCE/-1.m.webp',
            TEXT: 'Haluk Akakçe çağdaş sanat dünyasının en önemli isimlerinden, her sergisi bir olay… Sıradışı, yaratıcı ve dahi…<br>' +
                'Haluk Akakçe’nin hikayesi Ankara’da başladı.' +
                ' Dedesinin oymaları, çizimleri ve babasının balet olmasıyla sanatla çok küçük yaşta tanıştı.' +
                ' Akakçe en büyük şanslarından birinin ailesinin onu özgür bırakması ve yönlendirmemeye çalışmaması olduğunu söylüyor.<br>' +
                'Bilkent İç Mimarlık Bölümü, Chicago Sanat Enstitüsü ve Londra Kraliyet Akademisi’nden mezun olan sanatçı bu okulların hepsini birinci olarak bitirdi.' +
                ' Bir insan ne yaparsa yapsın, yaptığı her işin en iyisini yapması gerektiğini savunan Akakçe askerde de kuralı bozmayarak örnek asker olarak sertifika aldı.<br>' +
                'Kendisini; yapıtları, boşluk ve sonsuz zaman hissi veren bir sanatçı olarak tanımlayan multidisipliner sanatçı Haluk Akakçe’nin çalışmaları,' +
                ' video enstalasyonlardan duvar resimlerine, panel ve kağıt üzerine akrilikten heykele geniş bir yelpazede çeşitlilik gösteriyor.<br>' +
                '<i>Geceyi seviyorum çünkü bir yandan benim yaptığım iş yazı yazmak ya da bir anlamda dua etmek gibi bir şey o yüzden de çok tanık olsun istemiyorum, çok ses olsun istemiyorum.' +
                ' Sanki gizlice sadece bana ait olan zamanda bir üretim olduğuna inanmak istiyorum.</i><br>' +
                '-Haluk Akakçe<br>' +
                'Dünyanın önde gelen sanat merkezlerinde birçok sergi açan Akakçe Las Vegas valisinin davetiyle 2006 yılına damgasını vuran projesi “Sky is the Limit”i' +
                ' Fremont Caddesi’ndeki dünyanın en büyük LED ekranı olan Viva Vision’da gerçekleştirdi.' +
                ' Las Vegas\'ta şehir merkezinde, dört blok boyunca devam eden, dev ekranlardan oluşmuş bir kubbe olarak nitelenebilecek bu işin ardından Akakçe,' +
                ' Louis Vuitton markası tarafından geçmişte pek çok değerli sanatçının davet edildiği Maison Louis Vuitton Champs- Elysées’de video enstelasyonu sergiledi.<br>' +
                '<i>‘Ben gelecekten geliyorum.’ Haluk böyle söyler. Ve biliyor musunuz, gerçekten gelecekten geliyor olabilir.</i><br>' +
                '-Richard Taittinger, Richard Taittinger Galeri’nin Kurucusu<br>' +
                '21 sene New York’ta yaşamış olan sanatçı, geçtiğimiz yıllarda Türkiye’ye döndü.' +
                ' Bodrum ve İstanbul’daki atölyelerinde sanat çalışmalarını sürdürdükten sonra, 2023\'te vefat etti.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references"></div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/HALUK_AKAKCE/0.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 190x125 cm, AÜKT, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/1.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 185x185 cm, TÜYB, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/2.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 205x185 cm, TÜYB, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/3.d.webp', DESCRIPTION: '"Sunshine on her shoulders" 208.3x256.5 cm, AÜAB, 2015'},
                {SRC: './assets/announcements/HALUK_AKAKCE/4.d.webp', DESCRIPTION: '"Platonic" 122x90cm, TÜYB, 2017'},
                {SRC: './assets/announcements/HALUK_AKAKCE/5.d.webp', DESCRIPTION: '"Hollow Man With Feelings" 122x90cm, TÜYB, 2017'},
                {SRC: './assets/announcements/HALUK_AKAKCE/6.d.webp', DESCRIPTION: 'İsimsiz 120x80cm, TÜYB, 2020'},
                {SRC: './assets/announcements/HALUK_AKAKCE/7.d.webp', DESCRIPTION: '"Odalisque" 122x102cm, AÜAB, 2015'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/HALUK_AKAKCE/0.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 190x125 cm, AÜKT, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/1.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 185x185 cm, TÜYB, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/2.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 205x185 cm, TÜYB, -'},
                {SRC: './assets/announcements/HALUK_AKAKCE/3.m.webp', DESCRIPTION: '"Sunshine on her shoulders" 208.3x256.5 cm, AÜAB, 2015'},
                {SRC: './assets/announcements/HALUK_AKAKCE/4.m.webp', DESCRIPTION: '"Platonic" 122x90cm, TÜYB, 2017'},
                {SRC: './assets/announcements/HALUK_AKAKCE/5.m.webp', DESCRIPTION: '"Hollow Man With Feelings" 122x90cm, TÜYB, 2017'},
                {SRC: './assets/announcements/HALUK_AKAKCE/6.m.webp', DESCRIPTION: 'İsimsiz 120x80cm, TÜYB, 2020'},
                {SRC: './assets/announcements/HALUK_AKAKCE/7.m.webp', DESCRIPTION: '"Odalisque" 122x102cm, AÜAB, 2015'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="https://artam.com/muzayede/262-cagdas-sanat-eserleri/haluk-akakce-1970-soyut-kompozisyon-4" target="_blank">https://artam.com/muzayede/262-cagdas-sanat-eserleri/haluk-akakce-1970-soyut-kompozisyon-4</a>',
                '<a class="gray-underline" href="https://www.galeriartget.com/urun/3226010/haluk-akakce/" target="_blank">https://www.galeriartget.com/urun/3226010/haluk-akakce/</a>',
                '<a class="gray-underline" href="https://www.galeriartget.com/urun/3226016/haluk-akakce/" target="_blank">https://www.galeriartget.com/urun/3226016/haluk-akakce/</a>',
                '<a class="gray-underline" href="http://www.artnet.com/artists/haluk-akakce/" target="_blank">http://www.artnet.com/artists/haluk-akakce/</a>',
            ]
        },
        {
            ID: 13,
            TEMPLATE: 0,
            NAME: 'Ömer Uluç',
            NAME_UPPERCASE: 'ÖMER ULUÇ',
            TOPIC: 'Ömer Uluç Hakkında',
            TOPIC_UPPERCASE: 'ÖMER ULUÇ HAKKINDA',
            ARTWORKS: 'Ömer Uluç Eserleri',
            ARTWORKS_UPPERCASE: 'ÖMER ULUÇ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/OMER_ULUC/Omer_Uluc.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/OMER_ULUC/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/OMER_ULUC/-1.m.webp',
            TEXT: '1931 yılında doğan Ömer Uluç dünya çapında ünlü bir Türk ressamdır.' +
                ' Farklı ülkelerde 30’u aşkın kişisel sergi açmıştır ve katıldığı organizasyonlarda sunduğu eserleriyle tüm dünyayı kendine hayran bırakmıştır.<br>' +
                'Türk sanatına kattığı değerle öne çıkan Uluç, 1953 yılında Robert Koleji’nden mezun olduktan sonra Amerika Birleşik Devletleri’nde mühendislik eğitimi görmüştür.' +
                ' Bu eğitim esnasında Tavanarası Ressamları grubuna katılması hayatının dönüm noktalarından biri olmuştur.<br>' +
                'Tavanarası Ressamları adlı oluşumda özellikle kendisi gibi akademiye karşı olan ve daha özgürlükçü bir tarz benimseyen Nuri İyem ile yollarının kesişmesi sanat hayatını da şekillendirir.' +
                ' İlk resimlerini büyük ölçüde bu oluşum içinde gerçekleştirir.<br>' +
                'Sanatçı kariyerinde çeşitli konuları ele almış ve hayatının her döneminde maceracı bir kimliğe sahip olmuştur.' +
                ' Ömer Uluç, her resminde risk almak istemiştir. Bu da onun resim aşkıyla macera arayışını birleştirdiği nokta olmuştur.' +
                ' Doğanın ve hayatın farklı sahnelerini ve renklerini içeren onlarca resim yapan sanatçı, bu resimleri sergilerken de en az yaparken olduğu kadar heyecanlı olmuştur.<br>' +
                'Ömer Uluç’un resminde en çok dikkat çeken detaylardan birisi de, kullandığı somut materyallerdir.' +
                ' Hortumlardan lastiklere, endüstriyel malzemelerden kıvrılabilen materyallere kadar her türlü nesneden çeşitli figürler yaratabilme becerisine sahiptir.' +
                ' Son dönemlerde yaptığı pek çok sergide bu yaklaşımın izlerini bulmak mümkündür.<br>' +
                'Son günlerini İstanbul’da geçiren ve “Parçalanmanın Kimyası” adını koyduğu son sergisinde karakalem otoportresinin yanına ' +
                ' “Ölümün olduğu yerde ben yokum/Benim olduğum yerde ölüm yok” sözlerini not düşen Ömer Uluç, iki yıl boyunca ağır bir akciğer kanseri tedavisi görürken de üretimine hiç ara vermedi.' +
                ' Ardında yüzlerce sanat eseri bırakarak İstanbul’da 79 yaşında iken, 28 Ocak 2010 tarihinde öldü.<sup>[1]</sup>',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://tr.wikipedia.org/wiki/Ömer_Uluç</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/OMER_ULUC/0.d.webp', DESCRIPTION: '"İki Figür ve Kuş" 75x75 cm, TÜYB, 1993'},
                {SRC: './assets/announcements/OMER_ULUC/1.d.webp', DESCRIPTION: '"Two Creatures-Portrait of Pink robot" 127x125 cm, TÜAB, 1994'},
                {SRC: './assets/announcements/OMER_ULUC/2.d.webp', DESCRIPTION: '"African Queen" 100x100 cm, TÜYB, 1989'},
                {SRC: './assets/announcements/OMER_ULUC/3.d.webp', DESCRIPTION: '"Creature and Woman" 150x150 cm, TÜAB, 1995'},
                {SRC: './assets/announcements/OMER_ULUC/4.d.webp', DESCRIPTION: '"Nude" 100x78 cm, TÜAB, 2003'},
                {SRC: './assets/announcements/OMER_ULUC/5.d.webp', DESCRIPTION: '"Dört Kuş" 98x98 cm, TÜYB, 1993'},
                {SRC: './assets/announcements/OMER_ULUC/6.d.webp', DESCRIPTION: '"Lady and Monster" 100x100 cm, TÜYB, 1987'},
                {SRC: './assets/announcements/OMER_ULUC/7.d.webp', DESCRIPTION: '"Mavi Kuş" 146x114 cm, TÜAB, 1997'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/OMER_ULUC/0.m.webp', DESCRIPTION: '"İki Figür ve Kuş" 75x75 cm, TÜYB, 1993'},
                {SRC: './assets/announcements/OMER_ULUC/1.m.webp', DESCRIPTION: '"Two Creatures-Portrait of Pink robot" 127x125 cm, TÜAB, 1994'},
                {SRC: './assets/announcements/OMER_ULUC/2.m.webp', DESCRIPTION: '"African Queen" 100x100 cm, TÜYB, 1989'},
                {SRC: './assets/announcements/OMER_ULUC/3.m.webp', DESCRIPTION: '"Creature and Woman" 150x150 cm, TÜAB, 1995'},
                {SRC: './assets/announcements/OMER_ULUC/4.m.webp', DESCRIPTION: '"Nude" 100x78 cm, TÜAB, 2003'},
                {SRC: './assets/announcements/OMER_ULUC/5.m.webp', DESCRIPTION: '"Dört Kuş" 98x98 cm, TÜYB, 1993'},
                {SRC: './assets/announcements/OMER_ULUC/6.m.webp', DESCRIPTION: '"Lady and Monster" 100x100 cm, TÜYB, 1987'},
                {SRC: './assets/announcements/OMER_ULUC/7.m.webp', DESCRIPTION: '"Mavi Kuş" 146x114 cm, TÜAB, 1997'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="http://www.artnet.com/artists/%C3%B6mer-ulu%C3%A7-2/" target="_blank">' +
                'http://www.artnet.com/artists/%C3%B6mer-ulu%C3%A7-2/</a>',
            ]
        },
        {
            ID: 14,
            TEMPLATE: 0,
            NAME: 'Nuri İyem',
            NAME_UPPERCASE: 'NURİ İYEM',
            TOPIC: 'Nuri İyem Hakkında',
            TOPIC_UPPERCASE: 'NURİ İYEM HAKKINDA',
            ARTWORKS: 'Nuri İyem Eserleri',
            ARTWORKS_UPPERCASE: 'NURİ İYEM ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/NURI_IYEM/Nuri_Iyem.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NURI_IYEM/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/NURI_IYEM/-1.m.webp',
            TEXT: 'Çocukken ölümüne tanık olduğu ablasının gözlerinden ve yüzünden esinlenen sanatçı Nuri İyem,' +
                ' sanatının ikonik ve ayrılmaz bir parçası haline gelen \'çekingen, güzel ve melankolik\' yüzlerin ressamıdır.<br>' +
                'Nuri İyem, 1915 yılında İstanbul Aksaray\'da memur Hüseyin Hüsnü Bey ile ev hanımı Melek Hanım\'ın yedinci ve son çocuğu olarak dünyaya geldi.' +
                ' Üç yıl sonra İyem ailesi, İyem\'in babasının görevi nedeniyle Türkiye\'nin güneydoğusundaki Mardin\'in Cizre kentine taşındı.' +
                ' İyem, gözleri ve bakışları sanatına damgasını vuracak olan ablası Aliye\'yi 1922\'de kaybetti. Ablasının ölümü onu derinden etkiledi.<br>' +
                'Resim yapmadan yaşayamayan Nuri İyem, zamanının çoğunu resimle geçirirdi.' +
                ' Derslerine çalışmak yerine geceleri de resimle uğraşırdı.' +
                ' Resimlerini gören Nazmi Ziya Güran’ın yüreklendirmesi sonucu öğrenimini yarıda bırakmaya karar verdi.' +
                ' İstanbul Güzel Sanatlar Akademisi’ne girdi. Öğreniminin ilk yılını Nazmi Ziya Güran Atölyesi’nde tamamladı.' +
                ' Teorik derslerini ise Ahmet Hamdi Tanpınar’dan aldı.<br>' +
                '1935 yılında Hikmet Onat Atölyesinde, 1936 yılında İbrahim Çallı Atölyesinde çalışmaya başladı.' +
                ' Kısa bir süre sonra da Leopold Levy Atölyesine geçti. Usta ressam Güzel Sanatlar Akademisi’ni birinci olarak bitirdi.<br>' +
                'Anadolulu kadın portreleriyle tanınmıştır.<br>' +
                'Ahmet Hamdi Tanpınar’ın, ‘Bir heykel kadar sımsıkı, yeşil mehtap aydınlığı kadar zarif, geçmiş zamanın havasını içinde taşıyan eski fresk ve ikonalar kadar yalın’ dediği kadın yüzleri,' +
                ' köyden kente göçün yoğunlaştığı, bireye ait sosyal hakların kadınlar aleyhine işlediği bir dönemin ürünüdür.' +
                ' Mahur, çekingen, güzel, utangaç ve melankolik halleri ile bu yüzler' +
                ' hem ölen ablasının hayali imgesi hem de zamanı aşan ikonik bir sembol olarak Nuri İyem’in sanatının billurlaşmış bir örneğidir.' +
                ' Sanatçının aynı tarihlerde gerçekleştirdiği, Anadolu gerçeğine ulusalcı bir bakışla yaklaştığı ‘göç’ resimlerinde de' +
                ' çalışan, emeğini topraktan çıkaran kadınlar sembolize edildi.<sup>[1]</sup><br>' +
                'Nuri İyem, binin üzerinde koleksiyonda yer alan arşivlenmiş, tescillenmiş ve kayıt altına alınmış 3 bin 500 civarında eseriyle' +
                ' bugün Türkiye’nin en çok tanınan ve eserleri farklı koleksiyonlarda yer alan ressamıdır.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://tr.wikipedia.org/wiki/Nuri_%C4%B0yem</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/NURI_IYEM/0.d.webp', DESCRIPTION: '"Toprak Ana", TÜYB, 1972'},
                {SRC: './assets/announcements/NURI_IYEM/1.d.webp', DESCRIPTION: '"Üç Güzeller" 120x80 cm, TÜYB, 1970’ler'},
                {SRC: './assets/announcements/NURI_IYEM/2.d.webp', DESCRIPTION: '"Portre" 50x45 cm, TÜYB, 1997'},
                {SRC: './assets/announcements/NURI_IYEM/3.d.webp', DESCRIPTION: '"Mustafa Kemal Atatürk" 48x37.5 cm, TÜYB, 1999'},
                {SRC: './assets/announcements/NURI_IYEM/4.d.webp', DESCRIPTION: '"Portre" 60x65cm, DÜYB, 1999'},
                {SRC: './assets/announcements/NURI_IYEM/5.d.webp', DESCRIPTION: '"Adalet Cimcoz’un Portresi" 65x75cm, DÜYB, 1952'},
                {SRC: './assets/announcements/NURI_IYEM/6.d.webp', DESCRIPTION: '"Bosnalı Müslüman" 25x31cm, TÜYB, 1970’ler '},
                {SRC: './assets/announcements/NURI_IYEM/7.d.webp', DESCRIPTION: '"Ümit İyem’in Portresi" 32.5x38.5cm, DÜYB, 1970’ler '},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/NURI_IYEM/0.m.webp', DESCRIPTION: '"Toprak Ana", TÜYB, 1972'},
                {SRC: './assets/announcements/NURI_IYEM/1.m.webp', DESCRIPTION: '"Üç Güzeller" 120x80 cm, TÜYB, 1970’ler'},
                {SRC: './assets/announcements/NURI_IYEM/2.m.webp', DESCRIPTION: '"Portre" 50x45 cm, TÜYB, 1997'},
                {SRC: './assets/announcements/NURI_IYEM/3.m.webp', DESCRIPTION: '"Mustafa Kemal Atatürk" 48x37.5 cm, TÜYB, 1999'},
                {SRC: './assets/announcements/NURI_IYEM/4.m.webp', DESCRIPTION: '"Portre" 60x65cm, DÜYB, 1999'},
                {SRC: './assets/announcements/NURI_IYEM/5.m.webp', DESCRIPTION: '"Adalet Cimcoz’un Portresi" 65x75cm, DÜYB, 1952'},
                {SRC: './assets/announcements/NURI_IYEM/6.m.webp', DESCRIPTION: '"Bosnalı Müslüman" 25x31cm, TÜYB, 1970’ler '},
                {SRC: './assets/announcements/NURI_IYEM/7.m.webp', DESCRIPTION: '"Ümit İyem’in Portresi" 32.5x38.5cm, DÜYB, 1970’ler '},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="http://www.nuriiyem.com/tr/eserleri" target="_blank">http://www.nuriiyem.com/tr/eserleri</a>',
            ]
        },
        {
            ID: 15,
            TEMPLATE: 0,
            NAME: 'Mustafa Ata',
            NAME_UPPERCASE: 'MUSTAFA ATA',
            TOPIC: 'Mustafa Ata Hakkında',
            TOPIC_UPPERCASE: 'MUSTAFA ATA HAKKINDA',
            ARTWORKS: 'Mustafa Ata Eserleri',
            ARTWORKS_UPPERCASE: 'MUSTAFA ATA ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/MUSTAFA_ATA/Mustafa_Ata.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/MUSTAFA_ATA/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/MUSTAFA_ATA/-1.m.webp',
            TEXT: '18 Mart 1945 tarihinde Trabzon’da doğan Mustafa Ata,' +
                ' Mimar Sinan Güzel Sanatlar Fakültesi (o zamanki adıyla İstanbul Devlet Güzel Sanatlar Akademisi) Resim Bölümü Adnan Çoker Atölyesi’nde öğrenim gördü.<sup>[1]</sup><br>' +
                '1966 yılından beri resim yapan Mustafa Ata, Münih, Kassel, Kopenhag, Tokyo, Darmstadt, Philadelphia, Kahire’de sergilere katıldı.' +
                ' Aynı zamanda değişik Avrupa ülkelerinin sanat merkezlerinde araştırmalar yaptı.' +
                ' 1978’de "Sentetik Plastik Malzemeler, Biçimlendirme Yöntemleri, Sanatta Kullanımı" adlı kitabı yayınlandı.<sup>[2]</sup><br>' +
                'Türk Resim Sanatı’nın usta isimlerinden olan sanatçı aynı zamanda uluslararası bir üne sahip.' +
                ' Ata, yapıtlarında bildik kalıpların, klasik form ve renklerin dışına çıkarak hareket ve kütleyi çizgi ve tonlarla birlikte sarıyor.' +
                ' Bu özelliğini etkin biçimde, özgün fırça vuruşlarıyla oluşturduğu figürleriyle ortaya koyuyor.' +
                ' Başta biçimin ön planda oluğu kompozisyonları uzun yılların çalışma ve deneyimiyle biçimin neredeyse ikinci planda tutulduğu yeni bir anlatıma doğru kaymıştır ama renk kaçınılmaz elemanıdır.<br>' +
                'Ankara, İzmir, İstanbul Resim ve Heykel müzelerinde, Yurtiçi ve Yurtdışı Özel ve Resmi koleksiyonlarda eserleri bulunan sanatçı' +
                ' yaşamını ve çalışmalarını İstanbul’da sürdürmekte ve Yeditepe Üniversitesi Güzel Sanatlar Fakültesi’nde öğretim üyesi olarak görev yapmaktadır.',
            TEXT_LIMIT_DESKTOP: 1150,
            TEXT_LIMIT_MOBILE: 1180,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://artam.com/muzayede/317-online-muzayede/mustafa-ata-1945-vlam</li>' +
                '   <li>https://artam.com/muzayede/317-online-muzayede/mustafa-ata-1945-vlam</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/MUSTAFA_ATA/0.d.webp', DESCRIPTION: 'İsimsiz 165x205 cm, TÜYAB, 2005'},
                {SRC: './assets/announcements/MUSTAFA_ATA/1.d.webp', DESCRIPTION: 'İsimsiz 150x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/2.d.webp', DESCRIPTION: 'İsimsiz 150x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/3.d.webp', DESCRIPTION: 'İsimsiz 90x110 cm, TÜYAB, 1997'},
                {SRC: './assets/announcements/MUSTAFA_ATA/4.d.webp', DESCRIPTION: 'İsimsiz 130x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/5.d.webp', DESCRIPTION: '"Abdi İpekçi\'ye Saygı" 50x70 cm, TÜYB, 1998'},
                {SRC: './assets/announcements/MUSTAFA_ATA/6.d.webp', DESCRIPTION: '"İlksiz ve Sonsuz MATA No:4" 50x60 cm, TÜKT, 2012'},
                {SRC: './assets/announcements/MUSTAFA_ATA/7.d.webp', DESCRIPTION: 'İsimsiz 140x170 cm, TÜKT, 2007'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/MUSTAFA_ATA/0.d.webp', DESCRIPTION: 'İsimsiz 165x205 cm, TÜYAB, 2005'},
                {SRC: './assets/announcements/MUSTAFA_ATA/1.d.webp', DESCRIPTION: 'İsimsiz 150x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/2.d.webp', DESCRIPTION: 'İsimsiz 150x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/3.d.webp', DESCRIPTION: 'İsimsiz 90x110 cm, TÜYAB, 1997'},
                {SRC: './assets/announcements/MUSTAFA_ATA/4.d.webp', DESCRIPTION: 'İsimsiz 130x180 cm, TÜYAB, 2011'},
                {SRC: './assets/announcements/MUSTAFA_ATA/5.d.webp', DESCRIPTION: '"Abdi İpekçi\'ye Saygı" 50x70 cm, TÜYB, 1998'},
                {SRC: './assets/announcements/MUSTAFA_ATA/6.d.webp', DESCRIPTION: '"İlksiz ve Sonsuz MATA No:4" 50x60 cm, TÜKT, 2012'},
                {SRC: './assets/announcements/MUSTAFA_ATA/7.d.webp', DESCRIPTION: 'İsimsiz 140x170 cm, TÜKT, 2007'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="http://gulersanat.com/mustafa-ata/" target="_blank">http://gulersanat.com/mustafa-ata/</a>',
                '<a class="gray-underline" href="https://www.aysilmuzayede.com/en/product/4215785/mustafa-ata" target="_blank">https://www.aysilmuzayede.com/en/product/4215785/mustafa-ata</a>',
                '<a class="gray-underline" href="https://www.devmuzayede.com/urun/3706619/mustafa-ata" target="_blank">https://www.devmuzayede.com/urun/3706619/mustafa-ata</a>',
                '<a class="gray-underline" href="https://www.aysilmuzayede.com/urun/4056115/mustafa-ata" target="_blank">https://www.aysilmuzayede.com/urun/4056115/mustafa-ata</a>',
            ]
        },
        {
            ID: 16,
            TEMPLATE: 0,
            NAME: 'Özdemir Altan',
            NAME_UPPERCASE: 'ÖZDEMİR ALTAN',
            TOPIC: 'Özdemir Altan Hakkında',
            TOPIC_UPPERCASE: 'ÖZDEMİR ALTAN HAKKINDA',
            ARTWORKS: 'Özdemir Altan Eserleri',
            ARTWORKS_UPPERCASE: 'ÖZDEMİR ALTAN ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/OZDEMIR_ALTAN/Ozdemir_Altan.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/OZDEMIR_ALTAN/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/OZDEMIR_ALTAN/-1.m.webp',
            TEXT: '1931’de Giresun’da doğan Özdemir Altan, İstanbul Devlet Güzel Sanatlar Akademisi Resim Bölümü’nden 1956’da mezun oldu.' +
                ' Mezunu olduğu okulda 1956 yılında akademisyen olarak çalışmaya  başladı ve 1988’deki emekliliğine kadar bu görevini sürdürdü.<sup>[1]</sup>' +
                ' Avangard düşünce, Pop art ve postmodernizm gibi akımların Türkiye’deki öncülüğünü yapan usta sanatçı, 1953-1956 yılları arasında Paris Biannelleri gibi pek çok ulusal ve uluslararası etkinliğe katıldı.' +
                ' 1966’da Çağdaş Ressamlar Cemiyeti’nin verdiği “Yılın En Başarılı Genç Ressamı” ödülünü aldı.<sup>[2]</sup>' +
                ' Ardından, 1969 senesinde TRT İstanbul Radyosu tarafından fuaye duvarının süslenmesi için açılan yarışmaya yaptığı duvar halısı tasarımıyla katılarak birinci oldu ve bu çalışmasını 1974’de hayata geçirdi.' +
                ' Sanat hayatı boyunca Roma, Üsküp, Bükreş, Belgrad, Moskova, Paris, Londra gibi pek çok Avrupa şehrinde düzenlenen sergiye katılmış, sanatına uluslararası bir boyut kazandırdı.<br>' +
                'Çağdaş sanat akımları üzerine yaptığı akademik ve sanatsal çalışmaların yanı sıra küratör olarak pek çok önemli çalışmaya imza attı.' +
                ' Bu anlamda Altan’ın 1968 tarihli Avni Lifij sergisi Türkiye’deki en erken küratörlük girişimlerinden biridir.<sup>[3]</sup>' +
                ' Bunu Zeki Faik, Mahmut Cuda ve Bedri Rahmi gibi isimlerin yanısıra çok sayıda genç sanatçının sergileri izledi.<sup>[4]</sup><br>' +
                'Özdemir Altan’ın sanatında benimsediği “rastlantısal buluşma yöntemi” 1988-1992 yıllarında yaptığı çalışmaların bir ürünüdür.' +
                ' Resim sanatındaki espas kavramını detaylıca inceleyen ve derslerinde temel öğreti maddesi olarak ele alan ünlü sanatçının' +
                ' bu konuda vardığı sonucu "sanat birbirinden farklı, kavram, köken, yapı ve mantıkların birleşmesiyle oluşur" şeklinde açıklamak mümkündür.<sup>[5]</sup>' +
                ' Altan daha sonra geliştirdiği bu sanatsal yaklaşıma uluslararası nitelik kazandıracak çalışmalarda bulundu.<br>' +
                '1988’de Güzel Sanatlar Akademisi’ndeki atöyle hocalığı görevinden emekli olan usta sanatçı;' +
                ' Romantik Dönem, Krallar ve Kraliçeler, Çok Kişiyle Piyano Çalışmaları ve Soyağaçları gibi isimler verdiği dönemlerde pek çok önemli sanat eseri ortaya koymuştur.<sup>[6]</sup><br>' +
                'Sanatçı, akademisyen, küratör ve yazar kimlikleriyle Türk sanatı adına önemli işlere imza atan Altan İstanbul’da yaşamını sürdürmekte ve çalışmalarına devam etmektedir.' +
                ' Sanatçının pek çok önemli eseri Ankar, İzmir ve Erzurum’daki Resim ve Heykel Müze ve Galerileri’nde sergilenmektedir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>“Modern Turkish Art: Özdemir Altan.” Modern Turkish art: OZDEMIR ALTAN. http://ozdemiraltan.com</li>' +
                '   <li>Salt research: Özdemir Altan. Accessed July 24, 2023. https://archives.saltresearch.org/handle/123456789/41863</li>' +
                '   <li>Özdemir Altan .” Ankara Resim ve Heykel Müzesi. https://arhm.ktb.gov.tr/artists/detail/2050/ozdemir-altan-1931-...</li>' +
                '   <li>Modern Turkish Art: Özdemir Altan.” Modern Turkish art: OZDEMIR ALTAN. http://ozdemiraltan.com</li>' +
                '   <li>Özdemir Altan .” Ankara Resim ve Heykel Müzesi. https://arhm.ktb.gov.tr/artists/detail/2050/ozdemir-altan-1931-...</li>' +
                '   <li>Özdemir Altan. https://sanat.terakki.org.tr/koleksiyon/ozdemir-altan/</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/OZDEMIR_ALTAN/0.d.webp', DESCRIPTION: 'Although he is a well-read king, he is disliked by his people, 1965, karışık materyal, 40 x 30 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/1.d.webp', DESCRIPTION: 'Players, 1964, guaj, 84 x 64 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/2.d.webp', DESCRIPTION: 'Family Tree, 1991, TÜYB, 130 x 194 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/3.d.webp', DESCRIPTION: 'Family Tree 1993, TÜAB, 81 x 100 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/4.d.webp', DESCRIPTION: 'New Period, 2004, TÜAB, 73 x 100 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/5.d.webp', DESCRIPTION: 'Altan and A. Arpat 1999, Karışık teknik, 162 x 130 cm'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/OZDEMIR_ALTAN/0.m.webp', DESCRIPTION: 'Although he is a well-read king, he is disliked by his people, 1965, karışık materyal, 40 x 30 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/1.m.webp', DESCRIPTION: 'Players, 1964, guaj, 84 x 64 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/2.m.webp', DESCRIPTION: 'Family Tree, 1991, TÜYB, 130 x 194 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/3.m.webp', DESCRIPTION: 'Family Tree 1993, TÜAB, 81 x 100 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/4.m.webp', DESCRIPTION: 'New Period, 2004, TÜAB, 73 x 100 cm'},
                {SRC: './assets/announcements/OZDEMIR_ALTAN/5.m.webp', DESCRIPTION: 'Altan and A. Arpat 1999, Karışık teknik, 162 x 130 cm'},
            ],
            ARTWORK_REFERENCES: [
                '<a class="gray-underline" href="http://www.ozdemiraltan.com/" target="_blank">http://www.ozdemiraltan.com/</a>',
            ]
        },
        {
            ID: 17,
            TEMPLATE: 0,
            NAME: 'Ahmet Yeşil',
            NAME_UPPERCASE: 'AHMET YEŞİL',
            TOPIC: 'Ahmet Yeşil Hakkında',
            TOPIC_UPPERCASE: 'AHMET YEŞİL HAKKINDA',
            ARTWORKS: 'Ahmet Yeşil Eserleri',
            ARTWORKS_UPPERCASE: 'AHMET YEŞİL ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/AHMET_YESIL/Ahmet_Yesil.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_YESIL/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_YESIL/-1.m.webp',
            TEXT: 'Ahmet Yeşil, 1954 yılında Mersin\'de doğdu ve burada büyüdü.' +
                ' Sanat hayatına Mersin\'de başlayan ve bu şehirde yaşamını sürdüren Yeşil, resim eğitimini Ressam Nuri Abaç, İlhan Çevik ve Heykeltraş Ernür Tüzün gibi önemli isimlerden aldı.<sup>[1]</sup>' +
                ' Sanatçının eserleri Türkiye\'deki birçok özel koleksiyonda yer alırken, aynı zamanda Almanya, Amerika, Kanada, Hollanda, İngiltere gibi önemli yabancı koleksiyonlarda da kendine yer bulmuştur.<sup>[2]</sup><br>' +
                'Ahmet Yeşil, Unicef Uluslararası Plastik Sanatlar Derneği üyesi olarak sanatsal kariyerine katkı sağlamıştır.' +
                ' Sanatçı, 141 kişisel sergi açmanın yanı sıra 315 karma ve yarışma sergisine katılarak ulusal ve uluslararası arenada önemli bir varlık göstermiştir.<sup>[3]</sup><br>' +
                'Sanatçının eserlerinde farklı dönemler ve temalar öne çıkar.' +
                ' İlk yıllarında izlenimci bir tarza sahip olan Yeşil, "Figür Dönemi" olarak adlandırılan döneminde kadın ve çocuk gibi toplumsal temalara odaklanmıştır.' +
                ' Daha sonra annesinin terzi olması ve denizle olan bağından etkilenerek halat ve ip motifini resimlerinde sıkça kullanmıştır.' +
                ' Bu motif, sanatçının içsel dünyasının yansıması olarak kabul edilir ve onun eserlerine derinlik katmıştır.<br>' +
                'Yeşil, resimlerinde sosyal, siyasal, ekonomik, ekolojik ve kişisel yaşamın farklı boyutlarını halatın nesnel yapısı üzerinden ifade etmiştir.' +
                ' Bu sayede sanatı, derin ve anlamlı bir anlatım gücüne sahip bir özgürleşme aracı olarak ortaya çıkmıştır.<br>' +
                'Sanatçı, ulusal ve uluslararası yarışmalarda toplam 24 ödül kazanmış ve Mersin\'de yaşayarak atölyesinde çalışmalarını sürdürmektedir.' +
                ' Ahmet Yeşil, Türk ve dünya sanatına önemli yenilikler getiren özgün bir sanatçıdır.' +
                ' Sanat eserleri, içtenlik ve yalınlıkla tanımlanan bir üslupla dekoratiflikten uzaktır ve yeni bir sanatsal dili temsil eder.<sup>[4]</sup><br>' +
                ' Sanatçının eserleri, sanatın gücünü ve ifade özgürlüğünü vurgulayarak sanatseverlere ilham vermektedir.<br>' +
                'Ahmet Yeşil\'in eserleri, sanatın evrensel dilini kullanarak duygusal derinlik ve düşünsel zenginlik sunar.' +
                ' Halat ve deniz temasını ustalıkla işleyen sanatçı, soyut anlayışı benimseyerek Türk sanatına önemli katkılarda bulunmuştur.' +
                ' Onun eserleri, yalınlık ve içtenlikle dokunmuş, değişim ve yenilikle dolu bir sanat yolculuğunun izlerini taşır.' +
                ' Ahmet Yeşil, Türk ve dünya sanatının önde gelen isimlerinden biri olarak, sanatın sınırlarını genişletmeye ve ifade gücünü zenginleştirmeye devam etmektedir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://www.alem.com.tr/sanatcilar/ahmet-yesil-hakkinda-merak-edilenler-1073893</li>' +
                '   <li>https://www.alem.com.tr/sanatcilar/ahmet-yesil-hakkinda-merak-edilenler-1073893 </li>' +
                '   <li>https://www.galerisoyut.com.tr/artist/ahmet-yesil/</li>' +
                '   <li>https://dspace.trakya.edu.tr/xmlui/handle/trakya/1716</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/AHMET_YESIL/0.d.webp', DESCRIPTION: 'Aşkın Diyalektiği 2 150x110'},
                {SRC: './assets/announcements/AHMET_YESIL/1.d.webp', DESCRIPTION: 'Figür-Yorum-I, 90x120, Oil Painting On Canvas'},
                {SRC: './assets/announcements/AHMET_YESIL/2.d.webp', DESCRIPTION: 'İçsel Dokunmalar Üzerine, 160x120'},
                {SRC: './assets/announcements/AHMET_YESIL/3.d.webp', DESCRIPTION: 'Sağınak, 100x50 cm'},
                {SRC: './assets/announcements/AHMET_YESIL/4.d.webp', DESCRIPTION: 'Son(uç), 100x200'},
                {SRC: './assets/announcements/AHMET_YESIL/5.d.webp', DESCRIPTION: 'Van Gogh ve Ben, 120x150'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/AHMET_YESIL/0.m.webp', DESCRIPTION: 'Aşkın Diyalektiği 2 150x110'},
                {SRC: './assets/announcements/AHMET_YESIL/1.m.webp', DESCRIPTION: 'Figür-Yorum-I, 90x120, Oil Painting On Canvas'},
                {SRC: './assets/announcements/AHMET_YESIL/2.m.webp', DESCRIPTION: 'İçsel Dokunmalar Üzerine, 160x120'},
                {SRC: './assets/announcements/AHMET_YESIL/3.m.webp', DESCRIPTION: 'Sağınak, 100x50 cm'},
                {SRC: './assets/announcements/AHMET_YESIL/4.m.webp', DESCRIPTION: 'Son(uç), 100x200'},
                {SRC: './assets/announcements/AHMET_YESIL/5.m.webp', DESCRIPTION: 'Van Gogh ve Ben, 120x150'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ahmet-yesil/" target="_blank">https://www.galerisoyut.com.tr/artist/ahmet-yesil/</a>',
            ]
        },
        {
            ID: 18,
            TEMPLATE: 0,
            NAME: 'Mehmet Güleryüz',
            NAME_UPPERCASE: 'MEHMET GÜLERYÜZ',
            TOPIC: 'Mehmet GÜleryüz Hakkında',
            TOPIC_UPPERCASE: 'MEHMET GÜLERYÜZ HAKKINDA',
            ARTWORKS: 'Mehmet GÜleryüz Eserleri',
            ARTWORKS_UPPERCASE: 'MEHMET GÜLERYÜZ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/MEHMET_GULERYUZ/Mehmet_Guleryuz.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/MEHMET_GULERYUZ/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/MEHMET_GULERYUZ/-1.m.webp',
            TEXT: 'Mehmet Güleryüz, 1938 yılında İstanbul\'da doğdu. ' +
                'İlk ve orta öğrenimini İstanbul\'da tamamladıktan sonra, 1958 yılında İstanbul Devlet Güzel Sanatlar Akademisi Resim Bölümü\'ne girdi. ' +
                '1966 yılında birincilikle mezun olan Güleryüz, aynı yıl profesyonel oyunculuğa başladı. Sanatçı, 1970’lerde devlet bursuyla gittiği Paris\'te Yüksek Resim ve Litografi eğitimi aldı. Bu dönemde heykel ve tiyatro çalışmalarına da devam etti. ' +
                '1975\'te Türkiye\'ye dönen Güleryüz, resim, heykel ve tiyatro çalışmalarını sürdürdü. 1980\'li yıllardan itibaren, resimlerinde daha çok insan figürleri ve günlük yaşamdan sahneler ele almaya başladı. Günümüzde Türkiye\'nin en önemli çağdaş ressamlarından biri olarak kabul edilen Güleryüz’ün eserleri, Türkiye\'nin yanı sıra dünyanın birçok önemli sanat müzesinde ve koleksiyonunda yer almaktadır. ' +
                'Güleryüz\'ün resimlerinde en sık işlediği temalardan biri insan figürüdür. Eserlerinde, sıradan insanların yaşadığı mutluluk, hüzün, öfke gibi duyguları yansıtır. ' +
                'Güleryüz\'ün resimlerinde, naif bir üslup hakimdir. Eserlerinde, karmaşık kompozisyonlardan ve tekniklerden kaçınarak, basit ve anlaşılır bir dil kullanır. Sanatçı, eleştirel ve dışavurumcu bir bakış açısıyla, insanlık hallerini ve bu hallerin doğurduğu durumları resmeder. Figürleri, belirsiz bir uzamın içerisinde belirginleştirerek, izleyicinin yorumuna bırakır. ' +
                'Figürleri, genellikle günlük yaşamdan sahnelerde yer alır. Örneğin, "Çöp Masalları" adlı eseri, İstanbul\'un sokaklarında yaşayan insanların hikâyelerini anlatmaktadır. ' +
                '"Kahveci" adlı eseri ise İstanbul\'un gündelik yaşamından bir kesit sunmaktadır. ' +
                'Güleryüz\'ün resimleri, hem Türkiye\'de hem de dünyada geniş bir hayran kitlesine sahiptir. ' +
                'Eserleri, birçok önemli sanat müzesinde ve koleksiyonunda yer almaktadır.' +
                ' 1966\'da İstanbul Devlet Güzel Sanatlar Akademisi Resim Bölümü Birincilik Ödülü, 1970\'te Paris\'te düzenlenen uluslararası bir yarışmada ikincilik ödülü,' +
                ' 1989\'te Sedat Simavi Sanat Ödülü ve 2003\'te Devlet Sanatçısı unvanı gibi birçok ödüle layık görülmüştür. ' +
                '2024\'te aramızdan ayrılmıştır.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ</li>' +
                '   <li>https://www.iskultur.com.tr/yazarlar/mehmet-guleryuz</li>' +
                '   <li>https://adasanat.com.tr/portfolio_page/ressam-ve-resim-mehmet-guleryuz-retrospektifi</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/MEHMET_GULERYUZ/0.d.webp', DESCRIPTION: '2 Juin 2020 Paris, 73x60'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/1.d.webp', DESCRIPTION: '9 Mai 2019 Paris - Le Premier Harnais, 27x19'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/2.d.webp', DESCRIPTION: '11 September 2023 Paris, 65x50'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/3.d.webp', DESCRIPTION: '18 Janvier 2020 Paris, 27x22 '},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/4.d.webp', DESCRIPTION: '21 Aout 2020 Paris, 65x54'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/5.d.webp', DESCRIPTION: '30 Novembre 2020 Paris, 92x73'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/6.d.webp', DESCRIPTION: 'Mistake, 162x130'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/MEHMET_GULERYUZ/0.m.webp', DESCRIPTION: '2 Juin 2020 Paris, 73x60'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/1.m.webp', DESCRIPTION: '9 Mai 2019 Paris - Le Premier Harnais, 27x19'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/2.m.webp', DESCRIPTION: '11 September 2023 Paris, 65x50'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/3.m.webp', DESCRIPTION: '18 Janvier 2020 Paris, 27x22 '},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/4.m.webp', DESCRIPTION: '21 Aout 2020 Paris, 65x54'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/5.m.webp', DESCRIPTION: '30 Novembre 2020 Paris, 92x73'},
                {SRC: './assets/announcements/MEHMET_GULERYUZ/6.m.webp', DESCRIPTION: 'Mistake, 162x130'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ" target="_blank">https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ</a>',
            ]
        },
        {
            ID: 19,
            TEMPLATE: 0,
            NAME: 'Selim Turan',
            NAME_UPPERCASE: 'SELİM TURAN',
            TOPIC: 'Selim Turan Hakkında',
            TOPIC_UPPERCASE: 'SELİM TURAN HAKKINDA',
            ARTWORKS: 'Selim Turan Eserleri',
            ARTWORKS_UPPERCASE: 'SELİM TURAN ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/SELIM_TURAN/Selim_Turan.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SELIM_TURAN/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/SELIM_TURAN/-1.m.webp',
            TEXT: '1915 yılında İstanbul Cağaloğlu’nda, Azeri-Kafkas kökenli Prof. Dr. Hüseyinzade Ali Turan ile Çerkez Süvari Zabiti Şemsettin Bey’in kızı Edhiye Hanım’ın oğlu olarak dünyaya gelen Selim Turan, ailesinin kökeninden kaynaklanan zengin kültürel mirasla büyüdü. Babasının etkisi altında Batı ve Doğu kültürlerinin özlerini alarak yorumlama anlayışıyla yetişti. Sanatçı, babasıyla birlikte desen çalışmaları, resim çizimleri ve renk, form, kompozisyon denemeleri yaparak sanatsal yeteneklerini geliştirdi. 1925’te ilkokulun üçüncü sınıfında Galatasaray Lisesi’ne geçti. \n' +
              'Galatasaray Lisesi\'ni tamamladıktan sonra 1935 yılında İstanbul Devlet Güzel Sanatlar Akademisi’ne girdi. 1938 yılında buradan mezun olan sanatçı, diploma konkurunu kazanarak Avrupa\'da çeşitli gözlem ve incelemelerde bulundu. Aynı zamanda hat ve tezhip dersleri aldı. 1947 yılında Fransız hükümetinin bursuyla Paris\'e giden Turan, uzun yıllar bu kentte yaşayarak sanatını geliştirdi. Bu süre zarfında Salon de Mai ve Yeni Gerçeklikler sergilerine katıldı. Ranson Akademisi\'ne, Fontainebleu Sanat Okulu\'na ve Goetz Akademisi\'ne devam etti.\n' +
              'Selim Turan, resim sanatının yanı sıra heykel sanatıyla da ilgilendi. 1950’li yıllarda Türk resminde soyut-lekeci akımının önde gelen temsilcilerinden biri oldu. Özellikle dikey formatta tasarladığı nan-figüratif, soyut dışavurumcu kompozisyonlarıyla tanındı. Renk kullanımıyla eserlerine dinamik ve coşkulu bir etki kazandıran sanatçı, bazen resimlerinde figüratif unsurları soyut bir biçimde işleyerek özgün bir tarz oluşturdu.\n' +
              'Selim Turan\'ın eserlerinde, Avrupa\'daki eğitim ve gözlemlerinden beslenen bir sanat anlayışı görülmektedir. Sanatçının çalışmaları, Türk resim geleneğini modern ve çağdaş bir perspektifle birleştiren özgün bir yaklaşımı yansıtmaktadır. \n' +
              'Fransa\'nın birçok kentinde önemli duvar resimleri, freskler, seramik ve mozaik panoları, açık hava heykelleriyle tanınan Selim Turan, sanat kariyeri boyunca çeşitli ödüllerle onurlandırılmış bir sanatçıdır. Halkevleri Yurtiçi Gezileri’nin 10. Yıl Sergisi\'nde birincilik ödülüne layık görülen Turan, 1991 yılında Plastik Sanatlar Derneği tarafından \'Onur Belgesi\' ile ödüllendirilmiştir. Aynı yıl içerisinde, Türkmen Tahtakuşlar Köyü\'nde bulunan özel Etnografya Galerisi\'nin kuruluşuna önemli katkılarda bulunmuştur.\n' +
              'Sanatçı, 1991 yılında Sorbonne Üniversitesi\'nde doktora jürilerine profesör unvanıyla katılarak uluslararası alanda tanınmış bir isim olmuştur. 1994 yılında Paris\'te vefat eden Turan\'ın cenazesi Türkiye\'ye getirilerek İstanbul\'da defnedilmiştir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
              ' <ol>' +
              '   <li>https://www.sakipsabancimuzesi.org/sanatci/306</li>' +
              '   <li>https://artam.com/makaleler/sanatci/selim-turanin-sanat-felsefesini-yeniden-degerlendirmek</li>' +
              '   <li>https://arhm.ktb.gov.tr/catalogs/detail/2196/selim-turan-in-sanatindan-kesitler</li>' +
              ' </ol>' +
              '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/SELIM_TURAN/0.d.webp', DESCRIPTION: 'İsimsiz, 198x71'},
                {SRC: './assets/announcements/SELIM_TURAN/1.d.webp', DESCRIPTION: 'Kompozisyon, 24x43'},
                {SRC: './assets/announcements/SELIM_TURAN/2.d.webp', DESCRIPTION: 'Natürmort, 80x60'},
                {SRC: './assets/announcements/SELIM_TURAN/3.d.webp', DESCRIPTION: 'İsimsiz, 198x71 '},
                {SRC: './assets/announcements/SELIM_TURAN/4.d.webp', DESCRIPTION: 'İsimsiz, 50x65'},
                {SRC: './assets/announcements/SELIM_TURAN/5.d.webp', DESCRIPTION: 'Kompozisyon, 119x274'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/SELIM_TURAN/0.m.webp', DESCRIPTION: 'İsimsiz, 198x71'},
                {SRC: './assets/announcements/SELIM_TURAN/1.m.webp', DESCRIPTION: 'Kompozisyon, 24x43'},
                {SRC: './assets/announcements/SELIM_TURAN/2.m.webp', DESCRIPTION: 'Natürmort, 80x60'},
                {SRC: './assets/announcements/SELIM_TURAN/3.m.webp', DESCRIPTION: 'İsimsiz, 198x71 '},
                {SRC: './assets/announcements/SELIM_TURAN/4.m.webp', DESCRIPTION: 'İsimsiz, 50x65'},
                {SRC: './assets/announcements/SELIM_TURAN/5.m.webp', DESCRIPTION: 'Kompozisyon, 119x274'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.sakipsabancimuzesi.org/sanatci/306" target="_blank">https://www.sakipsabancimuzesi.org/sanatci/306<a>',
            ]
        },
        {
            ID: 20,
            TEMPLATE: 0,
            NAME: 'Şahin Paksoy',
            NAME_UPPERCASE: 'ŞAHİN PAKSOY',
            TOPIC: 'Şahin Paksoy Hakkında',
            TOPIC_UPPERCASE: 'ŞAHİN PAKSOY HAKKINDA',
            ARTWORKS: 'Şahin Paksoy Eserleri',
            ARTWORKS_UPPERCASE: 'ŞAHİN PAKSOY ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/SAHIN_PAKSOY/Sahin_Paksoy.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SAHIN_PAKSOY/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/SAHIN_PAKSOY/-1.m.webp',
            TEXT: 'Şahin Paksoy, 1952 yılında doğmuş, Türk resim ve seramik sanatının özgün isimlerinden biridir. Sanat eğitimine 1973 yılında İstanbul Devlet Güzel Sanatlar Akademisi\'nin Seramik Bölümünde başlayan Paksoy, 1980 yılında buradan mezun olmuştur. Öğrencilik yıllarında başladığı arkeolojik eser koleksiyonculuğu, ilerleyen zamanlarda sanat tarzını etkileyecek önemli bir unsurdur.\n' +
                'İlk kişisel sergisini 1980 yılında İstanbul\'daki Galata Sanat Galerisinde açan Paksoy, yurt içi ve yurt dışında birçok karma ve solo sergiye katılmış, eserleri geniş bir izleyici kitlesi tarafından takdir edilmiştir. Sanatçı, eserlerini yaratırken Osmanlı minyatür sanatı, Bizans ikonları ve Selçuklu halıları gibi geleneksel Türk sanatlarından esinlenmiştir. Bu esinlenme, onun çağdaş sanat içinde kendine has bir üslup oluşturmasına yardımcı olmuştur.\n' +
                'Şahin Paksoy\'un figüratif resimleri ve küçük boyutlu seramik heykelleri, günlük hayatta karşılaştığı insanları ve geleneksel Anadolu insanının yaşam tarzını konu alır. Yağlı boya ve seramik gibi farklı ortamlarda çalışmalar yapan Paksoy, soyut çalışmalarıyla da tanınmaktadır. Halen Nişantaşı\'ndaki atölyesinde sanat çalışmalarına devam eden Paksoy, aynı zamanda koleksiyonerlik yaparak sanat dünyasına katkılarını sürdürmektedir.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy</li>' +
                '   <li>https://www.uxmuzayede.com/blog/1726/sahin-paksoy</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/SAHIN_PAKSOY/0.d.webp', DESCRIPTION: 'Park Hayatı, 50x70'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/1.d.webp', DESCRIPTION: 'İsimsiz, 10x80'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/2.d.webp', DESCRIPTION: 'Figürlü Kompozisyon, 100x130'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/3.d.webp', DESCRIPTION: 'Eminönü Sosyetesi, 90x100'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/4.d.webp', DESCRIPTION: 'Puf Puf Halime, 140x110'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/5.d.webp', DESCRIPTION: 'Kalabalıklar, 90x110'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/6.d.webp', DESCRIPTION: 'Figürler, 50x70'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/7.d.webp', DESCRIPTION: 'Aynalı Çeşme Jazz Dörtlüsü, 110x130'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/SAHIN_PAKSOY/0.m.webp', DESCRIPTION: 'Park Hayatı, 50x70'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/1.m.webp', DESCRIPTION: 'İsimsiz, 10x80'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/2.m.webp', DESCRIPTION: 'Figürlü Kompozisyon, 100x130'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/3.m.webp', DESCRIPTION: 'Eminönü Sosyetesi, 90x100'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/4.m.webp', DESCRIPTION: 'Puf Puf Halime, 140x110'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/5.m.webp', DESCRIPTION: 'Kalabalıklar, 90x110'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/6.m.webp', DESCRIPTION: 'Figürler, 50x70'},
                {SRC: './assets/announcements/SAHIN_PAKSOY/7.m.webp', DESCRIPTION: 'Aynalı Çeşme Jazz Dörtlüsü, 110x130'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy" target="_blank">https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy<a>',
            ]
        },
        {
            ID: 21,
            TEMPLATE: 0,
            NAME: 'Burhan Doğançay',
            NAME_UPPERCASE: 'BURHAN DOĞANÇAY',
            TOPIC: 'Burhan Doğançay Hakkında',
            TOPIC_UPPERCASE: 'BURHAN DOĞANÇAY HAKKINDA',
            ARTWORKS: 'Burhan Doğançay Eserleri',
            ARTWORKS_UPPERCASE: 'BURHAN DOĞANÇAY ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/BURHAN_DOGANCAY/burhan_dogancay.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/BURHAN_DOGANCAY/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/BURHAN_DOGANCAY/-1.m.webp',
            TEXT: 'Burhan Doğançay, 1929\'da İstanbul\'da dünyaya geldi. Babası, tanınmış ressam ve harita subayı Adil Doğançay ve ressam Arif Kaptan sayesinde küçük yaşlardan itibaren sanatla iç içe büyüdü. Ankara Üniversitesi Hukuk Fakültesi\'nden mezun olduktan sonra, sanatı akademik kariyerine tercih ederek Paris\'te ekonomi doktorası yaparken resim çalışmalarını sürdürdü. Babasıyla birlikte Ankara\'daki Sanat Sevenler Kulübü\'nde sergiler açarak sanat kariyerine başladı.\n' +
              '\n' +
              '1961 yılında Devlet Resim ve Heykel Sergisi\'ne beş eseri kabul edilen Doğançay, 1962\'de New York\'a taşındı. Burada, Guggenheim Müzesi müdürü Thomas Messer tarafından keşfedilerek eserleri müze koleksiyonuna dahil edildi. New York\'un dinamik ve çok kültürlü yapısından esinlenerek \'Duvarlar\' serisine başladı. Bu seri, şehir duvarlarının zaman içinde nasıl değiştiğini ve toplumsal hikayeleri nasıl yansıttığını gösteriyordu. 1975\'te başladığı ve 114 ülkeyi kapsayan \'Dünya Duvarları\' fotoğraf projesi, 1982\'de Paris\'teki Georges Pompidou Merkezi\'nde \'Fısıldayan Duvarlar\' adı altında sergilendi.\n' +
              '\n' +
              '1983\'te Doğançay\'ın tasarımları Fransa\'nın ünlü halı merkezi Aubusson tarafından duvar halısı olarak dokundu. 1986\'da Brooklyn Köprüsü\'nün onarım sürecinde çektiği fotoğraflar, New York\'un 100. yıl kutlamalarında JFK Uluslararası Havaalanı\'nda sergilendi ve daha sonra \'Walls of the World\' adı altında kitap olarak yayınlandı. 2001\'de İstanbul Dolmabahçe Sarayı\'nda gerçekleştirdiği ilk retrospektif sergisi ve 2003\'te Avrupa Parlamentosu\'nda sergilenen \'Hat Sanatına Saygı\' eseri, sanatçının uluslararası alanda da tanınmasını sağladı.\n' +
              '\n' +
              '18 Ocak 2013\'te 84 yaşında hayatını kaybeden Burhan Doğançay, sanat dünyasında derin izler bırakan, Türk ve dünya sanat tarihinde önemli bir yer edinen bir sanatçı olarak anılmaktadır. Eserleri, zamanın ve toplumun değişimini yansıtan, kültürel ve sosyal mesajlar taşıyan önemli bir miras olarak kabul edilir.\n',

            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
                ' <ol>' +
                '   <li>https://www.nytimes.com/2013/01/21/arts/design/burhan-dogancay-artist-inspired-by-urban-walls-dies-at-83.html</li>' +
                '   <li>https://www.artopol.com/sayfa/burhan-dogancay-kimdir</li>' +
                '   <li>https://www.istanbulsanatevi.com/turk-ressamlar/burhan-dogancay-1929-2013/</li>' +
                '   <li>https://www.galerisoyut.com.tr/artist/burhan-dogancay/</li>' +
                ' </ol>' +
                '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/BURHAN_DOGANCAY/0.d.webp', DESCRIPTION: 'Ribbon, 75,8x56,5'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/1.d.webp', DESCRIPTION: 'Shadows on Black, 75x55'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/2.d.webp', DESCRIPTION: 'PIROUETTE, 76x57'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/3.d.webp', DESCRIPTION: 'Billboard, -'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/4.d.webp', DESCRIPTION: 'Kurdela, -'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/BURHAN_DOGANCAY/0.m.webp', DESCRIPTION: 'Ribbon, 75,8x56,5'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/1.m.webp', DESCRIPTION: 'Shadows on Black, 75x55'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/2.m.webp', DESCRIPTION: 'PIROUETTE, 76x57'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/3.m.webp', DESCRIPTION: 'Billboard, -'},
                {SRC: './assets/announcements/BURHAN_DOGANCAY/4.m.webp', DESCRIPTION: 'Kurdela, -'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/burhan-dogancay/" target="_blank">https://www.galerisoyut.com.tr/artist/burhan-dogancay/<a>',
            ]
        },
        {
            ID: 22,
            TEMPLATE: 0,
            NAME: 'Ertuğrul Ateş',
            NAME_UPPERCASE: 'ERTUĞRUL ATEŞ',
            TOPIC: 'Ertuğrul Ateş Hakkında',
            TOPIC_UPPERCASE: 'ERTUĞRUL ATEŞ HAKKINDA',
            ARTWORKS: 'Ertuğrul Ateş Eserleri',
            ARTWORKS_UPPERCASE: 'ERTUĞRUL ATEŞ ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/ERTUGRUL_ATES/ertugrul_ates.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ERTUGRUL_ATES/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/ERTUGRUL_ATES/-1.m.webp',
            TEXT: 'Ertuğrul Ateş, 1954 Adana doğumlu bir ressamdır. Sanat yolculuğuna lisede Duran Karaca ile başlamış ve Gazi Eğitim Enstitüsü Resim-İş Bölümünde eğitimini sürdürerek, 1976\'da mezun olmuştur. Akademik eğitiminin ardından, 1977\'de Londra\'ya taşınarak Kingsway Princeton Yüksekokulu ve Bethnal Green Enstitüsü\'nde sanat eğitimini derinleştirmiştir. 1987\'de Amerika Birleşik Devletleri\'ne yerleşerek New York\'ta kariyerine devam eden Ateş, 2006 yılına kadar Amerika\'da çalışmalarını sürdürmüş ve ardından Türkiye\'ye dönmüştür.\n' +
              'Ateş, resimlerinde gerçeküstücü bir yaklaşımla insanın bilinçaltını ve kadın imgesini işlemektedir. Eskiz yapmadan, doğrudan tuval üzerine çalışarak başladığı resim yapma sürecinde, tesadüfi dokular oluşturarak resimle bir bilgi alışverişi içine girdiğini ifade eder. Metafizik kompozisyonlar zamanla mistik bir atmosfere bürünmüştür. "Kurdeleli Ressam" lakabıyla tanınan Ateş, resimlerinde kurdele öğesine sıklıkla yer vermiş, ancak 1990\'lardan sonra bu öğenin kullanımını azaltmıştır.\n' +
              'Sanat anlayışını, resimlerinin çözüm önermediğini ve ders vermediğini, ancak izleyicinin kulaklarına "anlaşılmaz şeyler fısıldadığını" belirterek açıklar. Ateş\'e göre sanat, yani yaratıcılık, hayatı anlamlı kılar ve ölüm dışındaki bir tanrısallığa yaratıcılıkla ulaşılabilir. Bu düşünce yapısıyla, 21. yüzyılın sanatçılara ait olduğunu ve yaklaşmakta olan bir 2. Rönesans dönemine işaret ettiğini vurgular.\n' +
              'Sanatçının yaşamı ve çalışmaları, İzmir-Çeşme\'de devam etmektedir. Yurt içi ve yurt dışında birçok sanatsal etkinlik gerçekleştiren Ateş, pek çok uluslararası sergi ve etkinlikte eserlerini sergilemiştir. New York, Boston, Los Angeles, Dallas, Chicago, Miami, South Hampton, Barcelona, Kopenhag, Pekin, Shanghai, Taipei, İstanbul, Ankara, İzmir ve Adana\'da düzenlenen sergilerde sanatseverlerle buluşmuştur.',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
              ' <ol>' +
              '   <li>https://arts.ozyegin.edu.tr/tr/artist/ertugrul-ates</li>' +
              '   <li>https://www.galerisoyut.com.tr/artist/ertugrul-ates/</li>' +
              ' </ol>' +
              '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/ERTUGRUL_ATES/0.d.webp', DESCRIPTION: 'Nuh\'un gemisi, 180x250'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/1.d.webp', DESCRIPTION: 'İsimsiz, 160x140'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/2.d.webp', DESCRIPTION: 'Gel, 135x240'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/3.d.webp', DESCRIPTION: 'Güzel Haber II, 140x240'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/4.d.webp', DESCRIPTION: 'Afrodit, 180x160'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/5.d.webp', DESCRIPTION: 'Güzel I, 50x100'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/ERTUGRUL_ATES/0.m.webp', DESCRIPTION: 'Nuh\'un gemisi, 180x250'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/1.m.webp', DESCRIPTION: 'İsimsiz, 160x140'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/2.m.webp', DESCRIPTION: 'Gel, 135x240'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/3.m.webp', DESCRIPTION: 'Güzel Haber II, 140x240'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/4.m.webp', DESCRIPTION: 'Afrodit, 180x160'},
                {SRC: './assets/announcements/ERTUGRUL_ATES/5.m.webp', DESCRIPTION: 'Güzel I, 50x100'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ertugrul-ates/" target="_blank">https://www.galerisoyut.com.tr/artist/ertugrul-ates/<a>',
            ]
        },
        {
            ID: 23,
            TEMPLATE: 0,
            NAME: 'Orhan Peker',
            NAME_UPPERCASE: 'ORHAN PEKER',
            TOPIC: 'Orhan Peker Hakkında',
            TOPIC_UPPERCASE: 'ORHAN PEKER HAKKINDA',
            ARTWORKS: 'Orhan Peker Eserleri',
            ARTWORKS_UPPERCASE: 'ORHAN PEKER ESERLERİ',
            ARTIST_IMAGE_SRC: './assets/announcements/ORHAN_PEKER/orhan_peker.webp',
            IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ORHAN_PEKER/-1.d.webp',
            IMAGE_BACKGROUND_MOBILE: './assets/announcements/ORHAN_PEKER/-1.m.webp',
            TEXT: 'Orhan Peker, 27 Mayıs 1927\'de Trabzon\'da doğmuş ve 28 Mayıs 1978\'de İstanbul\'da vefat etmiştir. İlkokul eğitimini Trabzon\'da tamamladıktan sonra, lise eğitimine İstanbul\'da Avusturya Lisesi\'nde devam etmiştir. 1946-1951 yılları arasında İstanbul Devlet Güzel Sanatlar Akademisi\'nde Bedri Rahmi Eyüboğlu\'nun atölyesinde ve Fransa\'da resim eğitimi almıştır. 1947 yılında, sanatçı arkadaşlarıyla birlikte "Onlar Grubu"nu kurmuştur.\n' +
              'Peker\'in resimleri ilk kez 1951 yılında Devlet Resim ve Heykel Sergisi\'nde sergilenmiştir. 1953 yılında ise ilk kişisel sergisini açmıştır. 1955 yılında İstanbul Şehir Tiyatroları yönetmeni Max Meincke ile birlikte Viyana ve Paris\'i ziyaret etmiştir. 1956\'da Oskar Kokoschka\'nın düzenlediği Yaz Akademisi\'ne katılmış ve aynı dönemde Almanya\'da taşbaskı eserlerini sergilemiştir. \n' +
              '1957\'de Türkiye\'ye dönen Peker, 1959 yılında Ankara\'ya yerleşmiş ve Turizm ve Tanıtma Bakanlığı\'nda çalışmaya başlamıştır. 1965 yılında Devlet Resim ve Heykel Sergisi\'nde birincilik kazanmış ve ardından Madrid\'e giderek şehirdeki sanat ortamını gözlemleme fırsatı bulmuştur. 1966 yılında Türkiye\'de yılın ressamı seçilmiş, TRT\'nin düzenlediği resim yarışmasında  şık Veysel portresiyle başarı ödülü kazanmıştır. Ayrıca, Almanya\'daki Türk çocukları için "Ağaca Takılan Uçurtma", Metin Eloğlu\'nun "Rüzgâr Ekmek" ve Çetin Öner\'in "Gülibik" isimli kitaplarını resimlemiştir.\n' +
              'Peker, Avrupa\'nın çeşitli şehirlerinde sergiler açmış, ölümünden kısa bir süre önce İstanbul\'a yerleşmiştir. Son kişisel sergisini Bedri Rahmi Galerisi\'nde açmış ve güvercin temalı eserlerini sergilemiştir. Peker\'in mezarı Zincirlikuyu Mezarlığı\'ndadır.\n',
            TEXT_LIMIT_DESKTOP: 1145,
            TEXT_LIMIT_MOBILE: 1145,
            REFERENCES: '<div class="references">' +
              ' <ol>' +
              '   <li>https://www.kulturportali.gov.tr/portal/orhan-peker</li>' +
              '   <li>https://www.galeriartist.com/tr/sanatcilar/orhan-peker</li>' +
              '   <li>https://www.soyakmuzayede.com/blog/2594/orhan-peker-1927-1978</li>' +
              ' </ol>' +
              '</div>',
            ARTWORKS_LIST_DESKTOP: [
                {SRC: './assets/announcements/ORHAN_PEKER/0.d.webp', DESCRIPTION: 'Balıkçı Çocuk ve Kediler, 189x266,TÜYB, 1976'},
                {SRC: './assets/announcements/ORHAN_PEKER/1.d.webp', DESCRIPTION: 'Sarımsaklı Plajında Denize Girenler ve Askerler, 41,5x57'},
                {SRC: './assets/announcements/ORHAN_PEKER/2.d.webp', DESCRIPTION: 'Kedili Özden, 150x150'},
                {SRC: './assets/announcements/ORHAN_PEKER/3.d.webp', DESCRIPTION: 'Güvercin, 15x22, TÜYB'},
                {SRC: './assets/announcements/ORHAN_PEKER/4.d.webp', DESCRIPTION: 'Karga, 10.5x15, KÜKT'},
                {SRC: './assets/announcements/ORHAN_PEKER/5.d.webp', DESCRIPTION: 'Kargalar, 36x48, Litografi, 1957'},
                {SRC: './assets/announcements/ORHAN_PEKER/6.d.webp', DESCRIPTION: 'İtfaiyeciler, 72x53, Ağaç Baskı, 1967'},
            ],
            ARTWORKS_LIST_MOBILE: [
                {SRC: './assets/announcements/ORHAN_PEKER/0.m.webp', DESCRIPTION: 'Balıkçı Çocuk ve Kediler, 189x266,TÜYB, 1976'},
                {SRC: './assets/announcements/ORHAN_PEKER/1.m.webp', DESCRIPTION: 'Sarımsaklı Plajında Denize Girenler ve Askerler, 41,5x57'},
                {SRC: './assets/announcements/ORHAN_PEKER/2.m.webp', DESCRIPTION: 'Kedili Özden, 150x150'},
                {SRC: './assets/announcements/ORHAN_PEKER/3.m.webp', DESCRIPTION: 'Güvercin, 15x22, TÜYB'},
                {SRC: './assets/announcements/ORHAN_PEKER/4.m.webp', DESCRIPTION: 'Karga, 10.5x15, KÜKT'},
                {SRC: './assets/announcements/ORHAN_PEKER/5.m.webp', DESCRIPTION: 'Kargalar, 36x48, Litografi, 1957'},
                {SRC: './assets/announcements/ORHAN_PEKER/6.m.webp', DESCRIPTION: 'İtfaiyeciler, 72x53, Ağaç Baskı, 1967'},
            ],
            ARTWORK_REFERENCES: [
                'Tümü: <a class="gray-underline" href="https://www.galeriartist.com/tr/sanatcilar/orhan-peker" target="_blank">https://www.galeriartist.com/tr/sanatcilar/orhan-peker<a>',
            ]
        },

        ...test_artists_config_tr,
    ]
};
