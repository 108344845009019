import {test_artists_config_en} from './test_config_en';

export const artists_config_en = {
  MISC: {
    READ_MORE: 'Read More',
    READ_LESS: 'Show Less',
    REFERENCES: 'References',
  },
  FOOTER: {
    ARTIST_ARCHIVE: 'Artist Archive',
    PREVIOUS_ARTIST: 'Previous Artist',
    NEXT_ARTIST: 'Next Artist',
    SEE_ARTWORKS: 'See Artworks',
    BACK_TO_ARTIST: 'Back to Artist',
  },
  ARTIST_LIST: [
    {
      ID: 0,
      TEMPLATE: 0,
      NAME: 'Yalçın Gökçebağ',
      NAME_UPPERCASE: 'YALÇIN GÖKÇEBAĞ',
      TOPIC: 'About Yalçın Gökçebağ',
      TOPIC_UPPERCASE: 'ABOUT YALÇIN GÖKÇEBAĞ',
      ARTWORKS: 'Yalçın Gökçebağ\'s Artworks',
      ARTWORKS_UPPERCASE: 'YALÇIN GÖKÇEBAĞ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/YALCIN_GOKCEBAG/Yalçin_Gokcebag.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/YALCIN_GOKCEBAG/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/YALCIN_GOKCEBAG/-1.m.webp',
      TEXT: 'Yalçın Gökçebağ  is known as “Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” and “Resmin Yaşar Kemal\'i”.' +
        ' He reflects Anatolia with its own attitude with its wide perspective, rhythmic layout, detail, use of brushes and colors.' +
        ' He is generally known for his landscape compositions that he fictionalized with wide viewpoints such as Hasat and Bodrum series.' +
        ' Yalçın Gökçebağ was born in 1944 in Ortaköy, in Çal District of Denizli.' +
        ' After his primary school education in his village,' +
        ' he passed the Teacher School exam in 1954 and started to study as a painting and music teacher in Isparta-Gönen Primary Teacher School.' +
        ' He took painting lessons from masters such as Malik Aksel and İlhami Demirci for 3 years at the painting seminars given at his school.' +
        ' In 1963, he entered the Gazi Education Institute, Department of Painting. After graduating in 1966, he worked as an art teacher in Akşehir primary school.' +
        ' After teaching for a while, he started to work as a cameraman in TRT in 1971.' +
        ' Starting in 1990, he worked as a lecturer in the Fine Arts Department of the Middle East Technical University (METU) for a while.<sup>[1]</sup>.<br>' +
        ' Although Gökçebağ\'s works are likened to the impressionism movement, the painter states that he cannot establish himself in any art movement.' +
        ' Gökçebağ shows the Anatolian villages where his childhood passed and the art of Anatolian documentaries' +
        ' in which he took part in his 22-year career as a cameraman in TRT as the greatest factors affecting his art.<sup>[2]</sup>.' +
        ' Working with a documentary perspective in his works, Gökçebağ is able to present many folklore elements to the audience with a detailed attitude.' +
        ' Gökçebağ has constructed his compositions with a wide, overhead angle; god\'s eye point of view, which is frequently encountered in his works, is one of the painter\'s characteristic attitudes.<sup>[3]</sup>.' +
        ' The main reason for this attitude in his works is the aerial shots he made during his career as a cameraman<sup>[4]</sup>.' +
        ' Gökçebağ explains the fiction in his paintings by saying “People are small parts of the painting, nature is important in the expression of the painting. People, birds, plants are living elements in the formation of the picture.”<sup>[5]</sup>.' +
        ' Another distinctive feature of the painter is that, as seen in the Hasat and Bodrum series, he has worked on similar compositions many times in his paintings.<br>' +
        ' Gökçebağ reflects the Anatolian life with its unique compositions in the Harvest series, which started in the years of cameraman after the 1980s.' +
        ' The lively colors and rhythmic pattern seen in his paintings add an abstract impression<sup>[6]</sup>.' +
        ' This unusual impression is one of the distinguishing features of Gökçebağ\'s style.' +
        ' The Bodrum series, which is a newer series, is similar to the Hasat series in many ways.' +
        ' In his works, Gökçebağ reflected the towns and beaches of Bodrum in his paintings with his unique style.<br>' +
        ' Gökçebağ, who has more than 50 exhibitions in Turkey and abroad, continues his art works in his workshops in Ankara and Istanbul today.',
      TEXT_LIMIT_DESKTOP: 1163,
      TEXT_LIMIT_MOBILE: 1163,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Fahri Dağı, Nuray Çağlayan, “Yalçın Gökçebağ’ın Eserlerindeki Milli Folklor Öğelerinin İncelenmesi”, Safran Kültür ve Turizm Araştırmaları Dergisi, sayı: 3, s 18-25.</li>' +
        '   <li>Ümmühan Kazanç, Yalçın Gökçebağ ile röportajı, Mayıs 2017, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, https://www.artfulliving.com.tr/sanat/resmin-yasar-kemali-yalcin-gokcebag-ile-dunden-bugune-i-11825</li>' +
        '   <li>Fahri Dağı, Nuray Çağlayan, “Yalçın Gökçebağ’ın Eserlerindeki Milli Folklor Öğelerinin İncelenmesi”, s 4-6.</li>' +
        '   <li>Ressam Yalçın Gökçebağ: Dokusunda Huzur Var, Yönetmen Sevim Karakaya (2014; Ankara, TRT Belgesel), TV</li>' +
        '   <li>Ayşegül Tabak, Yalçın Gökçebağ ile röportajı, Tarih belirtilmemiş, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, http://www.antikalar.com/yalcin-gokcebag</li>' +
        '   <li>Ümmühan Kazanç, Yalçın Gökçebağ ile röportajı, Mayıs 2017, 10.07.2020 tarihinde web sitesinden ulaşılmıştır, https://www.artfulliving.com.tr/sanat/resmin-yasar-kemali-yalcin-gokcebag-ile-dunden-bugune-i-11825</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/0.d.webp', DESCRIPTION: '\'Yörük Çadırı\', 50x60 cm, OPOC, 1992'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/1.d.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, OPOC, 2019'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/2.d.webp', DESCRIPTION: '50x60 cm, OPOC, 2016'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/3.d.webp', DESCRIPTION: '30x30 cm, OPOC'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/4.d.webp', DESCRIPTION: '50x60 cm, OPOC, 2007'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/5.d.webp', DESCRIPTION: '28x24 cm, OPOPC, 2012'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/6.d.webp', DESCRIPTION: '39x20 cm, OPOC, 1992'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/7.d.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, OPOC'}
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/0.m.webp', DESCRIPTION: '\'Yörük Çadırı\', 50x60 cm, OPOC, 1992'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/1.m.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, OPOC, 2019'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/2.m.webp', DESCRIPTION: '50x60 cm, OPOC, 2016'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/3.m.webp', DESCRIPTION: '30x30 cm, OPOC'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/4.m.webp', DESCRIPTION: '50x60 cm, OPOC, 2007'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/5.m.webp', DESCRIPTION: '28x24 cm, OPOPC, 2012'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/6.m.webp', DESCRIPTION: '39x20 cm, OPOC, 1992'},
        {SRC: './assets/announcements/YALCIN_GOKCEBAG/7.m.webp', DESCRIPTION: '\'Çay Bahçesi\', 60x80 cm, OPOC'}
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/yalcin-gokcebag/" target="_blank">https://www.galerisoyut.com.tr/artist/yalcin-gokcebag/</a>'
      ]
    },
    {
      ID: 1,
      TEMPLATE: 0,
      NAME: 'Nejad Melih Devrim',
      NAME_UPPERCASE: 'NEJAD MELİH DEVRİM',
      TOPIC: 'About Nejad Melih Devrim',
      TOPIC_UPPERCASE: 'ABOUT NEJAD MELİH DEVRİM',
      ARTWORKS: 'Nejad Melih Devrim\'s Artworks',
      ARTWORKS_UPPERCASE: 'NEJAD MELİH DEVRİM\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/Nejad_Melih_Devrim.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NEJAD_MELIH_DEVRIM/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/NEJAD_MELIH_DEVRIM/-1.m.webp',
      TEXT: 'Nejad Melih Devrim is among the important painters of his time with his works in the field of contemporary art.' +
        ' Influenced by geographical and cultural elements, the artist followed a path alternating between figurative and abstract,' +
        ' he has an important place in Turkish art, especially with its late period abstract paintings.' +
        ' he is included in the lyrical abstract and partially geometric abstract,' +
        ' which is the European equivalent of the movement known as Abstract Expressionism in America and started with artists from Europe<sup>[1]</sup>.' +
        ' Nejad Melih Devrim was born in 1923 in Istanbul.' +
        ' His interest in art began at an early age, with the guidance of his mother, Fahrelnisa Devrim (Fahrelnisa Zeid),' +
        ' a well-known painter, and his father, Izzet Melih Devrim, who was an award-winning writer.' +
        ' After graduating from Galatasaray High School, he started Istanbul Fine Arts Academy, where he was a student between 1942-1945.' +
        ' He first became a student in the workshops of Bedri Rahmi Eyüboğlu, then Zeki Kocamemi, then Nurullah Berk, and finally worked as an assistant at the Léopold Lévy Workshop.' +
        ' During his student years, he worked on abstract Islamic art, calligraphy and Byzantine art, which would later feed his art.' +
        ' In this process, Nejad Devrim became the youngest member of the Yeniler group with the suggestions of Abidin Dino and Fikret Adil<sup>[2]</sup>.' +
        ' In 1944, while still a student, he opened his first exhibition on Byzantine art in Istanbul Taksim Casino<sup>[3]</sup>.<br>' +
        ' After his graduation, he settled in Paris in 1946, where the destructive effects of the Second World War were still seen.' +
        ' His art, under the influence of the avant-garde artists of the Paris School, turned towards abstract compositions<sup>[4]</sup>.' +
        ' During this period, he took part in many exhibitions and held a workshop in 1948.' +
        ' The effects of Byzantine mosaics on Devrim\'s abstract works started in this period.' +
        ' Emphasizing the importance of this effect in his abstract compositions, it has been seen that Devrim as a Turkish painter has a different place in the abstract art understanding of Paris<sup>[5]</sup>.' +
        ' Mosaics have turned into original interpretations in the Bitki Bahçeleri, an important series of the Devrim\'s Paris period:' +
        ' repeated floral motifs with rigid contours within divided, fragmented areas are characteristic of this series.' +
        ' The 1948 Bitki Bahçesi painting attracted attention as an important work explaining the qualities of this style.<br>' +
        ' In 1950, he opened his personal exhibition at the Galerie Lydia Conti in Paris.' +
        ' Devrim\'s works were among the "Paris School" painters chosen by Leo Castelli to exhibit in New York the same year.' +
        ' In this exhibition, Devrim, who are among the French artists, exhibited his paintings alongside American artists such as Pollock, Kline, Gorky, Rothko, de Kooning, Soulages, Manessier, Dubuffet.<sup>[6]</sup>.' +
        ' These exhibitions were followed by an exhibition opened in Gallery De Beaune in 1951. In 1952 he was the founding member and president of Salon d\'Octobre.' +
        ' He took part in the Galerie Charpentier exhibitions in 1954. He participated in the Ecole de Paris exhibitions between 1954-62.<br>' +
        ' After 10 years in Paris, his travels, which started in 1956 and lasted until 1985, marked the beginning of a new era of his art.' +
        ' America, China, Central Asia, Spain, England, Egypt, the Netherlands, the Soviet Union and Turkey are among the regions he visited.' +
        ' Devrim focused on abstract compositions and urban interpretations during this period. In 1985 he made his travels less' +
        ' frequent and settled in the city of Nowy-Sacz in southern Poland.' +
        ' It continued production there until his death in 1995.',
      TEXT_LIMIT_DESKTOP: 1154,
      TEXT_LIMIT_MOBILE: 1154,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Sefa Sağlam, “Direnen Bir Yenilikçi Nejad Devrim”, Hürriyet Gösteri, Sayı:56, İstanbul, Kasım 1993, s 31.</li>' +
        '   <li>Yahşi Baraz, “Türk Resminde Bir Fenomen: Nejad Melih Devrim”, Artist Dergisi, Sayı: 4/18, İstanbul Mart 2004, s 38</li>' +
        '   <li>Lydia Harambourg, “Nejad Devrim, Renk Uzmanı, Sabırsızlığın Ressamı”, Kısa Yaşam Öyküsü Fahrelnissa ile Nejad, Fajrelnissa is Nejad Gökkuşağında İki Kuşak, İstanbul, 2006, s 53-54.</li>' +
        '   <li>Ferit Edgü, “Nejad Devrim’in Ardından Nejad’ın Yaşamı ve Sanatı”, Milliyet Sanat Dergisi, Sayı: 356, Doğan Yayıncılık, İstanbul Mart 1995, s 32-36.</li>' +
        '   <li>Georges Boudaille, “Nejad Melih Devrim Günümüz Sanatçıları Kolleksiyonu”, Türkiyede Sanat (Plastik Sanatlar Dergisi), Sayı: 11, İstanbul Kasım/Aralık 1993, s 22.</li>' +
        '   <li>Yahşi Baraz, “Türk Resminde Bir Fenomen: Nejad Melih Devrim”, Artist Dergisi, Sayı 4/18, İstanbul Mart 2004, s 37.</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/0.d.webp',
          DESCRIPTION: '\"The Great Wall\" 89x116 cm, OPOC, 1965'
        },
        {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/1.d.webp', DESCRIPTION: '\"Mesiah\" 26x41 cm, OPOC, 1949'},
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/2.d.webp',
          DESCRIPTION: '\"Les Tulipes\" 28x20 cm, OPOW, 1965'
        },
        {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/3.d.webp', DESCRIPTION: '\"Paris\" 130x160 cm, OPOC, 1963'},
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/4.d.webp',
          DESCRIPTION: '\"A Memoire of The City\" 97x130 cm, OPOC, 1962'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/5.d.webp',
          DESCRIPTION: '\"Méditerranée\" 22x27 cm, OPOC, 1959-61'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/6.d.webp',
          DESCRIPTION: '\"White Abstraction\" 130x160 cm, OPOC, 1954'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/7.d.webp',
          DESCRIPTION: '\"Abstrait Vert\" 22x12 cm, OPOC, 1963'
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/0.m.webp',
          DESCRIPTION: '\"The Great Wall\" 89x116 cm, OPOC, 1965'
        },
        {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/1.m.webp', DESCRIPTION: '\"Mesiah\" 26x41 cm, OPOC, 1949'},
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/2.m.webp',
          DESCRIPTION: '\"Les Tulipes\" 28x20 cm, OPOW, 1965'
        },
        {SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/3.m.webp', DESCRIPTION: '\"Paris\" 130x160 cm, OPOC, 1963'},
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/4.m.webp',
          DESCRIPTION: '\"A Memoire of The City\" 97x130 cm, OPOC, 1962'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/5.m.webp',
          DESCRIPTION: '\"Méditerranée\" 22x27 cm, OPOC, 1959-61'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/6.m.webp',
          DESCRIPTION: '\"White Abstraction\" 130x160 cm, OPOC, 1954'
        },
        {
          SRC: './assets/announcements/NEJAD_MELIH_DEVRIM/7.m.webp',
          DESCRIPTION: '\"Abstrait Vert\" 22x12 cm, OPOC, 1963'
        },
      ],
      ARTWORK_REFERENCES: [
        // 'Chartes: <a class="gray-underline" href="https://artam.com/muzayede/301-cagdas-sanat-eserleri/nejad-melih-devrim-1923-1995-soyut-kompozisyon-20" target="_blank">' +
        // 'https://artam.com/muzayede/301-cagdas-sanat-eserleri/nejad-melih-devrim-1923-1995-soyut-kompozisyon-20</a>',
        // 'Zaman Doğarken: <a class="gray-underline" href="https://galerinev.art/tr/nejad-devrim" target="_blank">https://galerinev.art/tr/nejad-devrim</a>',
        // 'Nejadın En Büyük Erdemi Nejad Olmaktır: <a class="gray-underline" href="https://galerinev.art/tr/nejad-devrim" target="_blank">https://galerinev.art/tr/nejad-devrim</a>',
        '<a class="gray-underline" href="http://www.artnet.com/artists/nejad-melih-devrim/" target="_blank">http://www.artnet.com/artists/nejad-melih-devrim/</a>',
      ]
    },
    {
      ID: 2,
      TEMPLATE: 0,
      NAME: 'Ahmet Güneştekin',
      NAME_UPPERCASE: 'AHMET GÜNEŞTEKİN',
      TOPIC: 'About Ahmet Güneştekin',
      TOPIC_UPPERCASE: 'ABOUT AHMET GÜNEŞTEKİN',
      ARTWORKS: 'Ahmet Güneştekin\'s Artworks',
      ARTWORKS_UPPERCASE: 'AHMET GÜNEŞTEKİN\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/AHMET_GUNESTEKIN/Ahmet_Gunestekin.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_GUNESTEKIN/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_GUNESTEKIN/-1.m.webp',
      TEXT: 'Ahmet Güneştekin, considered one of the most important names of 21st century Turkish art, produces works in the fields of painting, conceptual art and sculpture.' +
        ' He is a self-taught artist and uses geographical elements and Anatolian, Greek, Mesopotamian legends in his works<sup>[1]</sup>.' +
        ' Standing out with his works loaded with traditional and contemporary references, Güneştekin abandoned figurative work in the early 2000s and adopted an abstract style focusing on narrative.' +
        ' Güneştekin was born in 1966 in Garzan Workers Camp in Batman.' +
        ' Although he had an interest in art at a young age, he left the Mimar Sinan Fine Arts Academy, which he won,' +
        ' and graduated from Anadolu University, Department of Business Administration.' +
        ' After doing business for a while, he returned to art and opened his first professional art workshop in Istanbul in 1997.<br>' +
        ' The artist opened his first exhibition in 2003 at the Atatürk Cultural Center with the name “Karanlıktan Sonraki Renkler”.' +
        ' Güneştekin\'s international success came with the "Ahmet Güneştekin: Recent Paintings" exhibition in New York with Marlborough Gallery in 2013.' +
        ' In 2018, the "Chamber of Immortality" exhibition within the scope of the 13th Contemporary Istanbul made a big impression.' +
        ' Güneştekin, who has organized tours to many regions of Turkey, emphasizes that this is one of the foundations of his art<sup>[2]</sup>.' +
        ' In his works where geometry is at the forefront, there are references from traditional motifs found in local craft works and mythological elements, especially the sun.' +
        ' The artist, whose works consist of strong colors, defined forms, combined layers and optical illusions, thinks that mythology is the source of all beginnings<sup>[3]</sup>.' +
        ' Avoiding the limitations of the conventional canvas, Güneştekin uses layers that are constructed in various ways, especially in circles.' +
        ' He combines them with various elements such as optical materials, engraved motifs and images, and works with acrylic and oil in one body<sup>[4]</sup>.<br>' +
        ' Güneştekin was interested in documentary as well as art.' +
        ' Güneştekin, who was the artistic director of the documentary "Haberci" directed by Coşkun Aral, prepared the documentary "Güneşin İzinde" in Turkey in 2005, which focuses on art.' +
        ' The artist, who has social concerns, organizes workshops to introduce art to children in many regions of Turkey in addition to documentary productions.<sup>[5]</sup>.',
      TEXT_LIMIT_DESKTOP: 1158,
      TEXT_LIMIT_MOBILE: 1158,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Güler Ercan, Bir-Çok sergisi için Ahmet Güneştekin ile röportajı, Mayıs 2012</li>' +
        '   <li>Susanne Güsten, Sifting Through Anatolia\'s Dark Sins and Bright Cultures. International Herald Tribune, Nov 22, 2012.</li>' +
        '   <li>Susanne Güsten, Sifting Through Anatolia\'s Dark Sins and Bright Cultures. International Herald Tribune, Nov 22, 2012.</li>' +
        '   <li>Ahmet Güneştekin ile Hayat Sanat (2019; İstanbul, TRT 2 Belgesel), TV</li>' +
        '   <li>Güler Ercan, Bir-Çok sergisi için Ahmet Güneştekin ile röportajı, Mayıs 2012</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/0.d.webp',
          DESCRIPTION: '\"Aşk - Corona Günleri\" 150x150 cm, OPOC, 2020'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/1.d.webp',
          DESCRIPTION: '\"Cennetin Yedi Rüyası\" 150x200 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/2.d.webp',
          DESCRIPTION: '\"Maviye Yükseliş\" 100x100 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/3.d.webp',
          DESCRIPTION: '\"Ölümsüzlük Otu\" 150x200 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/4.d.webp',
          DESCRIPTION: '\"Tanrılardan Ateş Çalan Prometheus\" 50x60 cm, OPOC, 2015'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/5.d.webp',
          DESCRIPTION: '\"Cennetin Dervişleri\" 130x130 cm, OPOC, 2020'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/6.d.webp',
          DESCRIPTION: '\"Godiva’nın Merhameti\" 180x220 cm, OPOC, 2020'
        },
        {SRC: './assets/announcements/AHMET_GUNESTEKIN/7.d.webp', DESCRIPTION: '\"Sesli Saat\" 100x85 cm, OPOC, 2018'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/0.m.webp',
          DESCRIPTION: '\"Aşk - Corona Günleri\" 150x150 cm, OPOC, 2020'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/1.m.webp',
          DESCRIPTION: '\"Cennetin Yedi Rüyası\" 150x200 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/2.m.webp',
          DESCRIPTION: '\"Maviye Yükseliş\" 100x100 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/3.m.webp',
          DESCRIPTION: '\"Ölümsüzlük Otu\" 150x200 cm, OPOC, 2018'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/4.m.webp',
          DESCRIPTION: '\"Tanrılardan Ateş Çalan Prometheus\" 50x60 cm, OPOC, 2015'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/5.m.webp',
          DESCRIPTION: '\"Cennetin Dervişleri\" 130x130 cm, OPOC, 2020'
        },
        {
          SRC: './assets/announcements/AHMET_GUNESTEKIN/6.m.webp',
          DESCRIPTION: '\"Godiva’nın Merhameti\" 180x220 cm, OPOC, 2020'
        },
        {SRC: './assets/announcements/AHMET_GUNESTEKIN/7.m.webp', DESCRIPTION: '\"Sesli Saat\" 100x85 cm, OPOC, 2018'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ahmet-gunestekin/" target="_blank">https://www.galerisoyut.com.tr/artist/ahmet-gunestekin/</a>',
        '<a class="gray-underline" href="https://ahmetgunestekin.com/dt_portfolios/duz-tuvaller/" target="_blank">https://ahmetgunestekin.com/dt_portfolios/duz-tuvaller/</a>',
      ]
    },
    {
      ID: 3,
      TEMPLATE: 0,
      NAME: 'Adnan Turani',
      NAME_UPPERCASE: 'ADNAN TURANİ',
      TOPIC: 'About Adnan Turani',
      TOPIC_UPPERCASE: 'ABOUT ADNAN TURANİ',
      ARTWORKS: 'Adnan Turani\'s Artworks',
      ARTWORKS_UPPERCASE: 'ADNAN TURANİ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/ADNAN_TURANI/Adnan_Turani.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ADNAN_TURANI/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/ADNAN_TURANI/-1.m.webp',
      TEXT: 'Adnan Turani, one of the important names of Turkish art and art academy, is one of the most important representatives of abstract-focused painting in Turkey.' +
        'Turani, who showed an abstract understanding in his early works, used figures and nature in his later works,' +
        ' but his approach to his paintings continued to be based on an abstract fiction and the expression of form.<sup>[1]</sup>.<br>' +
        ' Turani was born in 1925 in Beşiktaş, Istanbul.' +
        ' He graduated from high school in Istanbul Teacher Training School and won the Department of Painting in Ankara Gazi Education Institute in 1945.' +
        ' After graduating in 1948, he worked as a painting teacher in various educational institutions and' +
        ' continued his education in Germany\'s Munich Academy of Fine Arts and Hamburg Academy of Fine Arts in 1953 as part of a state scholarship.' +
        ' He returned to Gazi Education Institute in 1959 and became a workshop instructor and continued this duty until 1970.' +
        ' In the same year, he started to work as a lecturer at Hacettepe University, Faculty of Letters, Department of Art History and became the coordinator of the Institute of Fine Arts.' +
        ' In 1983, he took part in the board of Hacettepe University Faculty of Fine Arts and became the head of the Painting Department.' +
        ' He transferred to Bilkent University in 1986 and worked there until his retirement in 1993.<sup>[2]</sup>.' +
        ' Turani, who received the title of doctor at Hacettepe University with his thesis on "Modern Resim Sanatını Yaratan Faktörler"' +
        ' have published important resources for the Turkish art academy such as “Dünya Sanat Tarihi”, “Türk Resim Sanatı”, “Sanat Terimleri Sözlüğü”, “Çağdaş Sanat Felsefesi”' +
        ' Adnan Turani, who has held more than thirty personal exhibitions in Turkey and abroad, participated in many group exhibitions and published more than forty books and articles,' +
        ' was awarded the Artist of the Year award in 1992, the Sedat Simavi Foundation Award in 1993, and the Anadolu University "Honorary Doctorate" award in 1998.' +
        ' In 2001, he was awarded the Çağdaş Sanatçılar Vakfı Honor Award.<br>' +
        ' The artist, who turned to a non-figurative understanding during his education in Europe, had an effect on the workshops he worked on.' +
        ' His abstract period that lasted until the 1970s is important in terms of shaping his later research.' +
        ' After the 1970s, he included figures and depictions of nature in his works, but this has preserved the abstract form perception mentioned.' +
        ' Turani explains the creation process in words "I am going to new configurations around me. So my painting is to reconstruct the nature effect in an abstract language.' +
        ' Thus, I am not talking about the form of nature, but the form of the nature effect. This drives me into new form-motif synthesis.' +
        ' This process is form of effect research."<sup>[3]</sup>. The understanding of painting is based on the search for an form of effect with abstract fiction.' +
        ' In this respect, not the nature and the form of figures, but the pictorial fiction of these forms came to the fore.' +
        ' After his retirement in 1993, Turani continued his work in his workshop in Ankara, where he lived.' +
        ' Adnan Turani passed away in 2016.',
      TEXT_LIMIT_DESKTOP: 1164,
      TEXT_LIMIT_MOBILE: 1164,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Kurt, Efe Korkut. Çağdaş Türk Sanatında Soyut Resim, İstanbul: İTÜ, 2008.</li>' +
        '   <li>Turani, Adnan. Biyografi, Adnan Turani Resmi Web Sitesi, adnanturani.com.</li>' +
        '   <li>Kurt, Efe Korkut. Çağdaş Türk Sanatında Soyut Resim, İstanbul: İTÜ, 2008.</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/ADNAN_TURANI/0.d.webp', DESCRIPTION: '\"Trio\" 50x70 cm, OPOP, 2011'},
        {SRC: './assets/announcements/ADNAN_TURANI/1.d.webp', DESCRIPTION: '\"Aşıklar\" 90x90 cm, OPOC, 2003'},
        {SRC: './assets/announcements/ADNAN_TURANI/2.d.webp', DESCRIPTION: '\"Aşıklar-\" 35x35 cm, OPOC, 1994'},
        {SRC: './assets/announcements/ADNAN_TURANI/3.d.webp', DESCRIPTION: '\"Kemancı Kız\" 70x70 cm, OPOC, 2003'},
        {SRC: './assets/announcements/ADNAN_TURANI/4.d.webp', DESCRIPTION: '\"Müzisyenler\" 90x100 cm, OPOC, 2015'},
        {SRC: './assets/announcements/ADNAN_TURANI/5.d.webp', DESCRIPTION: '\"Kemancı\" 40x40 cm, OPOC, 2011'},
        {SRC: './assets/announcements/ADNAN_TURANI/6.d.webp', DESCRIPTION: '\"Kemancı Kız\" 80x80 cm, OPOC, 2006'},
        {SRC: './assets/announcements/ADNAN_TURANI/7.d.webp', DESCRIPTION: '\"Portre\" 50x50 cm, OPOC, 1991'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/ADNAN_TURANI/0.m.webp', DESCRIPTION: '\"Trio\" 50x70 cm, OPOP, 2011'},
        {SRC: './assets/announcements/ADNAN_TURANI/1.m.webp', DESCRIPTION: '\"Aşıklar\" 90x90 cm, OPOC, 2003'},
        {SRC: './assets/announcements/ADNAN_TURANI/2.m.webp', DESCRIPTION: '\"Aşıklar-\" 35x35 cm, OPOC, 1994'},
        {SRC: './assets/announcements/ADNAN_TURANI/3.m.webp', DESCRIPTION: '\"Kemancı Kız\" 70x70 cm, OPOC, 2003'},
        {SRC: './assets/announcements/ADNAN_TURANI/4.m.webp', DESCRIPTION: '\"Müzisyenler\" 90x100 cm, OPOC, 2015'},
        {SRC: './assets/announcements/ADNAN_TURANI/5.m.webp', DESCRIPTION: '\"Kemancı\" 40x40 cm, OPOC, 2011'},
        {SRC: './assets/announcements/ADNAN_TURANI/6.m.webp', DESCRIPTION: '\"Kemancı Kız\" 80x80 cm, OPOC, 2006'},
        {SRC: './assets/announcements/ADNAN_TURANI/7.m.webp', DESCRIPTION: '\"Portre\" 50x50 cm, OPOC, 1991'},
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/adnan-turani/" target="_blank">https://www.galerisoyut.com.tr/artist/adnan-turani/</a>',
      ]
    },
    {
      ID: 4,
      TEMPLATE: 0,
      NAME: 'Devrim Erbil',
      NAME_UPPERCASE: 'DEVRİM ERBİL',
      TOPIC: 'About Devrim Erbil',
      TOPIC_UPPERCASE: 'ABOUT DEVRİM ERBİL',
      ARTWORKS: 'Devrim Erbil\'s Artworks',
      ARTWORKS_UPPERCASE: 'DEVRİM ERBİL\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/DEVRIM_ERBIL/Devrim_Erbil.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/DEVRIM_ERBIL/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/DEVRIM_ERBIL/-1.m.webp',
      TEXT: 'Devrim Erbil is a painter and academic who has participated in many national and international exhibitions and has many awards.' +
        ' He has produced works with many mediums such as oil painting, printing, stained glass and mosaic.' +
        ' Devrim Erbil\'s art can be evaluated within the local trends that want to place contemporary art on a foundation connected with tradition.<sup>[1]</sup>.' +
        ' Devrim Erbil was born in Uşak in 1937. Erbil, who completed his primary and secondary education in Balıkesir,' +
        ' opened his first exhibition in Balıkesir American Cultural Center in 1954 while he was still in high school.' +
        ' In 1955, he entered the State Academy of Fine Arts, whose current name is Mimar Sinan Fine Arts University.' +
        ' Erbil, who graduated in 1959 as a student of Halil Dikmen and Bedri Rahmi Eyüpoğlu, took part in groups such as Soyut 7ler and Mavi Grup during the new graduate period.' +
        ' Starting from the 1960s, Erbil started to show a linear approach that would transform into its unique style.' +
        ' During this period, he worked with many different mediums, especially oil painting, in a linear and rhythmic style' +
        ' that incorporates local cultural elements in his works.<sup>[2]</sup>.' +
        ' Throughout his artistic life, Erbil has worked around certain themes: Anatolian towns, passion and interpretation of nature, and themes such as Istanbul, he worked with rhythmic arrangements.' +
        ' Devrim Erbil handled the geometric compositions in Ottoman ornaments and the style in miniature art in an expressive and spatial expression in his works.<sup>[3]</sup>.<br>' +
        ' Erbil, who participated in the 31st Venice Biennale in 1962 and the 3rd Paris Young Artists Biennale the following year, was awarded the Palace Royal 1st prize in the 5th Tehran Biennale in 1966.' +
        ' The artist, whose success continued in the following years, received awards and opened exhibitions in organizations such as Türkiye Çağdaş Ressamlar Cemiyeti, Alexandria Biennial, and DYO Exhibition.<br>' +
        ' Continuing his academic career in addition to his art, Erbil became associate professor in 1970 and professor in 1981.' +
        ' He was the head of the Painting Department of Mimar Sinan Fine Arts Faculty between 1985-1987, and the head of the Department of the Faculty of Fine Arts at Yıldız University between 1988-1990;' +
        ' In 1990, he returned to Mimar Sinan University as the Deputy Dean of the Faculty of Fine Arts.' +
        ' Erbil, who received the title of State Artist in 1991, continued his academic studies at Mimar Sinan Fine Arts University until his retirement in 2004.' +
        ' Devrim Erbil, who has opened numerous national and international exhibitions, continues his artistic works in Istanbul.',
      TEXT_LIMIT_DESKTOP: 1144,
      TEXT_LIMIT_MOBILE: 1144,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Pehlivanoğlu, Burcu, Devrim Erbil (2012), Olcayart, 2012 basım, 1. Edisyon</li>' +
        '   <li>Devrim Erbil Hayatı ve Eserleri”, Istanbulsanatevi.com, Kasım 11, 2020</li>' +
        '   <li>Sinan Eren Erk, “Sanat Peşinde Yarım Asır: Devrim Erbil”, Devrim Erbil ile “Akademide 50 Yıl” sergisi için röportajı</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/DEVRIM_ERBIL/0.d.webp', DESCRIPTION: 'Untitled, 90x140 cm, OPOC, 2020'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/1.d.webp', DESCRIPTION: 'Untitled, 100x100 cm, OPOC"'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/2.d.webp', DESCRIPTION: 'Untitled, 100x120 cm, OPOC, 2019'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/3.d.webp', DESCRIPTION: 'Untitled, 100x120 cm, OPOC, 2020'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/4.d.webp', DESCRIPTION: 'Untitled, 140,5x89,5 cm, OPOC, 2005'},
        {
          SRC: './assets/announcements/DEVRIM_ERBIL/5.d.webp',
          DESCRIPTION: '"Ayasofya; İkili Bakış", 150x8150 cm, OPOC, 2016'
        },
        {SRC: './assets/announcements/DEVRIM_ERBIL/6.d.webp', DESCRIPTION: '"Derin Mavi", 150x100 cm, OPOC, 1998'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/7.d.webp', DESCRIPTION: '"Yeşil İstanbul", 125x125 cm, OPOC, 2006'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/DEVRIM_ERBIL/0.m.webp', DESCRIPTION: 'Untitled, 90x140 cm, OPOC, 2020'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/1.m.webp', DESCRIPTION: 'Untitled, 100x100 cm, OPOC"'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/2.m.webp', DESCRIPTION: 'Untitled, 100x120 cm, OPOC, 2019'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/3.m.webp', DESCRIPTION: 'Untitled, 100x120 cm, OPOC, 2020'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/4.m.webp', DESCRIPTION: 'Untitled, 140,5x89,5 cm, OPOC, 2005'},
        {
          SRC: './assets/announcements/DEVRIM_ERBIL/5.m.webp',
          DESCRIPTION: '"Ayasofya; İkili Bakış", 150x8150 cm, OPOC, 2016'
        },
        {SRC: './assets/announcements/DEVRIM_ERBIL/6.m.webp', DESCRIPTION: '"Derin Mavi", 150x100 cm, OPOC, 1998'},
        {SRC: './assets/announcements/DEVRIM_ERBIL/7.m.webp', DESCRIPTION: '"Yeşil İstanbul", 125x125 cm, OPOC, 2006'},
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/devrim-erbil/" target="_blank">https://www.galerisoyut.com.tr/artist/devrim-erbil/</a>',
      ]
    },
    {
      ID: 5,
      TEMPLATE: 0,
      NAME: 'Nuri Abaç',
      NAME_UPPERCASE: 'NURİ ABAÇ',
      TOPIC: 'About Nuri Abaç',
      TOPIC_UPPERCASE: 'ABOUT NURİ ABAÇ',
      ARTWORKS: 'Nuri Abaç\'s Artworks',
      ARTWORKS_UPPERCASE: 'NURİ ABAÇ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/NURI_ABAC/Nuri_Abaç.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NURI_ABAC/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/NURI_ABAC/-1.m.webp',
      TEXT: 'Nuri Abaç, who relates his paintings with traditions, daily life and Anatolian legends, and presents them with a unique decorative surrealism,' +
        ' He became one of the important painters of 20th century Turkish art.' +
        ' The prominent feature of his style is that he adopts an approach with a Karagöz-Hacivat aesthetic and often includes musical elements.<sup>[1]</sup>.<br>' +
        ' Nuri Abaç was born in Istanbul in 1926. He is the second son of a theater artist Celal Abaç and teacher Sahire Abaç.' +
        ' Abaç entered the Painting Department of the State Academy of Fine Arts in 1944, and started his education at the Leopold Levy Workshop.' +
        ' After a year, he transferred to the Department of Architecture, and graduated in 1950.<br>' +
        ' Abaç started to concentrate on his art works, which he continued with his architectural education, in the 1960s.' +
        ' It is especially inspired by Anatolian civilizations.' +
        ' He examined the interpretative applications and intellectual problems of historical works such as the Hittite reliefs and reached his own pictorial expression language.' +
        ' Accordingly, he worked on expressionist approaches aiming at expressive fantasy interpretations.<sup>[2]</sup>.<br>' +
        ' Abaç turned to traditional sources after 1970 and started to process traditional folk arts.<sup>[3]</sup>' +
        ' During this period, he used a motifistic language in painting.' +
        ' In this period when folk dances, Ortaoyunu and Karagöz studies were concentrated, Abaç gave priority to these subjects.<sup>[4]</sup>' +
        ' The paintings of the artist, who is also influenced by the miniature art and compositions, gain importance with the motifs of folk dances,' +
        ' shawm-and-drum, Ortaoyunu(traditional improvised theatre) scenes and shadow puppetry.<br>' +
        ' According to Abaç, the psychological and social origin of the world in which he lives in is correct and undertaking some functions.' +
        ' In this direction, he interpreted his own works as out of the hands of the artist and being the property of the society he will communicate with<sup>[8]</sup><br>' +
        ' In addition to his art, Abaç started his life as a freelancer in 1950 and worked for the State Hydraulic Works for three years in the State Planning Organization since 1970 and retired in 1985.<br>' +
        ' He was working as a lecturer in the "Perspective" course at Hacettepe University and Bilkent University Faculty of Fine Arts for a while since 1991' +
        ' and opened many exhibitions in Turkey and abroad. He won the 42nd and 47th State Painting and Sculpture Exhibition awards,' +
        ' the 3rd prize of the Alexandria Biennial, and the Ankara Art Fair Çağsav Honor Award in 2002.<br>' +
        ' Nuri Abaç continued her work in Ankara, where she lived for the rest of her life. He passed away in 2008.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
        '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106.</li>' +
        '   <li>Gönül Gültekin, Batı Anlayışında Türk Resim Sanatı, Ankara: T.C. Ziraat Bankası. (1992).</li>' +
        '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106</li>' +
        '   <li>Şefik Kahramankaptan, “Resmi Geçit Ressam Söyleşileri”, Kültür Bakanlığı Yayınları, Ankara 2001, s.2, 3.</li>' +
        '   <li>Ahmet Dalkıran, Türk Kültüründe Davul ve Resim Sanatına Yansıması, Uluslararası Tarih ve Sosyal Araştırmalar Dergisi, sayı 21, (2019), 1-24.</li>' +
        '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
        '   <li>Alaybey Karoğlu, Folklorik Unsurların Resme Yansıması ve Yaşatılması Bağlamında Bazı Düşünceler, 106</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/NURI_ABAC/0.d.webp', DESCRIPTION: '\"Ayakkabı Boyacısı\" 42x31 cm, OPOC, 1982'},
        {SRC: './assets/announcements/NURI_ABAC/1.d.webp', DESCRIPTION: 'Untitled 35x40 cm, OPOC, 1999'},
        {SRC: './assets/announcements/NURI_ABAC/2.d.webp', DESCRIPTION: 'Untitled 50x70 cm, OPOC, 1990'},
        {SRC: './assets/announcements/NURI_ABAC/3.d.webp', DESCRIPTION: '\"Nuri Abaç -\" 50x50 cm, OPOC, 1994'},
        {SRC: './assets/announcements/NURI_ABAC/4.d.webp', DESCRIPTION: '\"Çeşme Başı\" 40x33 cm, OPOC, 1996'},
        {
          SRC: './assets/announcements/NURI_ABAC/5.d.webp',
          DESCRIPTION: '\"Island Ship and Fisherman\" 60x110 cm, OPOC, 1982'
        },
        {
          SRC: './assets/announcements/NURI_ABAC/6.d.webp',
          DESCRIPTION: '\"Ship with Horse Head\" 40x50 cm, OPOC, 2002'
        },
        {SRC: './assets/announcements/NURI_ABAC/7.d.webp', DESCRIPTION: '\"Locomotive\" 39x39 cm, OPOC, 2004'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/NURI_ABAC/0.m.webp', DESCRIPTION: '\"Ayakkabı Boyacısı\" 42x31 cm, OPOC, 1982'},
        {SRC: './assets/announcements/NURI_ABAC/1.m.webp', DESCRIPTION: 'Untitled 35x40 cm, OPOC, 1999'},
        {SRC: './assets/announcements/NURI_ABAC/2.m.webp', DESCRIPTION: 'Untitled 50x70 cm, OPOC, 1990'},
        {SRC: './assets/announcements/NURI_ABAC/3.m.webp', DESCRIPTION: '\"Nuri Abaç -\" 50x50 cm, OPOC, 1994'},
        {SRC: './assets/announcements/NURI_ABAC/4.m.webp', DESCRIPTION: '\"Çeşme Başı\" 40x33 cm, OPOC, 1996'},
        {
          SRC: './assets/announcements/NURI_ABAC/5.m.webp',
          DESCRIPTION: '\"Island Ship and Fisherman\" 60x110 cm, OPOC, 1982'
        },
        {
          SRC: './assets/announcements/NURI_ABAC/6.m.webp',
          DESCRIPTION: '\"Ship with Horse Head\" 40x50 cm, OPOC, 2002'
        },
        {SRC: './assets/announcements/NURI_ABAC/7.m.webp', DESCRIPTION: '\"Locomotive\" 39x39 cm, OPOC, 2004'},
      ],
      ARTWORK_REFERENCES: [
        '"Ayakkabı Boyacısı" 42x31 cm, OPOC, 1982:' +
        ' <a class="gray-underline" href="http://www.artnet.com/artists/nuri-abac/ayakkab%C4%B1-boyac%C4%B1s%C4%B1-EcOjpI_a__g8hVCScVQrQw2" target="_blank">' +
        'http://www.artnet.com/artists/nuri-abac/ayakkab%C4%B1-boyac%C4%B1s%C4%B1-EcOjpI_a__g8hVCScVQrQw2</a>',
        '35x40 cm, OPOC, 1999: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/nuri-abac/" target="_blank">https://www.galerisoyut.com.tr/artist/nuri-abac/</a>',
        '50x70 cm, OPOC, 1990: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/nuri-abac/" target="_blank">https://www.galerisoyut.com.tr/artist/nuri-abac/</a>',
        'Nuri Abaç - 50x50 cm, OPOC, 1994: <a class="gray-underline" href="https://www.devmuzayede.com/urun/3311498/nuri-abac-1926-2008-tuval-uzerine-yagli-boya-1994-tarihli-imzali" target="_blank">' +
        'https://www.devmuzayede.com/urun/3311498/nuri-abac-1926-2008-tuval-uzerine-yagli-boya-1994-tarihli-imzali</a>',
        '"Çeşme Başı" 40x33 cm, OPOC, 1996: <a class="gray-underline" href="https://www.sancakmuzayede.com/urun/3446493/nuri-abac-cesme-basi-imzali-1996-tarihli-tuval-uzeri-yagli-boya-40x33-cm" target="_blank">' +
        'https://www.sancakmuzayede.com/urun/3446493/nuri-abac-cesme-basi-imzali-1996-tarihli-tuval-uzeri-yagli-boya-40x33-cm</a>',
      ]
    },
    {
      ID: 6,
      TEMPLATE: 1,
      NAME: 'Sauna Emerging 1 Bundle',
      NAME_UPPERCASE: 'SAUNA EMERGING 1 BUNDLE',
      TOPIC: 'About Sauna Emerging 1 Bundle',
      TOPIC_UPPERCASE: 'ABOUT SAUNA EMERGING 1 BUNDLE',
      ARTIST_LIST_HEADING: 'Sauna Emerging 1 Artists',
      ARTIST_LIST_HEADING_UPPERCASE: 'SAUNA EMERGING 1 ARTISTS',
      ARTWORKS: 'Sauna Emerging 1 Artworks',
      ARTIST_IMAGE_SRC: './assets/announcements/SAUNA_EMERGING_1/Art_Ist_Sauna.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SAUNA_EMERGING_1/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/SAUNA_EMERGING_1/-1.m.webp',
      TEXT: 'The “Sauna Emerging 1” bundle, created within the framework of the collaboration of Artiox and Art.Ist Sauna, consists of the works of 11 young and independent artists.' +
        ' While investing in the artworks produced by promising and carefully selected artists, you will also support them with this bundle.' +
        ' As a result of a long and detailed study, the selection committee formed by Art.Ist Sauna determined the names to be included in the bundle' +
        ' and focused on selecting the artists based on their past performances and producing works consistently.' +
        ' The works in the bundle will be constantly exhibited in the exhibition area of UniqExpo and art lovers will have the opportunity to spend time with the artists at various events.' +
        ' Thus, while keeping in contact with the artist and art lovers more, we will also have the opportunity to regularly monitor the progress of these valuable artists.',
      NESTED_ARTIST_LIST: [
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Erdinc_Sakin.webp',
          HEADING: 'Erdinç Sakin',
          TEXT: '“In the Anadolu’dan Fütürizme series, in which I embroidered the motifs of Anatolia, sometimes futuristic ideas dominate,' +
            ' and sometimes I create my paintings by blending old traditions with modern people.”<br>' +
            'The artist, who was born in Ankara in 1982, started to study painting in 1997.' +
            ' Between 2011 and 2012, after the leather model design, he made sculptures with specially designed products for Bursa Science and Technology Center.' +
            ' Having received an artist certificate from the Ministry of Culture and Tourism in 2014, Sakin went to Italy in 2015 and conducted research in the field of painting and sculpture.' +
            ' He worked with Italian curator Adelinda Allegretti for his abroad exhibitions.' +
            ' Many of Erdinç Sakin’s works are in the collections of art lovers at home and abroad.' +
            ' The artist, who continues his paintings in his own private workshop; he has participated in 29 composite exhibitions, 6 of which are solo exhibitions, and many national and international exhibitions so far.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Erhan_Karagoz.webp',
          HEADING: 'Erhan Karagöz',
          TEXT: '“By painting the female figure, which is at the center of all my works, with different emotions, expressions and mimics from life,' +
            ' I seek to create atmospheres that are beyond reality and that draw the audience in.' +
            ' I can describe my works as unbalanced in details, high in aesthetic concern and balanced in the whole.”<br>' +
            'The artist, who was born in Istanbul in 1980, completed his high school education at Kabataş High School between 1994-1998,' +
            ' and after graduating from the Department of Chemistry at Kocaeli University in 2003, he turned to art with the workshops he voluntarily participated in.' +
            ' After his education at the University of Wales Robert Kennedy College, Karagöz held solo exhibitions and took part in many national and international group exhibitions.' +
            ' Portrait, figurative and abstract; the artist, who produces works in different styles and techniques,' +
            ' sees painting as an inner journey and includes concepts such as sadness, enthusiasm, fear, unhappiness and pain in his paintings.' +
            ' The artist, who states that the reason why he uses different techniques and mediums in his paintings is that the different techniques,' +
            ' mediums and colors he uses while expressing different feelings of different people help him to complete the expressions, continues his works in his workshop in Istanbul.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Kuntay_Tarik_Evren.webp',
          HEADING: 'Küntay Tarık Evren',
          TEXT: '“In my world where I wake up with changes every day, I blend my lines and my own inner conflicts with the chaos of the modern age.' +
            ' For me, my paintings are an expression; in this expression, like ying yang, everyone can see a good or bad part of themselves.”<br>' +
            'The artist, who was born in Bursa in 1989, started painting in his childhood.' +
            ' Continuing his artistic inclination as an amateur, Evren started his art education at the Visual Communication and Design Department of Beykent University in 2007, and continued his education at Milan Fine Arts Academy in 2009.' +
            ' The artist, who studied graphic design and visual arts in Milan, graduated in 2013.' +
            ' Between 2015-2019, he worked with many brands in the advertising industry in Turkey.' +
            ' After leaving the advertising industry in 2019, he started his professional art life.' +
            ' The artist, who does not want to squeeze the planlessness and intertwined worlds of his paintings into an exhibition address or between four walls,' +
            ' created his own street exhibition by hanging his paintings on the walls of important squares such as Kadıköy throughout 2020 by creating his own movement.' +
            ' He participated in the exhibition called Mikrotopya, which was held in a damaged and about to be demolished building,' +
            ' with his work “Teddy Bear”, which he created inspired by the Izmir earthquake and tells about collapsed lives.' +
            ' His works in the “Le Capitalisme” series were exhibited at Contemporary Barcelona in 2021. The artist, who is largely inspired by dadaism in his works, calls the trend in his paintings as doddlism.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Mine_Akcaoglu.webp',
          HEADING: 'Mine Akçaoğlu',
          TEXT: '“The artist has built her works on the concept of time, which can be determined as before and after.' +
            ' She uses photographic realities while creating her works.' +
            ' While she carries the reality she obtained from her own lens or someone else’s lens to the painting surface, she makes the canvas the space of the new reality.' +
            ' She refers to the concept of time with this movement and change that takes place while transforming the photographic object into an image.”<br>' +
            ' Born in Kastamonu in 1982, the artist graduated from Sakarya University Fine Arts Faculty Painting Department in 2007.' +
            ' Successfully publishing her thesis titled "Fotografik Gerçeklikten Resimsel Yeniden sunma Geçiş Sürecinde 1960 Sonrası Resim Sanatında Uzam Espas İlişkisi",' +
            ' completed the Painting Department of Sakarya University Social Sciences Institute in 2010.' +
            ' She uses photographic realities while creating her works, she built her works on the concept of time, which can determined as before and after.' +
            ' While she carries the reality she obtained from her own lens or someone else’s lens to the painting surface, she turns the canvas into the space of the new reality.' +
            ' She refers to the concept of time with this movement and change that occurs while transforming the photographic object into an image.' +
            ' Participating in various painting competitions, group exhibitions and fairs, the artist continues to produce her original works in Istanbul.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Mr_Hure.webp',
          HEADING: 'Mr. Hure',
          TEXT: '“I reflect the period I live in my works by constructing the elements in graffiti and the effects of people on the streets,' +
            ' with pastel, vivid and phosphorescent colors that do not harmonize with each other.”<br>' +
            'The artist, who was born in Siirt in 1987, realized his interest in writing in 1999, when he was just 13 years old.' +
            ' After graduating from Karadeniz Technical University, Department of Business Administration in 2006, he studied at Eskişehir Anadolu University between 2009-2011.' +
            ' The artist, who took part in solo and many group exhibitions, made the art of graffiti widespread through his brand collaborations and events with brands such as Adidas,' +
            ' Redbull, Puma, Beşiktaş JK, Efes Pilsen, Istanblue, Jack Daniels, Peugeot, Swatch, GAP, Nokia and Superstep.' +
            ' Working on different patterns in recent years, Mr. Hure makes more abstract drawings and graffiti.' +
            ' It is possible to see the artist’s graffiti works and street art works in different countries of the world such as Germany,' +
            ' America, Bulgaria, Bosnia and Herzegovina, France, Italy, Serbia, Syria and Greece in addition to various cities of Turkey .' +
            ' The artist, whose work you can see at the entrance of Istanbul Airport, continues his activities in Istanbul.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Muhammet_Bakir.webp',
          HEADING: 'Muhammet Bakır',
          TEXT: '“The spaces reduced to geometric forms in my works are sheltered places where people\'s inner world is shaped and they feel good.' +
            ' These works, which claim that the transcendent attraction of the outside world surrounding the spaces cannot be understood' +
            ' without looking at the spiritual void that these buildings contain, which refer to houses, businesses, schools and places of worship,' +
            ' create memory through metaphors dressed with color, stain and texture.”<br>' +
            'Born in Malatya in 1980, the artist\'s passion for painting has been a part of his life since his childhood.' +
            ' Bakır, whose drawings have been published in various fanzines and periodicals since his high school years,' +
            ' evaluated the art education in the art faculties of 4 different universities in Turkey as a process of accumulation.' +
            ' Universities he got painting education are İnönü University, Hacettepe University, Gazi University and Yıldız Technical University.' +
            ' The artist, who makes interdisciplinary productions, mostly produces works in the fields of painting, conceptual art and graphic humor.' +
            ' His works, which received national and international awards, have been exhibited in many places at home and abroad.' +
            ' His works are in various private collections.' +
            ' In his recent works, he mainly deals with the relationship between space and figure.' +
            ' In his works, which claim that the transcendent attraction of the outside world surrounding the spaces cannot be understood ' +
            ' without looking at the spiritual void that the spaces reduced to geometric forms; he creates memory through metaphors dressed in colour, stain and texture.' +
            ' The artist continues his work in Istanbul.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Saniye_Ozbek.webp',
          HEADING: 'Saniye Özbek',
          TEXT: '“I cannot exist without an identity, I am constantly being defined by others or myself.' +
            ' Those definitions are not me. I try to reflect this with fluidity and the chaos of being human.”<br>' +
            ' "I’m the gap between what I’d like to be and what others have made of me." as Fernando Pessoa said.' +
            ' Born in Kırıkkale in 1993, the artist studied at Karamürsel High School between 2007-2011.' +
            ' After graduating from Marmara University Painting Teaching Department in 2017,' +
            ' she assisted London-based painter Aslı Özok, who has studios in Istanbul and Sevilla, in her workshop in Istanbul.' +
            ' Özbek, who has participated in many group exhibitions and discovered the healing power of art since 2015, started to receive Art Therapy Training at Altınbaş University.' +
            ' The artist, who questions people’s search for identity in her art; she works in fields such as painting, sculpture, graphics and ceramics where she is educated.' +
            ' In addition to her works, Özbek also continues her sectoral activities as a designer and continues her artistic works in her own workshop.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Suleyman_Engin.webp',
          HEADING: 'Süleyman Engin',
          TEXT: '“I focus on visual aesthetics in the works I produce. I focus on elements such as line, color, balance, motif, rather than content or narrative.”<br>' +
            'The artist, who was born in Aydın in 1989, started to live in İzmir in 2003 after taking his first steps in his education life here.' +
            ' After completing his high school education in the Department of Graphics and Photography,' +
            ' Engin started his undergraduate education in Kocaeli University, Faculty of Fine Arts, Department of Sculpture in 2014, and continued his graduate education in the same institution and field.' +
            ' Continuing his productions in the field of painting along with sculpture, Engin has won exhibitions and awards in various competitions in both fields.' +
            ' Continuing his works in painting and sculpture in his workshop in Üsküdar, the artist continues his work independently.' +
            ' The artist, who uses compositions containing chaos in his painting works, presents this chaos to the audience in the company of his colorful characters.' +
            ' He emphasizes that the audience should go on a journey while interpreting the work in order to resolve the confusion and chaos.' +
            ' The artist, who uses sharp color transitions on the painting surface, also occasionally uses the color element in his sculpture works.' +
            ' Engin, who uses materials such as stone, wood and polyester in sculpture, has recently been working on abstracted portraits.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Utku_Oksuz.webp',
          HEADING: 'Utku Öksüz',
          TEXT: '“In my works, I seek the equivalent of today and the truth of the order we have created.”<br>' +
            'Born in 1991 in Ordu, the artist completed his art education at Ordu Fine Arts High School between 2005-2010.' +
            ' After graduating from Kütahya Dumlupınar University Fine Arts Faculty Painting Department, he received his master’s degree from Gazi University Fine Arts Institute Composite Arts Department.' +
            ' Öksüz, who worked as a visual arts instructor at a private education institution between 2017-2021,' +
            ' took part in many national and international group exhibitions besides his personal exhibition and received 7 different awards.' +
            ' “What is today’s equivalent? Before it was nothingness, existence came into being. The day has come, people have risen.' +
            ' To understand their world, began to be spoken and produced; created their own myths, tales, epics. Undoubtedly believed.' +
            ' They progressed on the path they believed in, made mistakes, and continued to do so. They thought, philosophized.' +
            ' They noticed the absolute nothingness and deep silence behind existence, and when they did, fell into doubt.' +
            ' With my doubts, I went in search of non-existence or existence.' +
            ' Because we know that; in this world where we live by groping, there is no absolute truth that one can be sure of,' +
            ' everything about our lives is made up of our stories.” expresses his artistic point of view with his words. ',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Ummuhan_Tuncturk.webp',
          HEADING: 'Ümmühan Tunçtürk',
          TEXT: '“My paintings are an expression of my personality, aesthetic perception and the geography I live in.' +
            ' I am trying to produce works that will support my own identity reflections by following a path from the local to the universal....”<br>' +
            ' Born in Van in 1982, the artist graduated from Van Yüzüncü Yıl University, Faculty of Education, Department of Painting' +
            ' and completed her master’s degree at Yüzüncü Yıl University, Institute of Social Sciences, Department of Painting Education in 2009.' +
            ' Tunçtürk, with her paintings, which she tries to carry to the future by reflecting the traditional motifs, especially in the eastern culture, on the canvas with modern techniques;' +
            ' she held solo and group exhibitions in Italy, Spain and France, as well as in Istanbul, Ankara and Van. ' +
            ' “I am trying to reflect woman outside of her aesthetic body as I see it from my own perspective.' +
            ' Today, there is a perception that women are beautiful. I do not accept this, I think that women can look very different and I try to emphasize this in my pictures.”' +
            ' gives an idea about the understanding of art with her words. She continues her works in her workshop.',
        },
        {
          PHOTO: './assets/announcements/SAUNA_EMERGING_1/Yasin_Canli.webp',
          HEADING: 'Yasin Canlı',
          TEXT: '“While painting, I am aiming to establish a bond with the audience in order to make sense of,' +
            ' understand and perceive life through lines with the imaginary landscape effects in the background that develops instantaneously,' +
            ' as opposed to entering certain patterns and telling something like a poster.”<br>' +
            ' Born in Kahramanmaraş in 1998, the artist completed his high school education at Kahramanmaraş Fine Arts High School.' +
            ' After his graduation, he won Eskişehir Anadolu University Fine Arts Education Faculty and graduated from Anadolu University Fine Arts Education Faculty in 2020.' +
            ' Having many awards, Canlı participated in group exhibitions as an artist.' +
            ' Antalya 5. Artoros, Antalya Aydın Özgüven Sanat ve Eskişehir Tepebaşı Belediyesi 10. Uluslararası Sanat.',
        },
      ],
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references"></div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/0.d.webp',
          DESCRIPTION: 'Erdinç Sakin, “Saygı,“ Acrylic on Canvas, 2021, 60cm diameter'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/1.d.webp',
          DESCRIPTION: 'Erhan Karagöz, “Tell Me If You Wanna “BEE””, 160x120 cm, Oil Painting On Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/2.d.webp',
          DESCRIPTION: 'Küntay Tarık Evren, “Teddy Bear“, 80x60 cm, Ink on Paper, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/3.d.webp',
          DESCRIPTION: 'Mine Akçaoğlu, “Aynı Yerden Bakıyoruz“, 70x100 cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/4.d.webp',
          DESCRIPTION: 'Mr. Hure, “Yengeç“, 150x150 cm, Spray and Acrylic Paint - Mixed Media, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/5.d.webp',
          DESCRIPTION: 'Muhammet Bakır, “Sessiz Seda“, 149x199 cm, Acrylic on Canvas, 2021'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/6.d.webp',
          DESCRIPTION: 'Saniye Özbek, “Nereye Aitim?“, Acrylic on Canvas, 120x120 cm, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/7.d.webp',
          DESCRIPTION: 'Süleyman Engin, “Güneşli Bir Günde Dinazor Ailesinin Düşündükleri“, 140x150 cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/8.d.webp',
          DESCRIPTION: 'Utku Öksüz, “Mutsuz Kent“, 150x150cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/9.d.webp',
          DESCRIPTION: 'Ümmühan Tunçtürk, “Despotun Bir Günü”, 100x100 cm, Acrylic on Canvas, 2021'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/10.d.webp',
          DESCRIPTION: 'Yasin Canlı, “Bu Yol Nereye Gider“, 140x120 cm, Acrylic on Canvas, 2020'
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/0.m.webp',
          DESCRIPTION: 'Erdinç Sakin, “Saygı,“ Acrylic on Canvas, 2021, 60cm diameter'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/1.m.webp',
          DESCRIPTION: 'Erhan Karagöz, “Tell Me If You Wanna “BEE””, 160x120 cm, Oil Painting On Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/2.m.webp',
          DESCRIPTION: 'Küntay Tarık Evren, “Teddy Bear“, 80x60 cm, Ink on Paper, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/3.m.webp',
          DESCRIPTION: 'Mine Akçaoğlu, “Aynı Yerden Bakıyoruz“, 70x100 cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/4.m.webp',
          DESCRIPTION: 'Mr. Hure, “Yengeç“, 150x150 cm, Spray and Acrylic Paint - Mixed Media, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/5.m.webp',
          DESCRIPTION: 'Muhammet Bakır, “Sessiz Seda“, 149x199 cm, Acrylic on Canvas, 2021'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/6.m.webp',
          DESCRIPTION: 'Saniye Özbek, “Nereye Aitim?“, Acrylic on Canvas, 120x120 cm, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/7.m.webp',
          DESCRIPTION: 'Süleyman Engin, “Güneşli Bir Günde Dinazor Ailesinin Düşündükleri“, 140x150 cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/8.m.webp',
          DESCRIPTION: 'Utku Öksüz, “Mutsuz Kent“, 150x150cm, Acrylic on Canvas, 2020'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/9.m.webp',
          DESCRIPTION: 'Ümmühan Tunçtürk, “Despotun Bir Günü”, 100x100 cm, Acrylic on Canvas, 2021'
        },
        {
          SRC: './assets/announcements/SAUNA_EMERGING_1/10.m.webp',
          DESCRIPTION: 'Yasin Canlı, “Bu Yol Nereye Gider“, 140x120 cm, Acrylic on Canvas, 2020'
        },
      ],
      ARTWORK_REFERENCES: []
    },
    {
      ID: 7,
      TEMPLATE: 0,
      NAME: 'Ergin İnan',
      NAME_UPPERCASE: 'ERGİN İNAN',
      TOPIC: 'About Ergin İnan ',
      TOPIC_UPPERCASE: 'ABOUT ERGİN İNAN',
      ARTWORKS: 'Ergin İnan\'s Artworks',
      ARTWORKS_UPPERCASE: 'ERGİN İNAN\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/ERGIN_INAN/Ergin_Inan.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ERGIN_INAN/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/ERGIN_INAN/-1.m.webp',
      TEXT: 'Ergin İnan is a painter who has participated in many internationally recognized exhibitions and was awarded an award.' +
        ' The artist, who has an unconventional approach in contemporary Turkish art, has made conceptual studies using mixed media and printing techniques in his works.' +
        ' Abstracted human figures, writing images and insect depictions can be given as examples to the images that are common in Ergin İnan\'s works.<br>' +
        ' Ergin İnan was born in Malatya in 1943 and completed his primary and secondary education there. Although he won the İstanbul Faculty of Law in 1963, he did not continue.' +
        ' İnan, who started the İstanbul State Academy of Applied Fine Arts in 1964, graduated in 1968.<br>' +
        'One of the distinguishing features of Ergin İnan\'s art is his extensive and deep treatment of Sufi philosophy and spirituality in his works.' +
        ' İnan\'s understanding of art is “You have to look at art as a spiritual world, you live in that world; but nobody knows. You try to push that out from time to time.” explained with a sentence.' +
        ' The artist, who handles extraordinary and spiritual elements in his paintings, expressed the reality between extinction and existence.' +
        ' Images and references from traditional Turkish-Islamic art can also be seen in İnan\'s works. He frequently used the old books and pages he collected from second-hand booksellers in his works.<br>' +
        ' İnan continued his academic activities in the years after his graduation. After graduation, he continued his education in Salzburg and Munich in his first years.' +
        ' In 1978, he worked as a research assistant at the Munich and Berlin Fine Arts Academies on a scholarship. He then continued his research work in Amsterdam, The Hague and London.' +
        ' İnan, who was appointed as an education member in the Faculty of Fine Arts of Marmara University in 1982, taught for a year.' +
        ' The artist, who was awarded the DAAD Berlin Artists Scholarship between 1983-1987, continued his work in Berlin upon invitation.' +
        ' On his return to Turkey, he was appointed as a professor at Marmara University, where he was a faculty member.' +
        ' Ergin İnan has lectured at Marmara University, Mimar Sinan Fine Arts University and Yeditepe University.<br>' +
        ' Ergin İnan has been awarded many national and international awards.' +
        ' Some of these awards:' +
        ' 1977 İstanbul Güzel Sanat Akademisi “Özgün Baskı” Birincilik Ödülü,' +
        ' 1980 Uluslararası Grafik Bienali Madalya,' +
        ' 1981 Uluslararası 5. Cleveland Bienali 4.lük Ödülü,' +
        ' 1982 Uluslararası Minyatür Baskı Bienali Ödülü(Güney Kore) (South Korea),' +
        ' 1983 Uluslararası Norveç Baskı Bienali Büyük Ödülü,' +
        ' 1987 Yılın Sanatçısı Ankara Sanat Kurumu,' +
        ' 1988 Uluslararası 2. Asya-Avrupa Bienali Birincilik Ödülü (Ankara),' +
        ' 1993 Uluslararası Resim Trienali Üçüncülük Ödülü (Japan),' +
        ' 2010 Cumhurbaşkanlığı Kültür ve Sanat Büyük Ödülü.<br>' +
        ' Ergin İnan, who retired in 2000, continues his artistic works in his workshop in Istanbul. ',
      TEXT_LIMIT_DESKTOP: 1150,
      TEXT_LIMIT_MOBILE: 1150,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Soylu, Rasim, “Ergin İnan Resimlerinde Tasavvuf İmgeleri”, Uluslararası Sosyal ve Beşeri Bilimler Araştırma Dergisi, sayı 41, 29.08.2019</li>' +
        '   <li>Tansuğ, S., Çağdaş Türk Sanatı (1991), 2.Bs. İstanbul: Remzi Kitabevi.</li>' +
        '   <li>Özışık, C. D., Osmanlı Kültüründe Resimsel Öge Olarak Mistik Semboller ve Günümüz Sanatına Yansımaları (2010), Marmara Üniversitesi, Güzel Sanatlar Enstitüsü, İstanbul.</li>' +
        '   <li>Soylu, R., Çağdaş Türk Resim Sanatında Mistik Semboller ve Göstergebilim Analizleri (2016), Yıldız Teknik Üniversitesi, Sosyal Bilimler Enstitüsü, İstanbul.</li>' +
        '   <li>“Ergin İnan”, www.biyografya.com, Aralık 13, 2020</li>' +
        '   <li>Nazlı Şahin, “Ergin İnan Kimdir? Hayatı ve Eserleri”, www.beyazegitim.com, Aralık 13, 2020</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/ERGIN_INAN/0.d.webp',
          DESCRIPTION: '"Can Işığı Renkleri" 135x135 cm, AOPOC, 2021'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/1.d.webp',
          DESCRIPTION: '"Begonvil Pembesi" 100x150 cm, AOPOC, -'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/2.d.webp',
          DESCRIPTION: '"Ayrılmış Ayrı Ayrıntılardan" 170x125 cm, MTOW, 1994'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/3.d.webp',
          DESCRIPTION: '"Binbir Böcek" 50x50 cm, AOPOC, 1996'
        },
        {SRC: './assets/announcements/ERGIN_INAN/4.d.webp', DESCRIPTION: '"Portre İkona" 70x50 cm, OPOW, 1990'},
        {SRC: './assets/announcements/ERGIN_INAN/5.d.webp', DESCRIPTION: '"El" 31x18 cm, POP, 1997'},
        {SRC: './assets/announcements/ERGIN_INAN/6.d.webp', DESCRIPTION: '"Kompozisyon" 167x132 cm, MTOC, 2001'},
        {SRC: './assets/announcements/ERGIN_INAN/7.d.webp', DESCRIPTION: '"Arı" 44x37 cm, MTOW, 2015'},
        {SRC: './assets/announcements/ERGIN_INAN/8.d.webp', DESCRIPTION: 'Untitled, 17x13 cm, Gravure, 1990'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/ERGIN_INAN/0.m.webp',
          DESCRIPTION: '"Can Işığı Renkleri" 135x135 cm, AOPOC, 2021'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/1.m.webp',
          DESCRIPTION: '"Begonvil Pembesi" 100x150 cm, AOPOC, -'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/2.m.webp',
          DESCRIPTION: '"Ayrılmış Ayrı Ayrıntılardan" 170x125 cm, MTOW, 1994'
        },
        {
          SRC: './assets/announcements/ERGIN_INAN/3.m.webp',
          DESCRIPTION: '"Binbir Böcek" 50x50 cm, AOPOC, 1996'
        },
        {SRC: './assets/announcements/ERGIN_INAN/4.m.webp', DESCRIPTION: '"Portre İkona" 70x50 cm, OPOW, 1990'},
        {SRC: './assets/announcements/ERGIN_INAN/5.m.webp', DESCRIPTION: '"El" 31x18 cm, POP, 1997'},
        {SRC: './assets/announcements/ERGIN_INAN/6.m.webp', DESCRIPTION: '"Kompozisyon" 167x132 cm, MTOC, 2001'},
        {SRC: './assets/announcements/ERGIN_INAN/7.m.webp', DESCRIPTION: '"Arı" 44x37 cm, MTOW, 2015'},
        {SRC: './assets/announcements/ERGIN_INAN/8.m.webp', DESCRIPTION: 'Untitled, 17x13 cm, Gravure, 1990'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/ergin-inan/page/2/" target="_blank">' +
        'https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/ergin-inan/page/2/' +
        '</a>',
        '<a class="gray-underline" href="http://lebriz.com/pages/artist.aspx?section=130&lang=TR&artistID=13&periodID=-1&pageNo=0&exhID=0" target="_blank">' +
        'http://lebriz.com/pages/artist.aspx?section=130&lang=TR&artistID=13&periodID=-1&pageNo=0&exhID=0' +
        '</a>'
      ]
    },
    {
      ID: 8,
      TEMPLATE: 0,
      NAME: 'Adnan Çoker',
      NAME_UPPERCASE: 'ADNAN ÇOKER',
      TOPIC: 'About Adnan Çoker',
      TOPIC_UPPERCASE: 'ABOUT ADNAN ÇOKER',
      ARTWORKS: 'Adnan Çoker Artworks',
      ARTWORKS_UPPERCASE: 'ADNAN ÇOKER\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/ADNAN_COKER/Adnan_Coker.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ADNAN_COKER/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/ADNAN_COKER/-1.m.webp',
      TEXT: 'Adnan Çoker, who has an important place in 20th century Turkish art, is best known for creating an original aesthetic style by transforming Byzantine, Seljuk and Ottoman architectural forms into pictorial contours.' +
        ' The artist, who produced works in this line after the 1970s, works in his paintings, which he describes as pattern-form, in an abstract spatial dimension on black.' +
        ' The harmony created by the motif of the pointed arched door and window opening the interior space of the monumental architecture to the outside world, is based on a molded form in the words of the artist.<br>' +
        'Adnan Çoker was born in Istanbul in 1927. He graduated from Afyon High School in 1944 and entered the Fine Arts Academy, Department of High Painting.' +
        ' Coker, who studied here between 1944-1951, worked at Zeki Kocamemi Atelier.' +
        ' After six years, he graduated from the Fine Arts Academy in 1951.<br>' +
        'He opened his first personal exhibition in Ankara in 1953, under the name "Before Exhibition". This was followed by the exhibitions he opened in Ankara in 1954 and Istanbul in 1955.' +
        ' However, the turning point of his artistic life was his going to Paris with the state scholarship he won in 1955.<br>' +
        'He worked in the workshops of names such as Andre Lhote and Henri Goetz in Paris.' +
        ' After completing his education in 1960, Çoker returned to Turkey after five years and became an assistant at the Fine Arts Academy Painting Department in the same year.' +
        ' After that, in 1963, he founded the painting group "Blue Group" with A. Gürman, Sarkis, Devrim Erbil and Tülay Tura, who were among the important names of the period.' +
        ' In 1964, Çoker, who won a state scholarship for the second time, went to Paris again to continue his education.' +
        ' Working on engraving at the W. Hayter Atelier and on paint at the Goetz Atelier, the artist laid the foundations of his geometric paintings with a black background, which would later become of great importance.' +
        ' Returning in 1965, Çoker continued his teaching career at the Academy of Fine Arts. In 1969, he received the title of associate professor in this field.' +
        ' Adnan Çoker, whose works were generally based on abstract expressionism until 1970, adopted a geometric, architectural, volumetric and abstract approach after this year.' +
        ' These black-backed works brought a great success to Çoker.' +
        ' After this year, Adnan Çoker; With a balanced, symmetrical, determined, static and definite formalism, it has adopted the principle of reaching pure and simple forms,' +
        ' which rejects the expressive elements of suprematists and minimal artists.' +
        ' He was appointed as a professor at the Painting Department of the Fine Arts Academy in 1976, and then as the Director of the Istanbul Painting and Sculpture Museum in 1977 during his professorship.' +
        ' He resigned from here in 1979, and then in 1983, he became the Head of the Painting Department of Mimar Sinan University Fine Arts Faculty.' +
        ' However, he resigned from this post in 1985. Having works in many institutions and private collections, Çoker has opened 26 personal exhibitions and participated in many group exhibitions.' +
        ' The artist passed away in August 2022.',
      TEXT_LIMIT_DESKTOP: 1150,
      TEXT_LIMIT_MOBILE: 1150,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li></li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/ADNAN_COKER/0.d.webp', DESCRIPTION: '\"Mor Ötesi Boşluk\" 50x50 cm, OPOC, 1979'},
        {SRC: './assets/announcements/ADNAN_COKER/1.d.webp', DESCRIPTION: '\"Mavi Kompozisyon\" 52x49 cm, KÜG, 1963'},
        {SRC: './assets/announcements/ADNAN_COKER/2.d.webp', DESCRIPTION: '\"Açık Simetri II\" 60x80 cm, OPOC, 1975'},
        {SRC: './assets/announcements/ADNAN_COKER/3.d.webp', DESCRIPTION: '\"Simetri Geçiş II\" 89x116 cm, OPOC, 1978'},
        {SRC: './assets/announcements/ADNAN_COKER/4.d.webp', DESCRIPTION: '\"Posa\' 92x73 cm, OPOC, 1965'},
        {SRC: './assets/announcements/ADNAN_COKER/5.d.webp', DESCRIPTION: '\"Half Globes II\" 180x180,5 cm, TÜA, 1996'},
        {SRC: './assets/announcements/ADNAN_COKER/6.d.webp', DESCRIPTION: '\"Domes\" 150x150 cm, TÜA, 2017'},
        {SRC: './assets/announcements/ADNAN_COKER/7.d.webp', DESCRIPTION: '\"Untitled\" 179,4x179,4 cm, TÜA, 1996'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/ADNAN_COKER/0.m.webp', DESCRIPTION: '\"Mor Ötesi Boşluk\" 50x50 cm, OPOC, 1979'},
        {SRC: './assets/announcements/ADNAN_COKER/1.m.webp', DESCRIPTION: '\"Mavi Kompozisyon\" 52x49 cm, KÜG, 1963'},
        {SRC: './assets/announcements/ADNAN_COKER/2.m.webp', DESCRIPTION: '\"Açık Simetri II\" 60x80 cm, OPOC, 1975'},
        {SRC: './assets/announcements/ADNAN_COKER/3.m.webp', DESCRIPTION: '\"Simetri Geçiş II\" 89x116 cm, OPOC, 1978'},
        {SRC: './assets/announcements/ADNAN_COKER/4.m.webp', DESCRIPTION: '\"Posa\' 92x73 cm, OPOC, 1965'},
        {SRC: './assets/announcements/ADNAN_COKER/5.m.webp', DESCRIPTION: '\"Half Globes II\" 180x180,5 cm, TÜA, 1996'},
        {SRC: './assets/announcements/ADNAN_COKER/6.m.webp', DESCRIPTION: '\"Domes\" 150x150 cm, TÜA, 2017'},
        {SRC: './assets/announcements/ADNAN_COKER/7.m.webp', DESCRIPTION: '\"Untitled\" 179,4x179,4 cm, TÜA, 1996'},
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="http://lebriz.com/pages/artist.aspx?artistID=483&section=130&periodID=-1&pageNo=0&exhID=0&lang=TR&bhcp=1" target="_blank">' +
        'http://lebriz.com/pages/artist.aspx?artistID=483&section=130&periodID=-1&pageNo=0&exhID=0&lang=TR&bhcp=1' +
        '</a>',
      ]
    },
    {
      ID: 9,
      TEMPLATE: 0,
      NAME: 'Pablo Picasso',
      NAME_UPPERCASE: 'PABLO PICASSO',
      TOPIC: 'About Pablo Picasso',
      TOPIC_UPPERCASE: 'ABOUT PABLO PICASSO',
      ARTWORKS: 'Pablo Picasso\'s Artworks',
      ARTWORKS_UPPERCASE: 'PABLO PICASSO\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/PABLO_PICASSO/Pablo_Picasso.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/PABLO_PICASSO/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/PABLO_PICASSO/-1.m.webp',
      TEXT: 'Pablo Picasso, one of the greatest and most influential artists of the 20th century, is born in Malaga, Spain.<br>' +
        'Picasso’s father was a professor of drawing, and he bred his son for a career in academic art.' +
        ' Picasso had his first exhibit at age 13 and later quit art school so he could experiment full-time with modern art styles.' +
        ' He went to Paris for the first time in 1900, and in 1901 was given an exhibition at a gallery on Paris’ rue Lafitte, a street known for its prestigious art galleries.' +
        ' The precocious 19-year-old Spaniard was at the time a relative unknown outside Barcelona, but he had already produced hundreds of paintings.' +
        ' Winning favorable reviews, he stayed in Paris for the rest of the year and later returned to the city to settle permanently.<br>' +
        'The work of Picasso, which comprises more than 50,000 paintings, drawings, engravings, sculptures, and ceramics produced over 80 years, is described in a series of overlapping periods.' +
        ' His first notable period –the “blue period”— began shortly after his first Paris exhibit.' +
        ' In works such as The Old Guitarist (1903), Picasso painted in blue tones to evoke the melancholy world of the poor.' +
        ' The blue period was followed by the “rose period,” in which he often depicted circus scenes, and then by Picasso’s early work in sculpture.' +
        ' In 1907, Picasso painted the groundbreaking work Les Demoiselles d’Avignon,' +
        ' which broke from previous European art with its fragmented and distorted representation of the human form.' +
        ' Les Demoiselles d’Avignon demonstrated the influence on Picasso of both African mask art and Paul Cezanne and is seen as a forerunner of the Cubist movement,' +
        ' founded by Picasso and the French painter Georges Braque in 1909.<br>' +
        'In Cubism, which is divided into two phases, analytical and synthetic,' +
        ' Picasso and Braque established the modern principle that artwork need not represent reality to have artistic value.' +
        ' Major Cubist works by Picasso included his costumes and sets for Sergey Diaghilev’s Ballets Russes (1917) and The Three Musicians (1921).' +
        ' Picasso and Braque’s Cubist experiments also resulted in the invention of several new artistic techniques, including collage.<br>' +
        'After Cubism, Picasso explored classical and Mediterranean themes, and images of violence and anguish increasingly appeared in his work.' +
        ' In 1937, this trend culminated in the masterpiece Guernica,' +
        ' a monumental work that evoked the horror and suffering endured by the Basque town of Guernica when it was destroyed by German war planes during the Spanish Civil War.' +
        ' Picasso remained in Paris during the Nazi occupation but was fervently opposed to fascism and after the war joined the French Communist Party.<br>' +
        'Picasso’s work after World War II is less studied than his earlier creations, but he continued to work feverishly and enjoyed commercial and critical success.' +
        ' He produced fantastical works, experimented with ceramics and painted variations on the works of other masters in the history of art.' +
        ' Known for his intense gaze and domineering personality, he had a series of intense and overlapping love affairs in his lifetime.' +
        ' He continued to produce art with undiminished force until his death in 1973 at the age of 91.',
      TEXT_LIMIT_DESKTOP: 1150,
      TEXT_LIMIT_MOBILE: 1180,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://www.history.com/this-day-in-history/pablo-picasso-born</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/PABLO_PICASSO/0.d.webp',
          DESCRIPTION: '"Sarı Picador" 24x19 cm, Oil Paint on Wood, 1889'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/1.d.webp',
          DESCRIPTION: '"Casagemas\'ın Ölümü" 27x35 cm, Oil Paint on Wood, 1901'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/2.d.webp',
          DESCRIPTION: '"Trajedi" 105.4x69 cm, Oil Paint on Wood, 1903'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/3.d.webp',
          DESCRIPTION: '"Yaşlı Gitarist" 121.3x82.5 cm, OPOC, 1903-1904'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/4.d.webp',
          DESCRIPTION: '"Saltimbanques Ailesi" 212.8x229.6 cm, OPOC, 1905'
        },
        {SRC: './assets/announcements/PABLO_PICASSO/5.d.webp', DESCRIPTION: '"Otoportre" 39x30 cm, OPOC, 1906'},
        {
          SRC: './assets/announcements/PABLO_PICASSO/6.d.webp',
          DESCRIPTION: '"Avignonlu Kızlar" 243.9x233.7 cm, OPOC, 1907'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/7.d.webp',
          DESCRIPTION: '"Ambroise Vollard Portresi" 92x65 cm, OPOC, 1910'
        },
        {SRC: './assets/announcements/PABLO_PICASSO/8.d.webp', DESCRIPTION: '"Guernica" 349x776 cm, OPOC, 1937'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/PABLO_PICASSO/0.m.webp',
          DESCRIPTION: '"Sarı Picador" 24x19 cm, Oil Paint on Wood, 1889'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/1.m.webp',
          DESCRIPTION: '"Casagemas\'ın Ölümü" 27x35 cm, Oil Paint on Wood, 1901'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/2.m.webp',
          DESCRIPTION: '"Trajedi" 105.4x69 cm, Oil Paint on Wood, 1903'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/3.m.webp',
          DESCRIPTION: '"Yaşlı Gitarist" 121.3x82.5 cm, OPOC, 1903-1904'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/4.m.webp',
          DESCRIPTION: '"Saltimbanques Ailesi" 212.8x229.6 cm, OPOC, 1905'
        },
        {SRC: './assets/announcements/PABLO_PICASSO/5.m.webp', DESCRIPTION: '"Otoportre" 39x30 cm, OPOC, 1906'},
        {
          SRC: './assets/announcements/PABLO_PICASSO/6.m.webp',
          DESCRIPTION: '"Avignonlu Kızlar" 243.9x233.7 cm, OPOC, 1907'
        },
        {
          SRC: './assets/announcements/PABLO_PICASSO/7.m.webp',
          DESCRIPTION: '"Ambroise Vollard Portresi" 92x65 cm, OPOC, 1910'
        },
        {SRC: './assets/announcements/PABLO_PICASSO/8.m.webp', DESCRIPTION: '"Guernica" 349x776 cm, OPOC, 1937'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="https://www.pablo-ruiz-picasso.net/work-261.php" target="_blank">https://www.pablo-ruiz-picasso.net/work-261.php</a>',
        '<a class="gray-underline" href="https://arthive.com/pablopicasso/works/196810~The_death_of_Casagemas" target="_blank">https://arthive.com/pablopicasso/works/196810~The_death_of_Casagemas</a>',
        '<a class="gray-underline" href="https://www.wikiart.org/en/pablo-picasso/the-death-of-casagemas-1901" target="_blank">https://www.wikiart.org/en/pablo-picasso/the-death-of-casagemas-1901</a>',
      ]
    },
    {
      ID: 10,
      TEMPLATE: 0,
      NAME: 'Ahmet Oran',
      NAME_UPPERCASE: 'AHMET ORAN',
      TOPIC: 'About Ahmet Oran',
      TOPIC_UPPERCASE: 'ABOUT AHMET ORAN',
      ARTWORKS: 'Ahmet Oran\'s Artworks',
      ARTWORKS_UPPERCASE: 'AHMET ORAN\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/AHMET_ORAN/Ahmet_Oran.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_ORAN/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_ORAN/-1.m.webp',
      TEXT: 'Ahmet Oran, known for his abstract works, was born in 1957 in Çanakkale.<br>' +
        'The artist, who entered the Istanbul State Academy of Fine Arts in 1977, studied painting, glass painting and graphics at the Vienna Applied Fine Arts College while he was a student there.' +
        ' Working with Prof. Carl Unger (1980-1985) and Prof. A. Frohner (1985-1987), Ahmet Oran stepped into the world of painting in Europe with two exhibitions he opened in Salzburg and Graz.' +
        ' He has been participating in individual and group exhibitions at home and abroad for more than thirty years.' +
        ' His works are exhibited in contemporary art museums such as Istanbul Modern and the Lentos Art Museum in Linz.' +
        ' The artist lives and works in Istanbul and Vienna.' +
        'Expressing that he creates his works with inspiration from the historical layers of Istanbul, its vibrant past, exciting contemporary life and unceasing energy,' +
        ' Ahmet Oran\'s paintings reveal the physical skin character of color when a part of the paint layer above or below the painting is peeled off.' +
        ' This matrix is rendered by the artist in different ways.' +
        ' Usually it creates a pulsating body of color space, but this spreads beyond the actual limits of the image carrier.' +
        'The artist emphasizes that his paintings are not accidental or spontaneous, but designed from the very beginning.:' +
        ' “I perform all the stages step by step within a certain plan until the end.' +
        ' The effect of each color is of course different.' +
        ' The effect that mentioned is; the identity of that picture, its character. This is already planned from the beginning.”.' +
        ' Ahmet Oran defines his paintings as “paintings that are perceived only as paintings and will always remain as paintings”.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '' +
        // '<div class="references">' +
        // ' <ol>' +
        // '   <li>Sibel Almelek İşman, Çağdaş Türk Resminde Müzik ve Dans İmgeleri, Uluslararası Sosyal Araştırmalar Dergisi, cilt 10, sayı 54, (2017), 393-394.</li>' +
        // ' </ol>' +
        // '</div>' +
        '',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/AHMET_ORAN/0.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/1.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/2.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/3.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/4.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/5.d.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/6.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 120x100 cm, OPOC'},
        {
          SRC: './assets/announcements/AHMET_ORAN/7.d.webp',
          DESCRIPTION: '"Koyu Mavi Soyutlama" 110x110 cm, OPOC, 2021'
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/AHMET_ORAN/0.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/1.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/2.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/3.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/4.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/5.m.webp', DESCRIPTION: 'Untitled, 120x100 cm, OPOC, 2022'},
        {SRC: './assets/announcements/AHMET_ORAN/6.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 120x100 cm, OPOC'},
        {
          SRC: './assets/announcements/AHMET_ORAN/7.m.webp',
          DESCRIPTION: '"Koyu Mavi Soyutlama" 110x110 cm, OPOC, 2021'
        },
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="https://www.aysilmuzayede.com/urun/4056148/ahmet-oran-1957-untitled-tuval-uzeri-yagli-boya-olcu-120x100cm" target="_blank">' +
        'https://www.aysilmuzayede.com/urun/4056148/ahmet-oran-1957-untitled-tuval-uzeri-yagli-boya-olcu-120x100cm</a>',
        '<a class="gray-underline" href="https://www.onlinemuzayede.com/en/product/4416839/ahmet-oran-navy-blue-abstraction" target="_blank">' +
        'https://www.onlinemuzayede.com/en/product/4416839/ahmet-oran-navy-blue-abstraction</a>',
        '<a class="gray-underline" href="https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/" target="_blank">' +
        'https://www.mirakoldasartgallery.com/urun-kategori/sanatcilar/</a>',
      ]
    },
    {
      ID: 11,
      TEMPLATE: 0,
      NAME: 'Fahrelnissa Zeid',
      NAME_UPPERCASE: 'FAHRELNISSA ZEID',
      TOPIC: 'About Fahrelnissa Zeid',
      TOPIC_UPPERCASE: 'ABOUT FAHRELNISSA ZEID',
      ARTWORKS: 'Fahrelnissa Zeid\'s Artworks',
      ARTWORKS_UPPERCASE: 'FAHRELNISSA ZEID\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/FAHRELNISSA_ZEID/Fahrelnissa_Zeid.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/FAHRELNISSA_ZEID/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/FAHRELNISSA_ZEID/-1.m.webp',
      TEXT: 'Born in Büyükada on January 7, 1901, painter Fahrelnissa Zeid is from the Kabaağaçlı family, which has trained many artists.' +
        ' Although the family has a military background, members of the Kabaağaçlı family have written their names in history in different branches of art.' +
        ' Famous painter Fahrelnissa Zeid, sister of Halikarnas Balıkçısı Cevat Şakir and painter Aliye Berger, mother of actress Şirin Devrim and painter Nejad Devrim,' +
        ' and aunt of Turkey’s first female ceramic artist Füreya Koral, is a talented Turkish woman who made her name known to the whole world with her art and character.' +
        ' Although her family calls her Fahrünnisa, she prefers to use her name as Fahrelnissa abroad and in the signature of her paintings.<br>' +
        'While she was studying, she married İzzet Melih Devrim, and from her 14-year marriage, painter Nejad Melih Devrim and theater actress Şirin Devrim were born.<br>' +
        'She went to Paris with her husband in 1928.' +
        ' She follows painting classes at the Académie Ranson and the Roger Bissière workshop.' +
        ' Then she returns to Istanbul, she is a popular and admired name with her beauty and culture when she returns to Istanbul.<br>' +
        'Fahrelnissa Zeid changes every environment she enters with her atmosphere.' +
        ' The words of gallery manager Xavier Bureau, who has experienced Zeid\'s fascinating influence, summarize Zeid\'s influence:<br>' +
        '<i>“It is impossible not to notice Zeid when you see it.' +
        ' They wore resplendent clothes, in a sense of the same race as Katia Granoff (French art dealer), large women in unusual attire.' +
        ' It was impossible not to go unnoticed when you went out with them.' +
        ' They looked like icons, it is necessary to bring reality to the dimension of the extraordinary.' +
        ' Already, Fahrelnissa Zeid herself had an air of living in a dimension of the extraordinary.' +
        ' For me, she really was a character from 1001 Nights Tales because she was so theatrical and she carried that theatricality as if it were something so natural and so simple.' +
        ' I will never forget Zeid placing her easel in the middle of the gallery to portray Katia Granoff\'s niece, Pierre La Roc.' +
        ' It was almost a ceremony. The easel was in the middle, the day was dedicated to the portrait, all the necessary tools were arranged around the painter.' +
        ' She liked to have people there while she was painting.' +
        ' There aren\'t many painters who like to be watched while they paint, but she clearly loved it.”</i><sup>[1]</sup><br>' +
        'Fahrelnissa Zeid broke up with İzzet Melih Bey in 1933 and married Prince Zeid bin Hussein, Iraq\'s ambassador to Turkey, brother of Iraqi King Faisal I in 1934.' +
        ' In addition to Berlin, where her husband was an embassy, she lived in Baghdad for a while when her husband was the regent.' +
        ' Her son, Ra\'ad Bin Zeid, was born in Berlin in 1936.<br>' +
        'Returning to Istanbul in 1941, Zeid opened his first exhibition in 1944 at the Ralli apartment in Maçka.' +
        ' Everyone who hears this speaks that no one will come to the exhibition to be held in the apartment.' +
        ' Fahrelnissa sends all the belongings to the warehouse and exhibits 180 of her works in the apartment.' +
        ' Everyone from seven to seventy shows great interest in the exhibition.<br>' +
        'Saying that the painting is different from the photograph, Zeid makes the following sentences about the portraits:<br>' +
        '<i>“I place my model far enough from me so that I have a general impression of that person\'s existence and spirit, not see the details, but actually have a general impression of that person\'s existence and spirit by looking at the so-called figure.' +
        ' Therefore, the model I will be working with should be 6 to 8 meters away from me.' +
        ' This is how I work in a kind of uncertainty.' +
        ' Nothing is certain and so I can go into a defensive state filled with energy and feel like; Now we have to win, this is a war and we have to win the war.' +
        ' On that spotless whiteness, it is necessary to reflect the spirit of that person who is far from me and whom I cannot see very well.”</i><sup>[2]</sup><br>' +
        'The late 1940s were the years when she started to focus on abstract compositions, which are now her best-known works.' +
        ' These are works that are extremely monumental and contain intense lyricism, in which luminous colors are used.' +
        ' The critics of the period expressed different views on the sources of the abstract in Fahrelnissa Zeid\'s art.' +
        ' European critics have dealt with her with an orientalist and political interest and have looked for traces of the East in the elements of his art.' +
        ' According to some, there is an Islamic aesthetic in these works, which are composed of geometric and free abstractionist compositions.<sup>[3]</sup><br>' +
        'The artist, who initially did not want to paint abstractly, even resisted, explains why she paints abstract as follows:<br>' +
        '<i>“The life of the artist creates and even changes the art in question.' +
        ' There were several reasons why I did abstract painting.' +
        ' In the early hours of the morning, from the window of the palace-like house I stayed in when I first went to Baghdad in 1938,' +
        ' I saw the rapid passage of women going to the market with their yogurt bowls on their heads.' +
        ' When I was a child, I used to see people from behind the cage when I looked out the window.' +
        ' More precisely, what I saw was not the persons, but their colours.' +
        ' That\'s why I divided the colors in my paintings with black lines.”</i><sup>[4]</sup><br>' +
        'Zeid\'s 1962 painting \'The Fragmentation of the Atom and Vegetal Life\' was sold at an auction held by Christie\'s Auction House in 2013 for 2 million 741 thousand dollars,' +
        ' or approximately 45 million liras and she won the title of female artist who painted the highest-priced work in the Middle East.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>Cengiz Özkarabekir, Gökkuşağında İki Kuşak, 2006</li>' +
        '   <li>Cengiz Özkarabekir, Gökkuşağında İki Kuşak, 2006</li>' +
        '   <li>leblebitozu.com, Fahrelnissa Zeid’in 23 Resmi ve Hayatı, 2016</li>' +
        '   <li>oggusto.com, Fahrelnissa Zeid; Hayatı, Eserleri, Bilinmeyenleri, 2021</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/FAHRELNISSA_ZEID/0.d.webp', DESCRIPTION: '\"Zeynep\" 100x64 cm, OPOC, 1980'},
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/1.d.webp',
          DESCRIPTION: '\"Çerkez Gelini\" 135x100 cm, OPOC, 1980-1985'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/2.d.webp',
          DESCRIPTION: '\"Portrait of Seteney Shami\" 100x79 cm, OPOC, 1985'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/3.d.webp',
          DESCRIPTION: '\"Autoportrait\" 120x100 cm, OPOC, 1988'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/4.d.webp',
          DESCRIPTION: '\"L\'Oiseau Übü\" 146x113 cm, OPOC, 1950'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/5.d.webp',
          DESCRIPTION: '\"Portrait Of A Young Boy\" 124.5x100 cm, OPOC, -'
        },
        {SRC: './assets/announcements/FAHRELNISSA_ZEID/6.d.webp', DESCRIPTION: '\"Arabian Queen\" 91.5x71 cm, OPOC, -'},
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/7.d.webp',
          DESCRIPTION: '\"Lost Horizon\" 77x100 cm, OPOC, 1947'
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/FAHRELNISSA_ZEID/0.m.webp', DESCRIPTION: '\"Zeynep\" 100x64 cm, OPOC, 1980'},
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/1.m.webp',
          DESCRIPTION: '\"Çerkez Gelini\" 135x100 cm, OPOC, 1980-1985'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/2.m.webp',
          DESCRIPTION: '\"Portrait of Seteney Shami\" 100x79 cm, OPOC, 1985'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/3.m.webp',
          DESCRIPTION: '\"Autoportrait\" 120x100 cm, OPOC, 1988'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/4.m.webp',
          DESCRIPTION: '\"L\'Oiseau Übü\" 146x113 cm, OPOC, 1950'
        },
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/5.m.webp',
          DESCRIPTION: '\"Portrait Of A Young Boy\" 124.5x100 cm, OPOC, -'
        },
        {SRC: './assets/announcements/FAHRELNISSA_ZEID/6.m.webp', DESCRIPTION: '\"Arabian Queen\" 91.5x71 cm, OPOC, -'},
        {
          SRC: './assets/announcements/FAHRELNISSA_ZEID/7.m.webp',
          DESCRIPTION: '\"Lost Horizon\" 77x100 cm, OPOC, 1947'
        },
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="http://www.artnet.com/artists/fahrelnissa-zeid/14" target="_blank">http://www.artnet.com/artists/fahrelnissa-zeid/14</a>',
      ]
    },
    {
      ID: 12,
      TEMPLATE: 0,
      NAME: 'Haluk Akakçe',
      NAME_UPPERCASE: 'HALUK AKAKÇE',
      TOPIC: 'About Haluk Akakçe',
      TOPIC_UPPERCASE: 'ABOUT HALUK AKAKÇE',
      ARTWORKS: 'Haluk Akakçe\'s Artworks',
      ARTWORKS_UPPERCASE: 'HALUK AKAKÇE\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/HALUK_AKAKCE/Haluk_Akakce.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/HALUK_AKAKCE/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/HALUK_AKAKCE/-1.m.webp',
      TEXT: 'Haluk Akakçe, one of the most prominent names in Turkish contemprary art. Extraordinary, creative and genius…<br>' +
        'Haluk Akakçe\'s was born in Ankara, 1970. He was introduced to art at a very young age, with his grandfather\'s carvings and drawings and his ballet dancer father.' +
        ' Akakçe says that one of his greatest luck is that his family freed him and did not manipulate him.<br>' +
        'Akakçe graduated with a BFA from Bilkent University, Ankara, in Interior Architecture, an MA from the Royal College of Art, London and an MFA from the School of the Art Instıtute of Chicago with the highest marks.' +
        ' Akakçe, who defended that no matter what a person does, should do the best in everything he does, did not break the rule in the military and received a certificate as an exemplary soldier.<br>' +
        'Multidisciplinary artist Haluk Akakçe describes himself as a performer and artist whose works give the feeling of space and infinite time.' +
        ' Akakçe’s work varies in a wide range of media, from video installations, wall paintings, and acrylic on panel and paper.<br>' +
        '<i>I love the night because on the one hand, my job is like writing or praying, so I don\'t want too many witnesses, I don\'t want too much noise.' +
        ' It\'s as if I secretly want to believe it\'s a production in time that only belongs to me.</i><br>' +
        '-Haluk Akakçe<br>' +
        'Akakçe, who held many exhibitions in the world\'s leading art centers, realized his project "Sky is the Limit",' +
        ' which left its mark on 2006 at the invitation of the governor of Las Vegas, at Viva Vision, the world\'s largest LED screen, on Fremont Street.' +
        ' After this work, Akakçe exhibited a video installation by the Louis Vuitton brand at the Maison Louis Vuitton Champs-Elysées, where many valuable artists were invited in the past.<br>' +
        '<i>‘I’m from the future.’ That’s what Haluk says. I think you know what, maybe he’s from the future.</i><br>' +
        '-Richard Taittinger, Founder of Richard Taittinger Gallery<br>' +
        'After living in New York for 21 years, the artist returned to Turkey in recent years.' +
        ' Continued to work on art in his ateliers in Bodrum and Istanbul, he died in 2023.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references"></div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/HALUK_AKAKCE/0.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 190x125 cm, MTOW, -'},
        {SRC: './assets/announcements/HALUK_AKAKCE/1.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 185x185 cm, OPOC, -'},
        {SRC: './assets/announcements/HALUK_AKAKCE/2.d.webp', DESCRIPTION: '"Soyut Kompozisyon" 205x185 cm, OPOC, -'},
        {
          SRC: './assets/announcements/HALUK_AKAKCE/3.d.webp',
          DESCRIPTION: '"Sunshine on her shoulders" 208.3x256.5 cm, APOW, 2015'
        },
        {SRC: './assets/announcements/HALUK_AKAKCE/4.d.webp', DESCRIPTION: '"Platonic" 122x90cm, OPOC, 2017'},
        {
          SRC: './assets/announcements/HALUK_AKAKCE/5.d.webp',
          DESCRIPTION: '"Hollow Man With Feelings" 122x90cm, OPOC, 2017'
        },
        {SRC: './assets/announcements/HALUK_AKAKCE/6.d.webp', DESCRIPTION: 'Untitled 120x80cm, OPOC, 2020'},
        {SRC: './assets/announcements/HALUK_AKAKCE/7.d.webp', DESCRIPTION: '"Odalisque" 122x102cm, APOW, 2015'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/HALUK_AKAKCE/0.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 190x125 cm, MTOW, -'},
        {SRC: './assets/announcements/HALUK_AKAKCE/1.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 185x185 cm, OPOC, -'},
        {SRC: './assets/announcements/HALUK_AKAKCE/2.m.webp', DESCRIPTION: '"Soyut Kompozisyon" 205x185 cm, OPOC, -'},
        {
          SRC: './assets/announcements/HALUK_AKAKCE/3.m.webp',
          DESCRIPTION: '"Sunshine on her shoulders" 208.3x256.5 cm, APOW, 2015'
        },
        {SRC: './assets/announcements/HALUK_AKAKCE/4.m.webp', DESCRIPTION: '"Platonic" 122x90cm, OPOC, 2017'},
        {
          SRC: './assets/announcements/HALUK_AKAKCE/5.m.webp',
          DESCRIPTION: '"Hollow Man With Feelings" 122x90cm, OPOC, 2017'
        },
        {SRC: './assets/announcements/HALUK_AKAKCE/6.m.webp', DESCRIPTION: 'Untitled 120x80cm, OPOC, 2020'},
        {SRC: './assets/announcements/HALUK_AKAKCE/7.m.webp', DESCRIPTION: '"Odalisque" 122x102cm, APOW, 2015'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="https://artam.com/muzayede/262-cagdas-sanat-eserleri/haluk-akakce-1970-soyut-kompozisyon-4" target="_blank">https://artam.com/muzayede/262-cagdas-sanat-eserleri/haluk-akakce-1970-soyut-kompozisyon-4</a>',
        '<a class="gray-underline" href="https://www.galeriartget.com/urun/3226010/haluk-akakce/" target="_blank">https://www.galeriartget.com/urun/3226010/haluk-akakce/</a>',
        '<a class="gray-underline" href="https://www.galeriartget.com/urun/3226016/haluk-akakce/" target="_blank">https://www.galeriartget.com/urun/3226016/haluk-akakce/</a>',
        '<a class="gray-underline" href="http://www.artnet.com/artists/haluk-akakce/" target="_blank">http://www.artnet.com/artists/haluk-akakce/</a>',
      ]
    },
    {
      ID: 13,
      TEMPLATE: 0,
      NAME: 'Ömer Uluç',
      NAME_UPPERCASE: 'ÖMER ULUÇ',
      TOPIC: 'About Ömer Uluç',
      TOPIC_UPPERCASE: 'ABOUT ÖMER ULUÇ',
      ARTWORKS: 'Ömer Uluç\'s Artworks',
      ARTWORKS_UPPERCASE: 'ÖMER ULUÇ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/OMER_ULUC/Omer_Uluc.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/OMER_ULUC/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/OMER_ULUC/-1.m.webp',
      TEXT: 'Born in 1931, Ömer Uluç is a world-renowned Turkish painter.' +
        ' He opened more than 30 personal exhibitions in different countries and impressed the whole world with the works he presented in the organizations he participated in.<br>' +
        'Uluç, who stands out with the value he added to Turkish art, studied engineering in the United States after graduating from Robert College in 1953.' +
        ' Joining the ‘Tavanarası Ressamları’ during his education was one of the turning points of his life.<br>' +
        'The crossing of his paths with Nuri İyem, who was against academia and adopted a more liberal style in the formation called Tavanarası Ressamları, also shaped his art life.' +
        ' He made his first paintings largely in this formation.<br>' +
        'The artist has dealt with various issues in his career and has had an adventurous identity in every period of his life.' +
        ' Ömer Uluç takes risk in every painting. This was the point where he combined his love of painting with his pursuit of adventure.' +
        ' The artist, who made dozens of paintings containing different scenes and colors of nature and life, was as excited as he was while exhibiting these paintings.<br>' +
        'One of the most striking details in Ömer Uluç\'s painting is the concrete materials he uses.' +
        ' He has the ability to create various figures from all kinds of objects, from hoses to tires, from industrial materials to bendable materials.' +
        ' It is possible to find traces of this approach in many of his recent exhibitions.<br>' +
        'Ömer Uluç, who spent his last days in Istanbul and noted the words of Lucretius: “Where death is, there is no death where I am” next to his charcoal self-portrait in his last exhibition titled “The Chemistry of Disintegration”, Ömer Uluç has been undergoing treatment for severe lung cancer for two years.' +
        ' It never stopped its production. He died on January 28, 2010, at the age of 79, in Istanbul, leaving hundreds of works of art behind.<sup>[1]</sup>',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://tr.wikipedia.org/wiki/Ömer_Uluç</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/OMER_ULUC/0.d.webp', DESCRIPTION: '"İki Figür ve Kuş" 75x75 cm, OPOC, 1993'},
        {
          SRC: './assets/announcements/OMER_ULUC/1.d.webp',
          DESCRIPTION: '"Two Creatures-Portrait of Pink robot" 127x125 cm, APOC, 1994'
        },
        {SRC: './assets/announcements/OMER_ULUC/2.d.webp', DESCRIPTION: '"African Queen" 100x100 cm, OPOC, 1989'},
        {SRC: './assets/announcements/OMER_ULUC/3.d.webp', DESCRIPTION: '"Creature and Woman" 150x150 cm, APOC, 1995'},
        {SRC: './assets/announcements/OMER_ULUC/4.d.webp', DESCRIPTION: '"Nude" 100x78 cm, APOC, 2003'},
        {SRC: './assets/announcements/OMER_ULUC/5.d.webp', DESCRIPTION: '"Dört Kuş" 98x98 cm, OPOC, 1993'},
        {SRC: './assets/announcements/OMER_ULUC/6.d.webp', DESCRIPTION: '"Lady and Monster" 100x100 cm, OPOC, 1987'},
        {SRC: './assets/announcements/OMER_ULUC/7.d.webp', DESCRIPTION: '"Mavi Kuş" 146x114 cm, APOC, 1997'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/OMER_ULUC/0.m.webp', DESCRIPTION: '"İki Figür ve Kuş" 75x75 cm, OPOC, 1993'},
        {
          SRC: './assets/announcements/OMER_ULUC/1.m.webp',
          DESCRIPTION: '"Two Creatures-Portrait of Pink robot" 127x125 cm, APOC, 1994'
        },
        {SRC: './assets/announcements/OMER_ULUC/2.m.webp', DESCRIPTION: '"African Queen" 100x100 cm, OPOC, 1989'},
        {SRC: './assets/announcements/OMER_ULUC/3.m.webp', DESCRIPTION: '"Creature and Woman" 150x150 cm, APOC, 1995'},
        {SRC: './assets/announcements/OMER_ULUC/4.m.webp', DESCRIPTION: '"Nude" 100x78 cm, APOC, 2003'},
        {SRC: './assets/announcements/OMER_ULUC/5.m.webp', DESCRIPTION: '"Dört Kuş" 98x98 cm, OPOC, 1993'},
        {SRC: './assets/announcements/OMER_ULUC/6.m.webp', DESCRIPTION: '"Lady and Monster" 100x100 cm, OPOC, 1987'},
        {SRC: './assets/announcements/OMER_ULUC/7.m.webp', DESCRIPTION: '"Mavi Kuş" 146x114 cm, APOC, 1997'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="http://www.artnet.com/artists/%C3%B6mer-ulu%C3%A7-2/" target="_blank">' +
        'http://www.artnet.com/artists/%C3%B6mer-ulu%C3%A7-2/</a>',
      ]
    },
    {
      ID: 14,
      TEMPLATE: 0,
      NAME: 'Nuri İyem',
      NAME_UPPERCASE: 'NURİ İYEM',
      TOPIC: 'About Nuri İyem',
      TOPIC_UPPERCASE: 'ABOUT NURİ İYEM',
      ARTWORKS: 'Nuri İyem\'s Artworks',
      ARTWORKS_UPPERCASE: 'NURİ İYEM\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/NURI_IYEM/Nuri_Iyem.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/NURI_IYEM/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/NURI_IYEM/-1.m.webp',
      TEXT: 'Inspired by the eyes and face of his older sister, whom he witnessed as a child,' +
        ' artist Nuri İyem is a painter of \'shy, beautiful and melancholic\' faces that have become an iconic and integral part of his art.<br>' +
        'Nuri İyem was born in Aksaray, Istanbul in 1915 as the seventh and last child of civil servant Hüseyin Hüsnü Bey and housewife Melek Hanım.' +
        ' Three years later, the İyem family moved to Cizre, Mardin, in southeastern Turkey, due to İyem\'s father\'s job.' +
        ' İyem lost his  older sister Aliye in 1922, whose eyes and looks would leave her mark on his art.' +
        ' His sister\'s death affected him deeply.<br>' +
        'Nuri İyem, who could not live without painting, spent most of his time painting.' +
        ' Instead of studying for his classes, he would also paint at night.' +
        ' Encouraged by Nazmi Ziya Güran, who saw his paintings, he decided to leave her education unfinished. He entered Istanbul Fine Arts Academy.' +
        ' He completed the first year of his education at Nazmi Ziya Güran Atelier.' +
        ' He took his theoretical lessons from Ahmet Hamdi Tanpınar.<br>' +
        'Nuri Iyem started working in Hikmet Onat Atelier in 1935 and in İbrahim Çallı Atelier in 1936.' +
        ' After a short time, he moved to the Leopold Levy Atelier.' +
        ' The famous painter graduated from the Academy of Fine Arts as the first.<br>' +
        'He is known for his portraits of Anatolian women.<br>' +
        'Women\'s faces, which Ahmet Hamdi Tanpınar calls "tight as a statue, as elegant as the light of the green moonlight,' +
        ' as plain as old frescoes and icons that carry the atmosphere of the past," are the product of a period' +
        ' in which migration from the village to the city intensified and the social rights of the individual were against women.' +
        ' With their shy, beautiful, timid and melancholic states,' +
        ' these faces are a crystallized example of Nuri İyem\'s art as both the imaginary image of her deceased sister and an iconic symbol that transcends time.' +
        ' The women who work and extract their labor from the soil were symbolized in the paintings of \'migration\',' +
        ' which the artist made at the same time and in which he approached the Anatolian reality with a nationalist perspective.<sup>[1]</sup><br>' +
        'Nuri İyem is the most well-known painter in Turkey with 3,500 works that are archived,' +
        ' registered and recorded in more than a thousand collections, and whose works are in different collections.',
      TEXT_LIMIT_DESKTOP: 1144,
      TEXT_LIMIT_MOBILE: 1144,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://tr.wikipedia.org/wiki/Nuri_%C4%B0yem</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/NURI_IYEM/0.d.webp', DESCRIPTION: '"Toprak Ana", OPOC, 1972'},
        {SRC: './assets/announcements/NURI_IYEM/1.d.webp', DESCRIPTION: '"Üç Güzeller" 120x80 cm, OPOC, 1970’s'},
        {SRC: './assets/announcements/NURI_IYEM/2.d.webp', DESCRIPTION: '"Portre" 50x45 cm, OPOC, 1997'},
        {
          SRC: './assets/announcements/NURI_IYEM/3.d.webp',
          DESCRIPTION: '"Mustafa Kemal Atatürk" 48x37.5 cm, OPOC, 1999'
        },
        {SRC: './assets/announcements/NURI_IYEM/4.d.webp', DESCRIPTION: '"Portre" 60x65cm, OPOD, 1999'},
        {
          SRC: './assets/announcements/NURI_IYEM/5.d.webp',
          DESCRIPTION: '"Adalet Cimcoz’un Portresi" 65x75cm, OPOD, 1952'
        },
        {SRC: './assets/announcements/NURI_IYEM/6.d.webp', DESCRIPTION: '"Bosnalı Müslüman" 25x31cm, OPOC, 1970’s '},
        {
          SRC: './assets/announcements/NURI_IYEM/7.d.webp',
          DESCRIPTION: '"Ümit İyem’in Portresi" 32.5x38.5cm, OPOD, 1970’s '
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/NURI_IYEM/0.m.webp', DESCRIPTION: '"Toprak Ana", OPOC, 1972'},
        {SRC: './assets/announcements/NURI_IYEM/1.m.webp', DESCRIPTION: '"Üç Güzeller" 120x80 cm, OPOC, 1970’s'},
        {SRC: './assets/announcements/NURI_IYEM/2.m.webp', DESCRIPTION: '"Portre" 50x45 cm, OPOC, 1997'},
        {
          SRC: './assets/announcements/NURI_IYEM/3.m.webp',
          DESCRIPTION: '"Mustafa Kemal Atatürk" 48x37.5 cm, OPOC, 1999'
        },
        {SRC: './assets/announcements/NURI_IYEM/4.m.webp', DESCRIPTION: '"Portre" 60x65cm, OPOD, 1999'},
        {
          SRC: './assets/announcements/NURI_IYEM/5.m.webp',
          DESCRIPTION: '"Adalet Cimcoz’un Portresi" 65x75cm, OPOD, 1952'
        },
        {SRC: './assets/announcements/NURI_IYEM/6.m.webp', DESCRIPTION: '"Bosnalı Müslüman" 25x31cm, OPOC, 1970’s '},
        {
          SRC: './assets/announcements/NURI_IYEM/7.m.webp',
          DESCRIPTION: '"Ümit İyem’in Portresi" 32.5x38.5cm, OPOD, 1970’s '
        },
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="http://www.nuriiyem.com/tr/eserleri" target="_blank">http://www.nuriiyem.com/tr/eserleri</a>',
      ]
    },
    {
      ID: 15,
      TEMPLATE: 0,
      NAME: 'Mustafa Ata',
      NAME_UPPERCASE: 'MUSTAFA ATA',
      TOPIC: 'About Mustafa Ata',
      TOPIC_UPPERCASE: 'ABOUT MUSTAFA ATA',
      ARTWORKS: 'Mustafa Ata\'s Artworks',
      ARTWORKS_UPPERCASE: 'MUSTAFA ATA\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/MUSTAFA_ATA/Mustafa_Ata.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/MUSTAFA_ATA/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/MUSTAFA_ATA/-1.m.webp',
      TEXT: 'Mustafa Ata, born in Trabzon on March 18, 1945,' +
        ' studied at the Adnan Çoker Studio of the Painting Department of Mimar Sinan Fine Arts Faculty (then Istanbul State Academy of Fine Arts).<sup>[1]</sup><br>' +
        'Mustafa Ata, who has been painting since 1966, participated in exhibitions in Munich, Kassel, Copenhagen, Tokyo, Darmstadt, Philadelphia and Cairo.' +
        ' At the same time, he made researches in art centers of different European countries.' +
        ' His book "Synthetic Plastic Materials, Forming Methods, Their Use in Art" was published in 1978.<sup>[2]</sup><br>' +
        'The artist, who is one of the masters of Turkish Painting Art, also has an international reputation.' +
        ' In his works, Ata goes beyond the usual patterns, classical forms and colors and mix movement and mass together with lines and tones.' +
        ' He reveals this feature effectively with the figures he created with original brush strokes.' +
        ' The compositions, in which the form was at the forefront,' +
        ' shifted towards a new expression in which the form was almost kept in the background with the long years of work and experience, but color is an inevitable element.<br>' +
        'The artist, whose works are in Ankara, Izmir, Istanbul Painting and Sculpture museums, and in Private and Official collections in Turkey and abroad,' +
        ' continues his life and works in Istanbul and works as a lecturer at Yeditepe University Fine Arts Faculty.',
      TEXT_LIMIT_DESKTOP: 1150,
      TEXT_LIMIT_MOBILE: 1180,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://artam.com/muzayede/317-online-muzayede/mustafa-ata-1945-vlam</li>' +
        '   <li>https://artam.com/muzayede/317-online-muzayede/mustafa-ata-1945-vlam</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/MUSTAFA_ATA/0.d.webp', DESCRIPTION: 'Untitled 165x205 cm, OAPOC, 2005'},
        {SRC: './assets/announcements/MUSTAFA_ATA/1.d.webp', DESCRIPTION: 'Untitled 150x180 cm, OAPOC, 2011'},
        {SRC: './assets/announcements/MUSTAFA_ATA/2.d.webp', DESCRIPTION: 'Untitled 150x180 cm, OAPOC, 2011'},
        {SRC: './assets/announcements/MUSTAFA_ATA/3.d.webp', DESCRIPTION: 'Untitled 90x110 cm, OAPOC, 1997'},
        {SRC: './assets/announcements/MUSTAFA_ATA/4.d.webp', DESCRIPTION: 'Untitled 130x180 cm, OAPOC, 2011'},
        {
          SRC: './assets/announcements/MUSTAFA_ATA/5.d.webp',
          DESCRIPTION: '"Abdi İpekçi\'ye Saygı" 50x70 cm, OPOC, 1998'
        },
        {
          SRC: './assets/announcements/MUSTAFA_ATA/6.d.webp',
          DESCRIPTION: '"İlksiz ve Sonsuz MATA No:4" 50x60 cm, MMOC, 2012'
        },
        {SRC: './assets/announcements/MUSTAFA_ATA/7.d.webp', DESCRIPTION: 'Untitled 140x170 cm, MMOC, 2007'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/MUSTAFA_ATA/0.m.webp', DESCRIPTION: 'Untitled 165x205 cm, OAPOC, 2005'},
        {SRC: './assets/announcements/MUSTAFA_ATA/1.m.webp', DESCRIPTION: 'Untitled 150x180 cm, OAPOC, 2011'},
        {SRC: './assets/announcements/MUSTAFA_ATA/2.m.webp', DESCRIPTION: 'Untitled 150x180 cm, OAPOC, 2011'},
        {SRC: './assets/announcements/MUSTAFA_ATA/3.m.webp', DESCRIPTION: 'Untitled 90x110 cm, OAPOC, 1997'},
        {SRC: './assets/announcements/MUSTAFA_ATA/4.m.webp', DESCRIPTION: 'Untitled 130x180 cm, OAPOC, 2011'},
        {
          SRC: './assets/announcements/MUSTAFA_ATA/5.m.webp',
          DESCRIPTION: '"Abdi İpekçi\'ye Saygı" 50x70 cm, OPOC, 1998'
        },
        {
          SRC: './assets/announcements/MUSTAFA_ATA/6.m.webp',
          DESCRIPTION: '"İlksiz ve Sonsuz MATA No:4" 50x60 cm, MMOC, 2012'
        },
        {SRC: './assets/announcements/MUSTAFA_ATA/7.m.webp', DESCRIPTION: 'Untitled 140x170 cm, MMOC, 2007'},
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="http://gulersanat.com/mustafa-ata/" target="_blank">http://gulersanat.com/mustafa-ata/</a>',
        '<a class="gray-underline" href="https://www.aysilmuzayede.com/en/product/4215785/mustafa-ata" target="_blank">https://www.aysilmuzayede.com/en/product/4215785/mustafa-ata</a>',
        '<a class="gray-underline" href="https://www.devmuzayede.com/urun/3706619/mustafa-ata" target="_blank">https://www.devmuzayede.com/urun/3706619/mustafa-ata</a>',
        '<a class="gray-underline" href="https://www.aysilmuzayede.com/urun/4056115/mustafa-ata" target="_blank">https://www.aysilmuzayede.com/urun/4056115/mustafa-ata</a>',
      ]
    },
    {
      ID: 16,
      TEMPLATE: 0,
      NAME: 'Özdemir Altan',
      NAME_UPPERCASE: 'ÖZDEMİR ALTAN',
      TOPIC: 'About Özdemir Altan',
      TOPIC_UPPERCASE: 'ABOUT ÖZDEMİR ALTAN',
      ARTWORKS: 'Özdemir Altan\'s Artworks',
      ARTWORKS_UPPERCASE: 'ÖZDEMİR ALTAN\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/OZDEMIR_ALTAN/Ozdemir_Altan.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/OZDEMIR_ALTAN/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/OZDEMIR_ALTAN/-1.m.webp',
      TEXT: 'Özdemir Altan, born in Giresun in 1931, graduated from Istanbul State Academy of Fine Arts in 1956.' +
        ' Following his graduation in 1956, he began working as an academician at the same school and continued in this position until his retirement in 1988.' +
        ' As a prominent artist, he pioneered avant-garde thinking, Pop art, and postmodernism in Turkey.<sup>[1]</sup>' +
        ' Altan participated in numerous national and international events such as the Paris Biennials between 1953 and 1956.' +
        ' In 1966, he received the "Most Successful Young Painter of the Year" award from the Turkish Contemporary Painters Society.<sup>[2]</sup>' +
        ' Later, in 1969, he won the first prize in the competition organized by TRT Istanbul Radio for decorating the foyer wall with his wall carpet design, a project he brought to life in 1974.' +
        ' Throughout his artistic career, he participated in exhibitions held in various European cities such as Rome, Skopje, Bucharest, Belgrade, Moscow, Paris, and London; giving his art an international dimension.<br>' +
        'In addition to his academic and artistic endeavors in the contemporary art movement, he also made significant contributions to the Turkish art scene as a curator.' +
        ' In this regard, Altan\'s Avni Lifij exhibition from 1968 is one of the earliest curatorial attempts in Turkey.<sup>[3]</sup>' +
        ' Following this, numerous exhibitions of young artists such as Zeki Faik, Mahmut Cuda, and Bedri Rahmi were organized by Altan.<sup>[4]</sup><br>' +
        'The "coincidental encounter method" adopted by Özdemir Altan is a product of his works between 1988 and 1992.' +
        ' The renowned artist thoroughly examined the concept of space in painting and extensively explored this concept in his classes at the Academy.' +
        ' His conclusion on this subject can be summarized as "art is created by the merging of different concepts, origins, structures, and logics."<sup>[5]</sup><br>' +
        'After retiring from his position as a studio instructor at the Academy of Fine Arts in 1988, the artist produced significant works during different periods such as the Romantic Period, Kings and Queens, and Piano Studies with Many People.<sup>[6]</sup><br>' +
        'Altan has made important contributions to Turkish art as an artist, academician, curator, and writer.' +
        ' He continues to live and work in Istanbul. His artworks are exhibited in the Painting and Sculpture Museums and Galleries in Ankara, Izmir, and Erzurum.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>“Modern Turkish Art: Özdemir Altan.” Modern Turkish art: OZDEMIR ALTAN. http://ozdemiraltan.com</li>' +
        '   <li>Salt research: Özdemir Altan. Accessed July 24, 2023. https://archives.saltresearch.org/handle/123456789/41863</li>' +
        '   <li>Özdemir Altan .” Ankara Resim ve Heykel Müzesi. https://arhm.ktb.gov.tr/artists/detail/2050/ozdemir-altan-1931-...</li>' +
        '   <li>Modern Turkish Art: Özdemir Altan.” Modern Turkish art: OZDEMIR ALTAN. http://ozdemiraltan.com</li>' +
        '   <li>Özdemir Altan .” Ankara Resim ve Heykel Müzesi. https://arhm.ktb.gov.tr/artists/detail/2050/ozdemir-altan-1931-...</li>' +
        '   <li>Özdemir Altan. https://sanat.terakki.org.tr/koleksiyon/ozdemir-altan/</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/0.d.webp',
          DESCRIPTION: 'Although he is a well-read king, he is disliked by his people, 1965, mixed materials, 40 x 30 cm'
        },
        {SRC: './assets/announcements/OZDEMIR_ALTAN/1.d.webp', DESCRIPTION: 'Players, 1964, gouache, 84 x 64 cm'},
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/2.d.webp',
          DESCRIPTION: 'Family Tree, 1991, Oil on canvas, 130 x 194 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/3.d.webp',
          DESCRIPTION: 'Family Tree 1993, Acrylic on canvas, 81 x 100 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/4.d.webp',
          DESCRIPTION: 'New Period, 2004, acrylic on canvas, 73 x 100 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/5.d.webp',
          DESCRIPTION: 'Altan and A. Arpat 1999, Mixed techniques, 162 x 130 cm'
        },
      ],
      ARTWORKS_LIST_MOBILE: [
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/0.m.webp',
          DESCRIPTION: 'Although he is a well-read king, he is disliked by his people, 1965, mixed materials, 40 x 30 cm'
        },
        {SRC: './assets/announcements/OZDEMIR_ALTAN/1.m.webp', DESCRIPTION: 'Players, 1964, gouache, 84 x 64 cm'},
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/2.m.webp',
          DESCRIPTION: 'Family Tree, 1991, Oil on canvas, 130 x 194 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/3.m.webp',
          DESCRIPTION: 'Family Tree 1993, Acrylic on canvas, 81 x 100 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/4.m.webp',
          DESCRIPTION: 'New Period, 2004, acrylic on canvas, 73 x 100 cm'
        },
        {
          SRC: './assets/announcements/OZDEMIR_ALTAN/5.m.webp',
          DESCRIPTION: 'Altan and A. Arpat 1999, Mixed techniques, 162 x 130 cm'
        },
      ],
      ARTWORK_REFERENCES: [
        '<a class="gray-underline" href="http://www.ozdemiraltan.com/" target="_blank">http://www.ozdemiraltan.com/</a>',
      ]
    },
    {
      ID: 17,
      TEMPLATE: 0,
      NAME: 'Ahmet Yeşil',
      NAME_UPPERCASE: 'AHMET YEŞİL',
      TOPIC: 'About Ahmet Yeşil',
      TOPIC_UPPERCASE: 'ABOUT AHMET YEŞİL',
      ARTWORKS: 'Ahmet Yeşil\'s Artworks',
      ARTWORKS_UPPERCASE: 'AHMET YEŞİL\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/AHMET_YESIL/Ahmet_Yesil.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/AHMET_YESIL/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/AHMET_YESIL/-1.m.webp',
      TEXT: 'Ahmet Yeşil was born in Mersin in 1954 and grew up in this city. He received his artistic education from talented artists such as Painter Nuri Abaç, İlhan Çevik, and Sculptor Ernür Tüzün.' +
        ' While the artist\'s works are included in many private collections in Turkey, they have been represented in foreign collections in Germany, America, Canada, the Netherlands, and England.<br>' +
        'Ahmet Yeşil continued his artistic career as a member of UNICEF International Plastic Arts Association.' +
        ' In addition to opening 141 personal exhibitions, the artist has shown a significant presence in the national and international arena by participating in 315 group and competition exhibitions.<br>' +
        'Different periods and themes stand out in Yeşil’s works. Yeşil, who had an impressionist style in his early years, focused on social themes such as women and children in his period called the "Figure Period".' +
        ' Later, influenced by his mother being a seamstress and his connection to the sea, he frequently used rope and rope motifs in his paintings where he frequently used the sea as the subject.' +
        ' This motif is considered a reflection of the artist\'s inner world and adds depth to his works.<br>' +
        'In his paintings, Yeşil expressed different dimensions of social, political, economic, ecological, and personal life through the objective structure of the rope.' +
        ' In this way, art emerged as a tool of liberation with a deep and meaningful expressive power.<br>' +
        'The artist has won a total of 24 awards in national and international competitions. He lives in Mersin and continues his work in his atelier.' +
        ' Ahmet Yeşil is an original artist who brings important innovations to Turkish and world art.' +
        ' The artworks are far from decorative, with a style defined by sincerity and simplicity, and represent a new artistic language.' +
        ' The artist\'s works inspire art lovers by emphasizing the power of art and freedom of expression.<br>' +
        'Ahmet Yeşil\'s works offer emotional depth and intellectual richness by using the universal language of art.' +
        ' The artist, who masterfully handled the rope and sea themes, made significant contributions to Turkish art by adopting an abstract approach.' +
        ' His works bear the traces of an artistic journey full of change and innovation, woven with simplicity and sincerity.' +
        ' Ahmet Yeşil, as one of the leading figures of Turkish and world art, continues to expand the boundaries of art and enrich the power of expression.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://www.alem.com.tr/sanatcilar/ahmet-yesil-hakkinda-merak-edilenler-1073893</li>' +
        '   <li>https://www.alem.com.tr/sanatcilar/ahmet-yesil-hakkinda-merak-edilenler-1073893 </li>' +
        '   <li>https://www.galerisoyut.com.tr/artist/ahmet-yesil/</li>' +
        '   <li>https://dspace.trakya.edu.tr/xmlui/handle/trakya/1716</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/AHMET_YESIL/0.d.webp', DESCRIPTION: 'Aşkın Diyalektiği 2 150x110'},
        {
          SRC: './assets/announcements/AHMET_YESIL/1.d.webp',
          DESCRIPTION: 'Figür-Yorum-I, 90x120, Oil Painting On Canvas'
        },
        {SRC: './assets/announcements/AHMET_YESIL/2.d.webp', DESCRIPTION: 'İçsel Dokunmalar Üzerine, 160x120'},
        {SRC: './assets/announcements/AHMET_YESIL/3.d.webp', DESCRIPTION: 'Sağınak, 100x50 cm'},
        {SRC: './assets/announcements/AHMET_YESIL/4.d.webp', DESCRIPTION: 'Son(uç), 100x200'},
        {SRC: './assets/announcements/AHMET_YESIL/5.d.webp', DESCRIPTION: 'Van Gogh ve Ben, 120x150'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/AHMET_YESIL/0.m.webp', DESCRIPTION: 'Aşkın Diyalektiği 2 150x110'},
        {
          SRC: './assets/announcements/AHMET_YESIL/1.m.webp',
          DESCRIPTION: 'Figür-Yorum-I, 90x120, Oil Painting On Canvas'
        },
        {SRC: './assets/announcements/AHMET_YESIL/2.m.webp', DESCRIPTION: 'İçsel Dokunmalar Üzerine, 160x120'},
        {SRC: './assets/announcements/AHMET_YESIL/3.m.webp', DESCRIPTION: 'Sağınak, 100x50 cm'},
        {SRC: './assets/announcements/AHMET_YESIL/4.m.webp', DESCRIPTION: 'Son(uç), 100x200'},
        {SRC: './assets/announcements/AHMET_YESIL/5.m.webp', DESCRIPTION: 'Van Gogh ve Ben, 120x150'},
      ],
      ARTWORK_REFERENCES: [
        'All: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ahmet-yesil/" target="_blank">https://www.galerisoyut.com.tr/artist/ahmet-yesil/</a>',
      ]
    },
    {
      ID: 18,
      TEMPLATE: 0,
      NAME: 'Mehmet Güleryüz',
      NAME_UPPERCASE: 'MEHMET GÜLERYÜZ',
      TOPIC: ' About Mehmet GÜleryüz',
      TOPIC_UPPERCASE: 'ABOUT MEHMET GÜLERYÜZ ',
      ARTWORKS: 'Mehmet GÜleryüz\'s Artworks',
      ARTWORKS_UPPERCASE: 'MEHMET GÜLERYÜZ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/MEHMET_GULERYUZ/Mehmet_Guleryuz.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/MEHMET_GULERYUZ/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/MEHMET_GULERYUZ/-1.m.webp',
      TEXT: 'Mehmet Güleryüz was born in Istanbul in 1938. ' +
        'He completed his primary and secondary education in Istanbul, and then entered the Painting Department of the Istanbul State Academy of Fine Arts in 1958. ' +
        'Güleryüz graduated with honors in 1966, and began a career in professional acting the same year. ' +
        'In the 1970s, Güleryüz received a state scholarship to study painting and lithography in Paris. ' +
        'He also continued his studies in sculpture and theater during this time. ' +
        'Upon his return to Turkey in 1975, Güleryüz continued to work in painting, sculpture, and theater. ' +
        'In the 1980s, he began to focus more on figurative painting and scenes from everyday life. ' +
        'During this time, he also adopted a more simplictic style of art. Güleryüz is considered one of the most important contemporary Turkish painters. ' +
        'His work is exhibited in museums and collections around the world, including Turkey. ' +
        'Güleryüz\'s paintings often feature human figures. His work reflects the emotions of ordinary people, such as happiness, sadness, and anger. ' +
        'Güleryüz\'s paintings are characterized by a simplistic style. ' +
        'The artist depicts the human condition and the situations that arise from these conditions from a critical and expressionist perspective. ' +
        'He makes the figures clear in an uncertain space and leaves them to the interpretation of the viewer. ' +
        'His figures are often found in scenes from everyday life. ' +
        'For example, his painting "Çöp Masalları" (Trash Tales) tells the stories of people living in the streets of Istanbul. ' +
        'His painting "Kahveci" (The Coffee Maker) offers a glimpse into everyday life in Istanbul. Güleryüz\'s paintings have a wide following in Turkey and abroad. ' +
        'His work is exhibited in many important museums and collections. ' +
        'The artist has been awarded numerous prizes throughout his career, including: First Prize, Painting Department of the Istanbul State Academy of Fine Arts in 1966, Second Prize, International Competition in Paris in 1970,' +
        ' Sedat Simavi Art Award in 1989 and State Artist in 2003. ' +
        'He passed away in 2024.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ</li>' +
        '   <li>https://www.iskultur.com.tr/yazarlar/mehmet-guleryuz</li>' +
        '   <li>https://adasanat.com.tr/portfolio_page/ressam-ve-resim-mehmet-guleryuz-retrospektifi</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/MEHMET_GULERYUZ/0.d.webp', DESCRIPTION: '2 Juin 2020 Paris, 73x60'},
        {
          SRC: './assets/announcements/MEHMET_GULERYUZ/1.d.webp',
          DESCRIPTION: '9 Mai 2019 Paris - Le Premier Harnais, 27x19'
        },
        {SRC: './assets/announcements/MEHMET_GULERYUZ/2.d.webp', DESCRIPTION: '11 September 2023 Paris, 65x50'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/3.d.webp', DESCRIPTION: '18 Janvier 2020 Paris, 27x22 '},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/4.d.webp', DESCRIPTION: '21 Aout 2020 Paris, 65x54'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/5.d.webp', DESCRIPTION: '30 Novembre 2020 Paris, 92x73'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/6.d.webp', DESCRIPTION: 'Mistake, 162x130'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/MEHMET_GULERYUZ/0.m.webp', DESCRIPTION: '2 Juin 2020 Paris, 73x60'},
        {
          SRC: './assets/announcements/MEHMET_GULERYUZ/1.m.webp',
          DESCRIPTION: '9 Mai 2019 Paris - Le Premier Harnais, 27x19'
        },
        {SRC: './assets/announcements/MEHMET_GULERYUZ/2.m.webp', DESCRIPTION: '11 September 2023 Paris, 65x50'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/3.m.webp', DESCRIPTION: '18 Janvier 2020 Paris, 27x22 '},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/4.m.webp', DESCRIPTION: '21 Aout 2020 Paris, 65x54'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/5.m.webp', DESCRIPTION: '30 Novembre 2020 Paris, 92x73'},
        {SRC: './assets/announcements/MEHMET_GULERYUZ/6.m.webp', DESCRIPTION: 'Mistake, 162x130'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ" target="_blank">https://web.archive.org/web/20151125212809/http://sanalmuze.tcmb.gov.tr/sanalmuze/tr/sanat-koleksiyonu/s/159/MEHMET+GULERYUZ</a>',
      ]
    },
    {
      ID: 19,
      TEMPLATE: 0,
      NAME: 'Selim Turan',
      NAME_UPPERCASE: 'SELİM TURAN',
      TOPIC: 'About Selim Turan',
      TOPIC_UPPERCASE: 'ABOUT SELİM TURAN',
      ARTWORKS: 'Selim Turan\'s Artworks',
      ARTWORKS_UPPERCASE: 'SELİM TURAN\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/SELIM_TURAN/Selim_Turan.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SELIM_TURAN/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/SELIM_TURAN/-1.m.webp',
      TEXT: 'Born in 1915 in Istanbul, in the Cağaloğlu district, Selim Turan was the son of Prof. Dr. Hüseyinzade Ali Turan of Azeri-Caucasian origin and Edhiye Hanım, the daughter of Çerkez Süvari Zabiti Şemsettin Bey. Growing up in a family with a rich cultural heritage, Turan was influenced by his father\'s approach of interpreting Western and Eastern cultures.\n' +
        'In 1925, he moved to Galatasaray Lisesi in the third grade after completing primary school. After graduating from Galatasaray Lisesi, he entered the Istanbul State Academy of Fine Arts in 1935. He completed his studies there in 1938, winning a competition that allowed him to conduct various observations and studies in Europe. He also took courses in calligraphy and illumination. In 1947, with a scholarship from the French government, Turan went to Paris, where he lived for many years, further developing his art. During this period, he participated in exhibitions such as Salon de Mai and Yeni Gerçeklikler and continued his education at institutions like Ranson Academy, Fontainebleu School of Art, and Goetz Academy.\n' +
        'Selim Turan was not only involved in painting but also explored sculpture. In the 1950s, he became one of the leading representatives of the abstract-expressionist movement in Turkish painting. Particularly known for his vertical nan-figurative, abstract expressionist compositions, he created a unique style by incorporating dynamic and vibrant colors. At times, his paintings hinted at a figurative abstraction.\n' +
        'Turan\'s works reflect an art philosophy influenced by his education and observations in Europe. His creations represent an original approach that merges Turkish artistic traditions with a modern and contemporary perspective.\n' +
        'Recognized for his large-scale murals, frescoes, ceramics, outdoor sculptures, and numerous honors, Selim Turan left a significant mark on the art world. He received the first prize at the 10th Anniversary Exhibition of Halkevleri Yurtiçi Gezileri and was awarded the \'Honorary Certificate\' by the Plastic Arts Association in 1991. That same year, he made significant contributions to the establishment of a private Ethnography Gallery in Türkmen Tahtakuşlar Village.\n' +
        'In 1991, Turan participated in doctoral juries at Sorbonne University in France, earning the title of professor. Unfortunately, in 1994, he passed away in Paris. His remains were brought to Turkey and laid to rest in Istanbul. Selim Turan\'s legacy lives on through his impactful contributions to Turkish art and his unique artistic vision.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://www.sakipsabancimuzesi.org/sanatci/306</li>' +
        '   <li>https://artam.com/makaleler/sanatci/selim-turanin-sanat-felsefesini-yeniden-degerlendirmek</li>' +
        '   <li>https://arhm.ktb.gov.tr/catalogs/detail/2196/selim-turan-in-sanatindan-kesitler</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/SELIM_TURAN/0.d.webp', DESCRIPTION: 'Untitled, 198x71'},
        {SRC: './assets/announcements/SELIM_TURAN/1.d.webp', DESCRIPTION: 'Kompozisyon, 24x43'},
        {SRC: './assets/announcements/SELIM_TURAN/2.d.webp', DESCRIPTION: 'Natürmort, 80x60'},
        {SRC: './assets/announcements/SELIM_TURAN/3.d.webp', DESCRIPTION: 'Untitled, 198x71 '},
        {SRC: './assets/announcements/SELIM_TURAN/4.d.webp', DESCRIPTION: 'Untitled, 50x65'},
        {SRC: './assets/announcements/SELIM_TURAN/5.d.webp', DESCRIPTION: 'Kompozisyon, 119x274'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/SELIM_TURAN/0.m.webp', DESCRIPTION: 'Untitled, 198x71'},
        {SRC: './assets/announcements/SELIM_TURAN/1.m.webp', DESCRIPTION: 'Kompozisyon, 24x43'},
        {SRC: './assets/announcements/SELIM_TURAN/2.m.webp', DESCRIPTION: 'Natürmort, 80x60'},
        {SRC: './assets/announcements/SELIM_TURAN/3.m.webp', DESCRIPTION: 'Untitled, 198x71 '},
        {SRC: './assets/announcements/SELIM_TURAN/4.m.webp', DESCRIPTION: 'Untitled, 50x65'},
        {SRC: './assets/announcements/SELIM_TURAN/5.m.webp', DESCRIPTION: 'Kompozisyon, 119x274'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://www.sakipsabancimuzesi.org/sanatci/306" target="_blank">https://www.sakipsabancimuzesi.org/sanatci/306<a>',
      ]
    },
    {
      ID: 20,
      TEMPLATE: 0,
      NAME: 'Şahin Paksoy',
      NAME_UPPERCASE: 'ŞAHİN PAKSOY',
      TOPIC: 'About Şahin Paksoy',
      TOPIC_UPPERCASE: 'ABOUT ŞAHİN PAKSOY',
      ARTWORKS: 'Şahin Paksoy\'s Artworks',
      ARTWORKS_UPPERCASE: 'ŞAHİN PAKSOY\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/SAHIN_PAKSOY/Sahin_Paksoy.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/SAHIN_PAKSOY/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/SAHIN_PAKSOY/-1.m.webp',
      TEXT: 'Şahin Paksoy, born in 1952, is a distinct figure in Turkish painting and ceramics. He began his art education in 1973 at the Ceramics Department of the Istanbul State Academy of Fine Arts, graduating in 1980. His interest in collecting archaeological artifacts during his student years significantly influenced his later artistic style.\n' +
        'Paksoy opened his first solo exhibition in 1980 at the Galata Art Gallery in Istanbul and has participated in numerous group and solo exhibitions both domestically and internationally, garnering widespread acclaim. In his creations, Paksoy draws inspiration from traditional Turkish arts such as Ottoman miniatures, Byzantine icons, and Seljuk carpets. This influence has helped him forge a unique style within contemporary art.\n' +
        'His figurative paintings and small-scale ceramic sculptures often depict people he encounters in everyday life and the lifestyle and values of traditional Anatolian people. Paksoy, who works with various mediums like oil paint and ceramics, is also known for his abstract works. He continues his artistic endeavors in his studio in Nişantaşı and also contributes to the art world through his collecting.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy</li>' +
        '   <li>https://www.uxmuzayede.com/blog/1726/sahin-paksoy</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/SAHIN_PAKSOY/0.d.webp', DESCRIPTION: 'Park Hayatı, 50x70'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/1.d.webp', DESCRIPTION: 'Untitled, 10x80'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/2.d.webp', DESCRIPTION: 'Figürlü Kompozisyon, 100x130'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/3.d.webp', DESCRIPTION: 'Eminönü Sosyetesi, 90x100'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/4.d.webp', DESCRIPTION: 'Puf Puf Halime, 140x110'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/5.d.webp', DESCRIPTION: 'Kalabalıklar, 90x110'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/6.d.webp', DESCRIPTION: 'Figürler, 50x70'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/7.d.webp', DESCRIPTION: 'Aynalı Çeşme Jazz Dörtlüsü, 110x130'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/SAHIN_PAKSOY/0.m.webp', DESCRIPTION: 'Park Hayatı, 50x70'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/1.m.webp', DESCRIPTION: 'Untitled, 10x80'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/2.m.webp', DESCRIPTION: 'Figürlü Kompozisyon, 100x130'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/3.m.webp', DESCRIPTION: 'Eminönü Sosyetesi, 90x100'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/4.m.webp', DESCRIPTION: 'Puf Puf Halime, 140x110'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/5.m.webp', DESCRIPTION: 'Kalabalıklar, 90x110'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/6.m.webp', DESCRIPTION: 'Figürler, 50x70'},
        {SRC: './assets/announcements/SAHIN_PAKSOY/7.m.webp', DESCRIPTION: 'Aynalı Çeşme Jazz Dörtlüsü, 110x130'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy" target="_blank">https://arts.ozyegin.edu.tr/tr/artist/sahin-paksoy<a>',
      ]
    },
    {
      ID: 21,
      TEMPLATE: 0,
      NAME: 'Burhan Doğançay',
      NAME_UPPERCASE: 'BURHAN DOĞANÇAY',
      TOPIC: 'About Burhan Doğançay',
      TOPIC_UPPERCASE: 'ABOUT BURHAN DOĞANÇAY',
      ARTWORKS: 'Burhan Doğançay\'s Artworks',
      ARTWORKS_UPPERCASE: 'BURHAN DOĞANÇAY\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/BURHAN_DOGANCAY/burhan_dogancay.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/BURHAN_DOGANCAY/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/BURHAN_DOGANCAY/-1.m.webp',
      TEXT: 'Burhan Doğançay was born in Istanbul in 1929. Growing up surrounded by art thanks to his father, the well-known painter and map officer Adil Doğançay, and painter Arif Kaptan, he developed a deep connection with art from an early age. After graduating from the Faculty of Law at Ankara University, he chose art over an academic career, continuing his painting studies while completing his doctorate in economics in Paris. He began his art career by opening exhibitions with his father at the Art Lovers Club in Ankara.\n' +
        '\n' +
        'In 1961, Doğançay had five of his works accepted into the State Painting and Sculpture Exhibition, and in 1962, he moved to New York. There, he was discovered by Thomas Messer, the director of the Guggenheim Museum, and his works were included in the museum\'s collection. Inspired by the dynamic and multicultural environment of New York, he started the \'Walls\' series. This series showcased how city walls changed over time and reflected societal stories. In 1975, he began the \'World Walls\' photography project, which covered 114 countries and was exhibited in 1982 at the Georges Pompidou Center in Paris under the title \'Whispering Walls\'.\n' +
        '\n' +
        'In 1983, Doğançay\'s designs were woven into wall tapestries by the famous carpet center of Aubusson in France. In 1986, his photographs of the restoration process of the Brooklyn Bridge were exhibited at JFK International Airport during New York\'s 100th-anniversary celebrations and were later published in a book titled \'Walls of the World\'. His first retrospective exhibition held at the Dolmabahçe Palace in Istanbul in 2001 and his work \'Respect for Calligraphy\', displayed at the European Parliament in 2003, enhanced his international recognition.\n' +
        '\n' +
        'Burhan Doğançay, who passed away at the age of 84 on January 18, 2013, is remembered as an artist who left a profound impact on the art world, holding an important place in both Turkish and global art history. His works, reflecting the changes of time and society and carrying cultural and social messages, are considered an important legacy.\n',


      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://www.nytimes.com/2013/01/21/arts/design/burhan-dogancay-artist-inspired-by-urban-walls-dies-at-83.html</li>' +
        '   <li>https://www.artopol.com/sayfa/burhan-dogancay-kimdir</li>' +
        '   <li>https://www.istanbulsanatevi.com/turk-ressamlar/burhan-dogancay-1929-2013/</li>' +
        '   <li>https://www.galerisoyut.com.tr/artist/burhan-dogancay/</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/BURHAN_DOGANCAY/0.d.webp', DESCRIPTION: 'Ribbon, 75,8x56,5'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/1.d.webp', DESCRIPTION: 'Shadows on Black, 75x55'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/2.d.webp', DESCRIPTION: 'PIROUETTE, 76x57'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/3.d.webp', DESCRIPTION: 'Billboard, -'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/4.d.webp', DESCRIPTION: 'Kurdela, -'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/BURHAN_DOGANCAY/0.m.webp', DESCRIPTION: 'Ribbon, 75,8x56,5'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/1.m.webp', DESCRIPTION: 'Shadows on Black, 75x55'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/2.m.webp', DESCRIPTION: 'PIROUETTE, 76x57'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/3.m.webp', DESCRIPTION: 'Billboard, -'},
        {SRC: './assets/announcements/BURHAN_DOGANCAY/4.m.webp', DESCRIPTION: 'Kurdela, -'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/burhan-dogancay/" target="_blank">https://www.galerisoyut.com.tr/artist/burhan-dogancay/<a>',
      ]
    },
    {
      ID: 22,
      TEMPLATE: 0,
      NAME: 'Ertuğrul Ateş',
      NAME_UPPERCASE: 'ERTUĞRUL ATEŞ',
      TOPIC: 'About Ertuğrul Ateş',
      TOPIC_UPPERCASE: 'ABOUT ERTUĞRUL ATEŞ',
      ARTWORKS: 'Ertuğrul Ateş\'s Artworks',
      ARTWORKS_UPPERCASE: 'ERTUĞRUL ATEŞ\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/ERTUGRUL_ATES/ertugrul_ates.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ERTUGRUL_ATES/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/ERTUGRUL_ATES/-1.m.webp',
      TEXT: 'Ertuğrul Ateş was born in Adana in 1954. His artistic journey began in high school with Duran Karaca, and he continued his education at the Gazi Education Institute\'s Department of Painting and Work, graduating in 1976. After completing his academic studies, he moved to London in 1977, where he furthered his art education at the Kingsway Princeton College and the Bethnal Green Institute. In 1987, Ateş relocated to the United States, settling in New York, where he continued his work until 2006 before returning to Turkey.\n' +
        '\n' +
        'Ateş approaches his paintings with a surrealistic style, exploring the subconscious of humanity and the image of women. He starts his painting process directly on the canvas without sketching, creating random textures that begin a dialogue with the painting. His metaphysical compositions gradually evolve into a mystical atmosphere. Known as the "Ribbon Painter", Ateş frequently incorporated the element of ribbons in his works, although this motif became less prevalent after the 1990s.\n' +
        '\n' +
        'Ateş describes his art as not offering solutions or lessons but whispering "incomprehensible things" gently to the ears of the viewers. He believes that art, or creativity, makes life meaningful and that divinity, beyond death, can only be reached through creation. This perspective signifies that the 21st century will belong to artists and points towards an upcoming Second Renaissance.\n' +
        '\n' +
        'Ateş currently lives and works in İzmir-Çeşme. He has participated in numerous artistic activities both domestically and internationally, showcasing his work in many cities including New York, Boston, Los Angeles, Dallas, Chicago, Miami, South Hampton, Barcelona, Copenhagen, Beijing, Shanghai, Taipei, Istanbul, Ankara, İzmir, and Adana. Ateş\'s art, with its unique approach and profound philosophy, holds a significant place in the art world.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://arts.ozyegin.edu.tr/tr/artist/ertugrul-ates</li>' +
        '   <li>https://www.galerisoyut.com.tr/artist/ertugrul-ates/</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/ERTUGRUL_ATES/0.d.webp', DESCRIPTION: 'Nuh\'un gemisi, 180x250'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/1.d.webp', DESCRIPTION: 'İsimsiz, 160x140'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/2.d.webp', DESCRIPTION: 'Gel, 135x240'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/3.d.webp', DESCRIPTION: 'Güzel Haber II, 140x240'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/4.d.webp', DESCRIPTION: 'Afrodit, 180x160'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/5.d.webp', DESCRIPTION: 'Güzel I, 50x100'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/ERTUGRUL_ATES/0.m.webp', DESCRIPTION: 'Nuh\'un gemisi, 180x250'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/1.m.webp', DESCRIPTION: 'İsimsiz, 160x140'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/2.m.webp', DESCRIPTION: 'Gel, 135x240'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/3.m.webp', DESCRIPTION: 'Güzel Haber II, 140x240'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/4.m.webp', DESCRIPTION: 'Afrodit, 180x160'},
        {SRC: './assets/announcements/ERTUGRUL_ATES/5.m.webp', DESCRIPTION: 'Güzel I, 50x100'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://www.galerisoyut.com.tr/artist/ertugrul-ates/" target="_blank">https://www.galerisoyut.com.tr/artist/ertugrul-ates/<a>',
      ]
    },
    {
      ID: 23,
      TEMPLATE: 0,
      NAME: 'Orhan Peker',
      NAME_UPPERCASE: 'ORHAN PEKER',
      TOPIC: 'About Orhan Peker',
      TOPIC_UPPERCASE: 'ABOUT ORHAN PEKER',
      ARTWORKS: 'Orhan Peker\'s Artworks',
      ARTWORKS_UPPERCASE: 'ORHAN PEKER\'S ARTWORKS',
      ARTIST_IMAGE_SRC: './assets/announcements/ORHAN_PEKER/orhan_peker.webp',
      IMAGE_BACKGROUND_DESKTOP: './assets/announcements/ORHAN_PEKER/-1.d.webp',
      IMAGE_BACKGROUND_MOBILE: './assets/announcements/ORHAN_PEKER/-1.m.webp',
      TEXT: 'Orhan Peker was born on May 27, 1927, in Trabzon and passed away on May 28, 1978, in Istanbul. After completing his primary education in Trabzon, he continued his secondary education at the Austrian High School in Istanbul. Between 1946 and 1951, he studied painting at the Istanbul State Academy of Fine Arts in Bedri Rahmi Eyüboğlu\'s workshop and in France. In 1947, he co-founded the "Onlar Group" with his artist friends.\n' +
        'Peker\'s paintings were first exhibited in 1951 at the State Painting and Sculpture Exhibition. In 1953, he held his first solo exhibition. In 1955, he visited Vienna and Paris with Max Meincke, the director of Istanbul City Theatres. In 1956, he attended the Summer Academy organized by Oskar Kokoschka and exhibited lithographic works in Germany during the same period.\n' +
        'Returning to Turkey in 1957, Peker settled in Ankara in 1959 and began working at the Ministry of Tourism and Information. In 1965, he won first place at the State Painting and Sculpture Exhibition and subsequently traveled to Madrid to observe the art scene. In 1966, he was named Painter of the Year in Turkey and won an award for his portrait of  şık Veysel in a painting competition organized by TRT. He also illustrated books such as "Ağaca Takılan Uçurtma" for Turkish children in Germany, Metin Eloğlu\'s "Rüzgâr Ekmek," and Çetin Öner\'s "Gülibik."\n' +
        'Peker held exhibitions in various European cities and moved to Istanbul shortly before his death. He opened his last solo exhibition at the Bedri Rahmi Gallery, showcasing his pigeon-themed works. Peker\'s grave is located at the Zincirlikuyu Cemetery.',
      TEXT_LIMIT_DESKTOP: 1145,
      TEXT_LIMIT_MOBILE: 1145,
      REFERENCES: '<div class="references">' +
        ' <ol>' +
        '   <li>https://www.kulturportali.gov.tr/portal/orhan-peker</li>' +
        '   <li>https://www.galeriartist.com/tr/sanatcilar/orhan-peker</li>' +
        '   <li>https://www.soyakmuzayede.com/blog/2594/orhan-peker-1927-1978</li>' +
        ' </ol>' +
        '</div>',
      ARTWORKS_LIST_DESKTOP: [
        {SRC: './assets/announcements/ORHAN_PEKER/0.d.webp', DESCRIPTION: 'Balıkçı Çocuk ve Kediler, 189x266,TÜYB, 1976'},
        {SRC: './assets/announcements/ORHAN_PEKER/1.d.webp', DESCRIPTION: 'Sarımsaklı Plajında Denize Girenler ve Askerler, 41,5x57'},
        {SRC: './assets/announcements/ORHAN_PEKER/2.d.webp', DESCRIPTION: 'Kedili Özden, 150x150'},
        {SRC: './assets/announcements/ORHAN_PEKER/3.d.webp', DESCRIPTION: 'Güvercin, 15x22, TÜYB'},
        {SRC: './assets/announcements/ORHAN_PEKER/4.d.webp', DESCRIPTION: 'Karga, 10.5x15, KÜKT'},
        {SRC: './assets/announcements/ORHAN_PEKER/5.d.webp', DESCRIPTION: 'Kargalar, 36x48, Litografi, 1957'},
        {SRC: './assets/announcements/ORHAN_PEKER/6.d.webp', DESCRIPTION: 'İtfaiyeciler, 72x53, Ağaç Baskı, 1967'},
      ],
      ARTWORKS_LIST_MOBILE: [
        {SRC: './assets/announcements/ORHAN_PEKER/0.m.webp', DESCRIPTION: 'Balıkçı Çocuk ve Kediler, 189x266,TÜYB, 1976'},
        {SRC: './assets/announcements/ORHAN_PEKER/1.m.webp', DESCRIPTION: 'Sarımsaklı Plajında Denize Girenler ve Askerler, 41,5x57'},
        {SRC: './assets/announcements/ORHAN_PEKER/2.m.webp', DESCRIPTION: 'Kedili Özden, 150x150'},
        {SRC: './assets/announcements/ORHAN_PEKER/3.m.webp', DESCRIPTION: 'Güvercin, 15x22, TÜYB'},
        {SRC: './assets/announcements/ORHAN_PEKER/4.m.webp', DESCRIPTION: 'Karga, 10.5x15, KÜKT'},
        {SRC: './assets/announcements/ORHAN_PEKER/5.m.webp', DESCRIPTION: 'Kargalar, 36x48, Litografi, 1957'},
        {SRC: './assets/announcements/ORHAN_PEKER/6.m.webp', DESCRIPTION: 'İtfaiyeciler, 72x53, Ağaç Baskı, 1967'},
      ],
      ARTWORK_REFERENCES: [
        'Tümü: <a class="gray-underline" href="https://www.galeriartist.com/tr/sanatcilar/orhan-peker" target="_blank">https://www.galeriartist.com/tr/sanatcilar/orhan-peker<a>',
      ]
    },

    ...test_artists_config_en,
  ]
};
