import {test_art_products_config_tr} from './test_config_tr';

export const art_products_config_tr = {
    ABOUT_ART_PRODUCT: 'Eser Hakkında',
    CONDITION: 'Kondisyon Durumu',
    EXPERT_REPORT: 'Eksper Raporu',
    EXPERT_REPORT_LINK: 'Eksper Raporunu Gör',
    INSURANCE_REPORT: 'Sigorta Raporu',
    INSURANCE_REPORT_LINK: 'Sigorta Raporu Gör',
    EXHIBITION_REPORT: 'Sergi Geçmişi',
    EXHIBITION_REPORT_LINK: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
    MORE: 'Daha Fazla Bilgi',
    NEW: {
        NAME: 'Yeni Eser',
        NAME_UPPERCASE: 'YENİ ESER',
        YEAR: 'sizlerle!',
        MEDIUM: 'Tarih ve eser hakkında',
        SIZE: 'detaylı bilgi çok yakında',
        SIGNATURE: '',
        INFO: 'Tarih ve eser hakkında detaylı bilgi çok yakında sizlerle!',
        ARTIST_NAME: '',
        ARTIST_SHORT_NAME: '',
        ARTIST_BORN_DEATH: 'Çok Yakında!',
        ARTIST_ABOUT: '',
        ARTIST_ABOUT_UPPERCASE: '',
        CONDITION: 0,
        EXPERT_REPORT: '',
        INSURANCE_REPORT: '',
        EXHIBITION_REPORT: '',
        IMAGE_DESKTOP: ['./assets/art_products/NEW/0.d.webp?w=600&h=593'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NEW/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NEW/0.m.webp?w=450&h=447'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NEW/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/NEW/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NEW/-1.m.thumbnail.webp'
    },
    BOD: {
        NAME: 'Bodrum',
        NAME_UPPERCASE: 'BODRUM',
        YEAR: '2020',
        MEDIUM: 'Tuval Üzerine Akrilik',
        SIZE: '60cm x 80cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Yalçın Gökçebağ “Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” ve “Resmin Yaşar Kemal’i” olarak bilinir.' +
            ' Geniş bakış açısı, ritmik düzeni, detaycılığı, fırça ve renk kullanımı ile Anadolu’yu kendi tavrıyla yansıtır.' +
            ' Genellikle Hasat ve Bodrum serileri gibi geniş bakış açıları ile kurguladığı peyzaj kompozisyonları ile bilinir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 0, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=0',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Yalçın Gökçebağ Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'YALÇIN GÖKÇEBAĞ HAKKINDA',
        ARTIST_NAME: 'Yalçın Gökçebağ',
        ARTIST_SHORT_NAME: 'Y. Gökçebağ',
        ARTIST_BORN_DEATH: '1944-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BOD/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BOD/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer>3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/BOD/0.d.webp?w=1200&h=954',
            './assets/art_products/BOD/1.d.webp?w=1200&h=892',
            './assets/art_products/BOD/2.d.webp?w=1200&h=966'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BOD/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BOD/0.m.webp?w=450&h=357',
            './assets/art_products/BOD/1.m.webp?w=450&h=335',
            './assets/art_products/BOD/2.m.webp?w=450&h=362'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BOD/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BOD/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BOD/-1.m.thumbnail.webp'
    },
    MAR: {
        NAME: 'Abstrait Marron',
        NAME_UPPERCASE: 'ABSTRAIT MARRON',
        YEAR: '1957',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '25cm x 50cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Nejad Melih Devrim, çağdaş sanat alanındaki çalışmalarıyla döneminin önemli ressamları arasında yer almaktadır.' +
            ' Coğrafi ve kültürel öğelerden etkilenen sanatçı, figüratif ve soyut arasında gidip gelen bir yol izlemiştir.' +
            ' Özellikle geç dönem soyut resimleriyle Türk sanatında önemli bir yere sahiptir.' +
            ' Amerika’da “Soyut Dışavurumculuk“ olarak bilinen ve çoğunluğu Avrupa’dan gelen sanatçılar ile başlayan hareketin' +
            ' Avrupa’daki karşılığı olan lirik soyut ve kısmen geometrik soyut içinde yer almaktadır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 1, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=1',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Nejad Melih Devrim Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'NEJAD MELİH DEVRİM HAKKINDA',
        ARTIST_NAME: 'Nejad Melih Devrim',
        ARTIST_SHORT_NAME: 'N. M. Devrim',
        ARTIST_BORN_DEATH: '1923-1995',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/MAR/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MAR/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/MAR/0.d.webp?w=1200&h=666',
            './assets/art_products/MAR/1.d.webp?w=1200&h=588',
            './assets/art_products/MAR/2.d.webp?w=1200&h=677'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MAR/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MAR/0.m.webp?w=450&h=250',
            './assets/art_products/MAR/1.m.webp?w=450&h=221',
            './assets/art_products/MAR/2.m.webp?w=450&h=254'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MAR/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MAR/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MAR/-1.m.thumbnail.webp'
    },
    PGS: {
        NAME: 'Pegasus\'un Bulutu',
        NAME_UPPERCASE: 'PEGASUS\'UN BULUTU',
        YEAR: '2015',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: '21. yüzyıl Türkiye sanatının en önemli isimlerinden kabul edilen Ahmet Güneştekin; resim, kavramsal sanat ve heykel alanlarında eserler üretmektedir.' +
            ' Kendi kendini yetiştirmiş bu sanatçı işlerinde coğrafi öğelere ve Anadolu, Yunan, Mezopotamya efsanelerine yer vermektedir.' +
            ' Geleneksel ve çağdaş referanslarla yüklü işleri ile öne çıkan Güneştekin, 2000\'lerin başında figüratifi terk etmiş ve anlatıya odaklanan soyut bir tarz benimsemiştir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 2, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=2',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ahmet Güneştekin Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'AHMET GÜNEŞTEKİN HAKKINDA',
        ARTIST_NAME: 'Ahmet Güneştekin',
        ARTIST_SHORT_NAME: 'A. Güneştekin',
        ARTIST_BORN_DEATH: '1966-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/PGS/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/PGS/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/PGS/0.d.webp?w=1178&h=1200',
            './assets/art_products/PGS/1.d.webp?w=1200&h=1191'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/PGS/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/PGS/0.m.webp?w=442&h=450',
            './assets/art_products/PGS/1.m.webp?w=450&h=447'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/PGS/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/PGS/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/PGS/-1.m.thumbnail.webp'
    },
    ORC: {
        NAME: 'Orchestra',
        NAME_UPPERCASE: 'ORCHESTRA',
        YEAR: '2011',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Türkiye sanatının ve sanat akademisinin önemli isimlerinden olan Adnan Turani, soyut odaklı resmin Türkiye’deki en önemli temsilcilerindendir.' +
            ' Erken dönem eserlerinde soyut bir anlayış gösteren Turani, son dönem eserlerinde figürlere ve doğaya yer verse de' +
            ' resimlerine yaklaşımı soyut bir kurguya ve biçimin ifadesine dayalı olmayı sürdürmüştür.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 3, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=3',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Adnan Turani Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ADNAN TURANİ HAKKINDA',
        ARTIST_NAME: 'Adnan Turani',
        ARTIST_SHORT_NAME: 'A. Turani',
        ARTIST_BORN_DEATH: '1925-2016',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ORC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ORC/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/ORC/0.d.webp?w=1200&h=1182',
            './assets/art_products/ORC/1.d.webp?w=1200&h=1156'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ORC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/ORC/0.m.webp?w=450&h=443',
            './assets/art_products/ORC/1.m.webp?w=450&h=434'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ORC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ORC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ORC/-1.m.thumbnail.webp'
    },
    IST: {
        NAME: 'Süleymaniye\'den İstanbul\'a',
        NAME_UPPERCASE: 'SÜLEYMANİYE\'DEN İSTANBUL\'A',
        YEAR: '2021',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Devrim Erbil, ulusal ve uluslararası birçok sergide yer almış ve ödüle layık bulunmuş ressam ve akademisyendir.' +
            ' Yağlıboya, baskı, vitray, mozaik gibi pek çok medyum ile eserler üretmiştir. Devrim Erbil’in sanatı;' +
            ' çağdaş sanatı gelenekle bağlantılı bir temele oturtmak isteyen yöresel kaynaklı eğilimler içinde değerlendirilebilir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=4',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Devrim Erbil Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'DEVRİM ERBİL HAKKINDA',
        ARTIST_NAME: 'Devrim Erbil',
        ARTIST_SHORT_NAME: 'D. Erbil',
        ARTIST_BORN_DEATH: '1937-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/IST/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/IST/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/IST/0.d.webp?w=1200&h=1193',
            './assets/art_products/IST/1.d.webp?w=1200&h=1200',
            './assets/art_products/IST/2.d.webp?w=1200&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/IST/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/IST/0.m.webp?w=450&h=450',
            './assets/art_products/IST/1.m.webp?w=450&h=450',
            './assets/art_products/IST/2.m.webp?w=450&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/IST/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/IST/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/IST/-1.m.thumbnail.webp'
    },
    APT: {
        NAME: 'Apartman',
        NAME_UPPERCASE: 'APARTMAN',
        YEAR: '1977',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '129cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Resimlerini gelenekler, gündelik hayat ve Anadolu söylenceleri ile bağdaştırıp dekoratif nitelikli özgün bir' +
            ' gerçeküstücülük ile ortaya koyan Nuri Abaç, 20. Yüzyıl Türkiye sanatının önemli ressamlarından olmuştur.' +
            ' Tarzının öne çıkan özelliği Karagöz-Hacivat estetiğine sahip bir yaklaşım benimsemesi ve musiki unsurlara sık sık yer vermesidir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 5, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=5',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Nuri Abaç Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'NURİ ABAÇ HAKKINDA',
        ARTIST_NAME: 'Nuri Abaç',
        ARTIST_SHORT_NAME: 'N. Abaç',
        ARTIST_BORN_DEATH: '1926-2008',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/APT/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/APT/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/APT/0.d.webp?w=979&h=1200',
            './assets/art_products/APT/1.d.webp?w=942&h=1200',
            './assets/art_products/APT/2.d.webp?w=966&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/APT/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/APT/0.m.webp?w=368&h=450',
            './assets/art_products/APT/1.m.webp?w=353&h=450',
            './assets/art_products/APT/2.m.webp?w=362&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/APT/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/APT/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/APT/-1.m.thumbnail.webp'
    },
    SE1: {
        NAME: 'Sauna Emerging 1',
        NAME_UPPERCASE: 'SAUNA EMERGING 1',
        YEAR: '',
        MEDIUM: '',
        SIZE: 'Çeşitli',
        SIGNATURE: 'İmzalı',
        INFO: 'Artiox ve Art.Ist Sauna işbirliği çerçevesinde oluşturulan “Sauna Emerging 1” sepeti, genç ve bağımsız 11 sanatçının eserlerinden oluşmaktadır.' +
            ' Bu sepet ile birlikte gelecek vadeden sanatçıların üretmiş oldukları sanat eserlerine yatırım yaparken aynı zamanda titizlikle seçilmiş bu sanatçılara da destek olmuş olacaksınız.' +
            ' Art.Ist Sauna tarafından oluşturulan seçici kurul uzun ve detaylı bir çalışma sonucunda sepette yer alacak isimleri belirlerken sanatçıların geçmiş performanslarını' +
            ' ve istikrarlı olarak ortaya eser koyan sanatçıları seçmeye odaklanmıştır.' +
            ' Sepette yer alan eserler UniqExpo bünyesinde bulunan sergileme alanında sürekli olarak sergilenecek ve çeşitli etkinliklerde sanatseverler sanatçılar ile birlikte vakit geçirme fırsatı da bulabilecekler.' +
            ' Böylece sanatçı ile sanatseverler daha fazla iletişim halinde kalırken aynı zamanda bu değerli sanatçılarımızın gelişimlerini düzenli izleme fırsatı da bulmuş olacağız.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 6, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=6',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Sauna Emerging 1 Sepeti Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'SAUNA EMERGING 1 SEPETİ HAKKINDA',
        ARTIST_NAME: 'Sauna Emerging 1 Sanatçıları',
        ARTIST_SHORT_NAME: 'Sauna Emerging 1',
        ARTIST_BORN_DEATH: ' - ',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SE1/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SE1/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/SE1/0.d.webp?w=1201&h=924',
            './assets/art_products/SE1/1.d.webp?w=1181&h=1200',
            './assets/art_products/SE1/2.d.webp?w=901&h=1200',
            './assets/art_products/SE1/3.d.webp?w=1202&h=910',
            './assets/art_products/SE1/4.d.webp?w=907&h=1199',
            './assets/art_products/SE1/5.d.webp?w=1193&h=1201',
            './assets/art_products/SE1/6.d.webp?w=1201&h=898',
            './assets/art_products/SE1/7.d.webp?w=1180&h=1200',
            './assets/art_products/SE1/8.d.webp?w=1200&h=1100',
            './assets/art_products/SE1/9.d.webp?w=1200&h=1200',
            './assets/art_products/SE1/10.d.webp?w=1200&h=1199',
            './assets/art_products/SE1/11.d.webp?w=1023&h=1199',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SE1/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/SE1/0.m.webp?w=454&h=351',
            './assets/art_products/SE1/1.m.webp?w=445&h=450',
            './assets/art_products/SE1/2.m.webp?w=341&h=448',
            './assets/art_products/SE1/3.m.webp?w=449&h=343',
            './assets/art_products/SE1/4.m.webp?w=335&h=455',
            './assets/art_products/SE1/5.m.webp?w=499&h=499',
            './assets/art_products/SE1/6.m.webp?w=451&h=343',
            './assets/art_products/SE1/7.m.webp?w=445&h=454',
            './assets/art_products/SE1/8.m.webp?w=448&h=412',
            './assets/art_products/SE1/9.m.webp?w=450&h=450',
            './assets/art_products/SE1/10.m.webp?w=452&h=451',
            './assets/art_products/SE1/11.m.webp?w=381&h=447',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SE1/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SE1/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SE1/-1.m.thumbnail.webp'
    },
    BUG: {
        NAME: 'Böcek',
        NAME_UPPERCASE: 'BÖCEK',
        YEAR: '2021',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '135cm x 135cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Ergin İnan, uluslararası tanınırlığa sahip çok sayıda sergilerde bulunmuş ve ödüle layık görülmüş bir ressamdır.' +
            ' Çağdaş Türk sanatında alışagelmedik bir yaklaşıma sahip sanatçı, eserlerinde genelde karışık medyum ve baskı teknikleri kullanarak kavramsal çalışmalar yapmıştır.' +
            ' Ergin İnan’ın eserlerinde sık rastlanan imgelere; soyutlanmış insan figürleri, yazı imgeleri ve böcek tasvirleri örnek gösterilebilir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ergin İnan Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ERGİN İNAN HAKKINDA',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BUG/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BUG/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/BUG/0.d.webp?w=1158&h=1200',
            './assets/art_products/BUG/1.d.webp?w=1200&h=1108'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BUG/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BUG/0.m.webp?w=434&h=450',
            './assets/art_products/BUG/1.m.webp?w=450&h=416'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BUG/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BUG/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BUG/-1.m.thumbnail.webp'
    },
    FACE: {
        NAME: 'Yüzler',
        NAME_UPPERCASE: 'YÜZLER',
        YEAR: '2023',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '100cm x 150cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Ergin İnan, uluslararası tanınırlığa sahip çok sayıda sergilerde bulunmuş ve ödüle layık görülmüş bir ressamdır.' +
            ' Çağdaş Türk sanatında alışagelmedik bir yaklaşıma sahip sanatçı, eserlerinde genelde karışık medyum ve baskı teknikleri kullanarak kavramsal çalışmalar yapmıştır.' +
            ' Ergin İnan’ın eserlerinde sık rastlanan imgelere; soyutlanmış insan figürleri, yazı imgeleri ve böcek tasvirleri örnek gösterilebilir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ergin İnan Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ERGİN İNAN HAKKINDA',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/FACE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/FACE/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/FACE/0.d.webp?w=1200&h=809',
            './assets/art_products/FACE/1.d.webp?w=1200&h=796'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/FACE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/FACE/0.m.webp?w=450&h=303',
            './assets/art_products/FACE/1.m.webp?w=450&h=298'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/FACE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/FACE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/FACE/-1.m.thumbnail.webp'
    },
    RET: {
        NAME: 'Retrospektif',
        NAME_UPPERCASE: 'RETROSPEKTİF',
        YEAR: '2021',
        MEDIUM: 'Tuval Üzerine Akrilik',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: '20. yüzyıl Türkiye sanatında önemli bir yeri olan Adnan Çoker; en çok Bizans, Selçuklu ve Osmanlı mimari formlarını resimsel konturlara dönüştürerek özgün bir estetik üslup yaratmasıyla tanınır.' +
            ' 1970ler sonrası bu çizgide işler üreten sanatçı resimlerinde kalıp-biçim olarak nitelendirdiği biçimleri, siyah üzerinde soyut bir mekânsal boyut içerisinde işler.' +
            ' Anıtsal mimarinin, iç uzamını dış dünyaya açan sivri kemerli kapı ve pencere motifinden yola çıkılarak oluşturulan uyum, sanatçının sözleriyle bir kalıp biçime dayanır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=8',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Adnan Çoker Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ADNAN ÇOKER HAKKINDA',
        ARTIST_NAME: 'Adnan Çoker',
        ARTIST_SHORT_NAME: 'A. Çoker',
        ARTIST_BORN_DEATH: '1927-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/RET/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/RET/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/RET/0.d.webp?w=1200&h=1179',
            './assets/art_products/RET/1.d.webp?w=1200&h=1168'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/RET/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/RET/0.m.webp?w=450&h=442',
            './assets/art_products/RET/1.m.webp?w=450&h=438'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/RET/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/RET/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/RET/-1.m.thumbnail.webp'
    },
    TETE: {
        NAME: 'Tete d’Histrion (Le Danseur)',
        NAME_UPPERCASE: 'TETE D’HISTRION (LE DANSEUR)',
        YEAR: '1965',
        MEDIUM: 'Linocut',
        SIZE: '75.4cm x 62.2cm',
        SIGNATURE: 'İmzalı',
        INFO: '20. yüzyılın en büyük ve en etkili sanatçılarından biri olan Pablo Picasso, İspanya\'nın Malaga kentinde doğdu.' +
            'Picasso\'nun babası bir çizim profesörüydü ve oğlunu akademik sanat alanında bir kariyer için yetiştirdi.' +
            ' Picasso ilk sergisini 13 yaşında açtı ve daha sonra modern sanat tarzlarıyla tam zamanlı deneyler yapabilmek için sanat okulunu bıraktı.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=9',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Pablo Picasso Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'PABLO PICASSO HAKKINDA',
        ARTIST_NAME: 'Pablo Picasso',
        ARTIST_SHORT_NAME: 'P. Picasso',
        ARTIST_BORN_DEATH: '1881-1973',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/TETE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TETE/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 Kasım IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/TETE/0.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/1.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/2.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/3.d.webp?w=1069&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TETE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TETE/0.m.webp?w=398&h=450',
            './assets/art_products/TETE/1.m.webp?w=398&h=450',
            './assets/art_products/TETE/2.m.webp?w=398&h=450',
            './assets/art_products/TETE/3.m.webp?w=401&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TETE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TETE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TETE/-1.m.thumbnail.webp'
    },
    NAI: {
        NAME: 'La Danseuse Naine',
        NAME_UPPERCASE: 'LA DANSEUSE NAINE',
        YEAR: '1966',
        MEDIUM: 'Litografi',
        SIZE: '75.5cm x 55cm',
        SIGNATURE: 'İmzalı',
        INFO: '20. yüzyılın en büyük ve en etkili sanatçılarından biri olan Pablo Picasso, İspanya\'nın Malaga kentinde doğdu.' +
            'Picasso\'nun babası bir çizim profesörüydü ve oğlunu akademik sanat alanında bir kariyer için yetiştirdi.' +
            ' Picasso ilk sergisini 13 yaşında açtı ve daha sonra modern sanat tarzlarıyla tam zamanlı deneyler yapabilmek için sanat okulunu bıraktı.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=9',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Pablo Picasso Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'PABLO PICASSO HAKKINDA',
        ARTIST_NAME: 'Pablo Picasso',
        ARTIST_SHORT_NAME: 'P. Picasso',
        ARTIST_BORN_DEATH: '1881-1973',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/NAI/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/NAI/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/NAI/0.d.webp?w=927&h=1200',
            './assets/art_products/NAI/1.d.webp?w=927&h=1200',
            './assets/art_products/NAI/2.d.webp?w=927&h=1200',
            './assets/art_products/NAI/3.d.webp?w=942&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NAI/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NAI/0.m.webp?w=348&h=450',
            './assets/art_products/NAI/1.m.webp?w=348&h=450',
            './assets/art_products/NAI/2.m.webp?w=348&h=450',
            './assets/art_products/NAI/3.m.webp?w=353&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NAI/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/NAI/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NAI/-1.m.thumbnail.webp'
    },
    ORAN: {
        NAME: 'İsimsiz',
        NAME_UPPERCASE: 'İSİMSİZ',
        YEAR: '2009',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '190cm x 250cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Soyut eserleriyle tanınan Ahmet Oran, 1957 yılında Çanakkale’de doğmuştur.' +
            ' 1977 yılında İstanbul Devlet Güzel Sanatlar Akademisi’ne giren sanatçı,' +
            ' burada öğrenciyken aynı zamanda Viyana Tatbiki Güzel Sanatlar Yüksek Okulu’nda resim, cam boyama ve grafik eğitimi almıştır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 10, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=10',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ahmet Oran Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'AHMET ORAN HAKKINDA',
        ARTIST_NAME: 'Ahmet Oran',
        ARTIST_SHORT_NAME: 'A. Oran',
        ARTIST_BORN_DEATH: '1957-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ORAN/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ORAN/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/ORAN/0.d.webp?w=1200&h=903',
            './assets/art_products/ORAN/1.d.webp?w=1200&h=1127',
            './assets/art_products/ORAN/2.d.webp?w=1200&h=901',
            './assets/art_products/ORAN/3.d.webp?w=1200&h=893',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ORAN/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/ORAN/0.m.webp?w=450&h=339',
            './assets/art_products/ORAN/1.m.webp?w=450&h=423',
            './assets/art_products/ORAN/2.m.webp?w=450&h=338',
            './assets/art_products/ORAN/3.m.webp?w=450&h=335',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ORAN/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ORAN/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ORAN/-1.m.thumbnail.webp'
    },
    SUM: {
        NAME: 'Summer',
        NAME_UPPERCASE: 'SUMMER',
        YEAR: '-',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '158cm x 74cm',
        SIGNATURE: 'İmzalı',
        INFO: '7 Ocak 1901’de Büyükada’da doğan ressam Fahrelnissa Zeid, pek çok sanatçı yetiştirmiş Kabaağaçlı ailesindendir.' +
            ' Ailenin geçmişi asker kökenli olsa da Kabaağaçlı ailesinin üyeleri sanatın farklı dallarında isimlerini tarihe yazdırmıştır.' +
            ' Halikarnas Balıkçısı Cevat Şakir’in ve ressam Aliye Berger’in kardeşi, oyuncu Şirin Devrim ve ressam Nejad Devrim’in annesi, ilk kadın seramik sanatçımız Füreya Koral’ın teyzesi olan' +
            ' ünlü ressam Fahrelnissa Zeid sanatı ve karakteriyle adını tüm dünyaya duyurmuş yetenekli bir Türk kadınıdır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=11',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Fahrelnissa Zeid Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'FAHRELNİSSA ZEİD HAKKINDA',
        ARTIST_NAME: 'Fahrelnissa Zeid',
        ARTIST_SHORT_NAME: 'F. Zeid',
        ARTIST_BORN_DEATH: '1901-1991',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SUM/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SUM/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/SUM/0.d.webp?w=633&h=1200',
            './assets/art_products/SUM/1.d.webp?w=555&h=1200',
            './assets/art_products/SUM/2.d.webp?w=633&h=1200',
            './assets/art_products/SUM/3.d.webp?w=724&h=1200',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SUM/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/SUM/0.m.webp?w=238&h=450',
            './assets/art_products/SUM/1.m.webp?w=208&h=450',
            './assets/art_products/SUM/2.m.webp?w=237&h=450',
            './assets/art_products/SUM/3.m.webp?w=271&h=450',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SUM/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SUM/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SUM/-1.m.thumbnail.webp'
    },
    TRUE: {
        NAME: 'True Love',
        NAME_UPPERCASE: 'TRUE LOVE',
        YEAR: '2018',
        MEDIUM: 'Tuval Üzerine Akrilik',
        SIZE: '200cm x 200cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Haluk Akakçe çağdaş sanat dünyasının en önemli isimlerinden, her sergisi bir olay… Sıradışı, yaratıcı ve dahi…',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=12',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Haluk Akakçe Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'HALUK AKAKÇE HAKKINDA',
        ARTIST_NAME: 'Haluk Akakçe',
        ARTIST_SHORT_NAME: 'H. Akakçe',
        ARTIST_BORN_DEATH: '1970-2023',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/TRUE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TRUE/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/TRUE/0.d.webp?w=1073&h=1200',
            './assets/art_products/TRUE/1.d.webp?w=1073&h=1200',
            './assets/art_products/TRUE/2.d.webp?w=1075&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TRUE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TRUE/0.m.webp?w=403&h=450',
            './assets/art_products/TRUE/1.m.webp?w=402&h=450',
            './assets/art_products/TRUE/2.m.webp?w=403&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TRUE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TRUE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TRUE/-1.m.thumbnail.webp'
    },
    ULC: {
        NAME: 'İsimsiz',
        NAME_UPPERCASE: 'İSİMSİZ',
        YEAR: '-',
        MEDIUM: 'Tuval Üzerine Akrilik',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'İmzalı',
        INFO: '1931 yılında doğan Ömer Uluç dünya çapında ünlü bir Türk ressamdır.' +
            ' Farklı ülkelerde 30’u aşkın kişisel sergi açmıştır ve katıldığı organizasyonlarda sunduğu eserleriyle tüm dünyayı kendine hayran bırakmıştır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 13, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=13',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ömer Uluç Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ÖMER ULUÇ HAKKINDA',
        ARTIST_NAME: 'Ömer Uluç',
        ARTIST_SHORT_NAME: 'Ö. Uluç',
        ARTIST_BORN_DEATH: '1931-2010',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ULC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ULC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/ULC/0.d.webp?w=1194&h=1200',
            './assets/art_products/ULC/1.d.webp?w=1200&h=1189',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ULC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/ULC/0.m.webp?w=448&h=450',
            './assets/art_products/ULC/1.m.webp?w=450&h=446',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ULC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ULC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ULC/-1.m.thumbnail.webp'
    },
    IYM: {
        NAME: 'İsimsiz',
        NAME_UPPERCASE: 'İSİMSİZ',
        YEAR: '-',
        MEDIUM: 'Duralit Üzerine Yağlı Boya',
        SIZE: '48cm x 38cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Çocukken ölümüne tanık olduğu ablasının gözlerinden ve yüzünden esinlenen sanatçı Nuri İyem,' +
            ' sanatının ikonik ve ayrılmaz bir parçası haline gelen \'çekingen, güzel ve melankolik\' yüzlerin ressamıdır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 14, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=14',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Nuri İyem Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'NURİ İYEM HAKKINDA',
        ARTIST_NAME: 'Nuri İyem',
        ARTIST_SHORT_NAME: 'N. İyem',
        ARTIST_BORN_DEATH: '1915-2005',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/IYM/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/IYM/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/IYM/0.d.webp?w=1000&h=1200',
            './assets/art_products/IYM/1.d.webp?w=1013&h=1200',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/IYM/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/IYM/0.m.webp?w=375&h=450',
            './assets/art_products/IYM/1.m.webp?w=380&h=450',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/IYM/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/IYM/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/IYM/-1.m.thumbnail.webp'
    },
    MATA: {
        NAME: 'İsimsiz',
        NAME_UPPERCASE: 'İSİMSİZ',
        YEAR: '2012',
        MEDIUM: 'Tuval Üzerine Karışık Teknik',
        SIZE: '97cm x 130 cm',
        SIGNATURE: 'İmzalı',
        INFO: '18 Mart 1945 tarihinde Trabzon’da doğan Mustafa Ata,' +
            ' Mimar Sinan Güzel Sanatlar Fakültesi (o zamanki adıyla İstanbul Devlet Güzel Sanatlar Akademisi) Resim Bölümü Adnan Çoker Atölyesi’nde öğrenim gördü.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Mustafa Ata Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'MUSTAFA ATA HAKKINDA',
        ARTIST_NAME: 'Mustafa Ata',
        ARTIST_SHORT_NAME: 'M. Ata',
        ARTIST_BORN_DEATH: '1945-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/MATA/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MATA/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/MATA/0.d.webp?w=1200&h=900',
            './assets/art_products/MATA/1.d.webp?w=1200&h=900',
            './assets/art_products/MATA/2.d.webp?w=1200&h=906'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MATA/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MATA/0.m.webp?w=450&h=338',
            './assets/art_products/MATA/1.m.webp?w=450&h=338',
            './assets/art_products/MATA/2.m.webp?w=450&h=340'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MATA/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MATA/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MATA/-1.m.thumbnail.webp'
    },
    MAVI: {
        NAME: 'Mavi Şiir',
        NAME_UPPERCASE: 'MAVİ ŞİİR',
        YEAR: '2022',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '105cm x 105cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Devrim Erbil, ulusal ve uluslararası birçok sergide yer almış ve ödüle layık bulunmuş ressam ve akademisyendir.' +
            ' Yağlıboya, baskı, vitray, mozaik gibi pek çok medyum ile eserler üretmiştir. Devrim Erbil’in sanatı;' +
            ' çağdaş sanatı gelenekle bağlantılı bir temele oturtmak isteyen yöresel kaynaklı eğilimler içinde değerlendirilebilir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=4',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Devrim Erbil Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'DEVRİM ERBİL HAKKINDA',
        ARTIST_NAME: 'Devrim Erbil',
        ARTIST_SHORT_NAME: 'D. Erbil',
        ARTIST_BORN_DEATH: '1937-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/MAVI/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MAVI/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/MAVI/0.d.webp?w=1200&h=1196',
            './assets/art_products/MAVI/1.d.webp?w=1200&h=1196',
            './assets/art_products/MAVI/2.d.webp?w=1168&h=1200',
            './assets/art_products/MAVI/3.d.webp?w=1200&h=442'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MAVI/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MAVI/0.m.webp?w=450&h=448',
            './assets/art_products/MAVI/1.m.webp?w=450&h=448',
            './assets/art_products/MAVI/2.m.webp?w=438&h=450',
            './assets/art_products/MAVI/3.m.webp?w=450&h=166'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MAVI/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MAVI/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MAVI/-1.m.thumbnail.webp'
    },
    SOY: {
        NAME: 'Soyağacı',
        NAME_UPPERCASE: 'SOYAĞACI',
        YEAR: '2014',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '180cm x 180cm',
        SIGNATURE: 'İmzalı',
        INFO: '1931’de Giresun’da doğan Özdemir Altan, İstanbul Devlet Güzel Sanatlar Akademisi Resim Bölümü’nden 1956’da mezun oldu.' +
            ' Mezunu olduğu okulda 1956 yılında akademisyen olarak çalışmaya  başladı ve 1988’deki emekliliğine kadar bu görevini sürdürdü.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 16, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=16',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Özdemir Altan Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ÖZDEMİR ALTAN HAKKINDA',
        ARTIST_NAME: 'Özdemir Altan',
        ARTIST_SHORT_NAME: 'Ö. Altan',
        ARTIST_BORN_DEATH: '1931-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SOY/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SOY/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/SOY/0.d.webp?w=1200&h=1200',
            './assets/art_products/SOY/1.d.webp?w=1200&h=1176'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SOY/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SOY/0.m.webp?w=450&h=450',
            './assets/art_products/SOY/1.m.webp?w=450&h=441'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SOY/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SOY/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SOY/-1.m.thumbnail.webp'
    },
    DIP: {
        NAME: 'Dip Dalga',
        NAME_UPPERCASE: 'DİP DALGA',
        YEAR: '2023',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '120x150 cm - Diptik (120x90+120x60 cm)',
        SIGNATURE: 'İmzalı',
        INFO: 'Ahmet Yeşil, 1954 yılında Mersin\'de doğdu ve burada büyüdü.' +
            ' Sanat hayatına Mersin\'de başlayan ve bu şehirde yaşamını sürdüren Yeşil, resim eğitimini Ressam Nuri Abaç, İlhan Çevik ve Heykeltraş Ernür Tüzün gibi önemli isimlerden aldı.' +
            ' Sanatçının eserleri Türkiye\'deki birçok özel koleksiyonda yer alırken, aynı zamanda Almanya, Amerika, Kanada, Hollanda, İngiltere gibi önemli yabancı koleksiyonlarda da kendine yer bulmuştur.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 17, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=17',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ahmet Yeşil Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'AHMET YEŞİL HAKKINDA',
        ARTIST_NAME: 'Ahmet Yeşil',
        ARTIST_SHORT_NAME: 'A. Yeşil',
        ARTIST_BORN_DEATH: '1954-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/DIP/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/DIP/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: [
            './assets/art_products/DIP/0.d.webp?w=1200&h=941',
            './assets/art_products/DIP/1.d.webp?w=1200&h=954',
            './assets/art_products/DIP/2.d.webp?w=1200&h=911',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/DIP/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/DIP/0.m.webp?w=450&h=353',
            './assets/art_products/DIP/1.m.webp?w=450&h=358',
            './assets/art_products/DIP/2.m.webp?w=450&h=342',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/DIP/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/DIP/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/DIP/-1.m.thumbnail.webp'
    },
    ASOS: {
        NAME: 'Assos',
        NAME_UPPERCASE: 'ASSOS',
        YEAR: '2019',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '64cm x 54cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Mehmet Güleryüz, çağdaş Türk resminin önde gelen sanatçılarından biri olarak kabul edilir ve eserleri Türkiye\'de ve dünya çapında birçok sanat müzesi ve koleksiyonunda bulunur. ' +
            'Eserlerinde sıkça insan figürlerine odaklanır ve sıradan insanların duygusal deneyimlerini, örneğin mutluluk, hüzün ve öfke gibi hisleri yansıtır. ' +
            'Naif bir üslupla çalışır ve karmaşık kompozisyonlardan kaçınarak basit ve anlaşılır bir dil kullanır. ',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 18, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Mehmet Güleryüz Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'MEHMET GÜLERYÜZ HAKKINDA',
        ARTIST_NAME: 'Mehmet Güleryüz',
        ARTIST_SHORT_NAME: 'M. Güleryüz' ,
        ARTIST_BORN_DEATH: '1938-2024',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ASOS/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ASOS/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/ASOS/0.d.webp?w=1038&h=1200',
            './assets/art_products/ASOS/1.d.webp?w=942&h=1200',
            './assets/art_products/ASOS/2.d.webp?w=960&h=1200',
            './assets/art_products/ASOS/3.d.webp?w=1200&h=900', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ASOS/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/ASOS/0.m.webp?w=390&h=451',
            './assets/art_products/ASOS/1.m.webp?w=354&h=450',
            './assets/art_products/ASOS/2.m.webp?w=360&h=450',
            './assets/art_products/ASOS/3.m.webp?w=450&h=337'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ASOS/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ASOS/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ASOS/-1.m.thumbnail.webp'
    },
    SURF: {
        NAME: 'Sınırsız Yüzey',
        NAME_UPPERCASE: 'SINIRSIZ YÜZEY',
        YEAR: '1995',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '146cm x 180cm',
        SIGNATURE: 'İmzalı',
        INFO: '18 Mart 1945 tarihinde Trabzon’da doğan Mustafa Ata,' +
            ' Mimar Sinan Güzel Sanatlar Fakültesi (o zamanki adıyla İstanbul Devlet Güzel Sanatlar Akademisi) Resim Bölümü Adnan Çoker Atölyesi’nde öğrenim gördü.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Mustafa Ata Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'MUSTAFA ATA HAKKINDA',
        ARTIST_NAME: 'Mustafa Ata',
        ARTIST_SHORT_NAME: 'M. Ata',
        ARTIST_BORN_DEATH: '1945-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SURF/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SURF/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/SURF/0.d.webp?w=1200&h=900',
            './assets/art_products/SURF/1.d.webp?w=1200&h=884',
            './assets/art_products/SURF/2.d.webp?w=1176&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SURF/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SURF/0.m.webp?w=450&h=337',
            './assets/art_products/SURF/1.m.webp?w=450&h=332',
            './assets/art_products/SURF/2.m.webp?w=441&h=450', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SURF/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SURF/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SURF/-1.m.thumbnail.webp'
    },
    SET: {
        NAME: 'Soyut Kompozisyon',
        NAME_UPPERCASE: 'SOYUT KOMPOZİSYON',
        YEAR: '-',
        MEDIUM: 'Tuval Üzerine Akrilik Boya',
        SIZE: '162cm x 130cm',
        SIGNATURE: 'İmzalı',
        INFO: '1915 yılında İstanbul Cağaloğlu’nda, Azeri-Kafkas kökenli Prof. Dr. Hüseyinzade Ali Turan ile Çerkez Süvari Zabiti Şemsettin Bey’in kızı Edhiye Hanım’ın oğlu olarak dünyaya gelen Selim Turan, ailesinin kökeninden kaynaklanan zengin kültürel mirasla büyüdü.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 19, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Selim Turan Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'SELİM TURAN HAKKINDA',
        ARTIST_NAME: 'Selim Turan',
        ARTIST_SHORT_NAME: 'S. Turan',
        ARTIST_BORN_DEATH: '1915 - 1994',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SET/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SET/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/SET/0.d.webp?w=972&h=1200',
            './assets/art_products/SET/1.d.webp?w=963&h=1200',
            './assets/art_products/SET/2.d.webp?w=894&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SET/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SET/0.m.webp?w=365&h=450',
            './assets/art_products/SET/1.m.webp?w=361&h=450',
            './assets/art_products/SET/2.m.webp?w=336&h=450', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SET/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SET/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SET/-1.m.thumbnail.webp'
    },
    PAK: {
        NAME: 'Suretler',
        NAME_UPPERCASE: 'SURETLER',
        YEAR: '2023',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '140cm x 160cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Şahin Paksoy, 1952 yılında doğmuş, Türk resim ve seramik sanatının özgün isimlerinden biridir. Sanat eğitimine 1973 yılında İstanbul Devlet Güzel Sanatlar Akademisi\'nin Seramik Bölümünde başlayan Paksoy, 1980 yılında buradan mezun olmuştur.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 20, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=20',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Şahin Paksoy Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ŞAHİN PAKSOY HAKKINDA',
        ARTIST_NAME: 'Şahin Paksoy',
        ARTIST_SHORT_NAME: 'Ş. Paksoy',
        ARTIST_BORN_DEATH: '1952 - ',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/PAK/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/PAK/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/PAK/0.d.webp?w=1059&h=1200',
            './assets/art_products/PAK/1.d.webp?w=1051&h=1200',
            './assets/art_products/PAK/2.d.webp?w=1170&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/PAK/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/PAK/0.m.webp?w=397&h=450',
            './assets/art_products/PAK/1.m.webp?w=394&h=450',
            './assets/art_products/PAK/2.m.webp?w=450&h=439', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/PAK/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/PAK/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/PAK/-1.m.thumbnail.webp'
    },
    TOC: {
        NAME: 'Touch of Class',
        NAME_UPPERCASE: 'TOUCH OF CLASS',
        YEAR: '1976',
        MEDIUM: 'Tuval Üzerine Akrilik Boya',
        SIZE: '60cm x 73cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Burhan Doğançay, 1929\'da İstanbul\'da dünyaya geldi. Babası, tanınmış ressam ve harita subayı Adil Doğançay ve ressam Arif Kaptan sayesinde küçük yaşlardan itibaren sanatla iç içe büyüdü. Ankara Üniversitesi Hukuk Fakültesi\'nden mezun olduktan sonra, sanatı akademik kariyerine tercih ederek Paris\'te ekonomi doktorası yaparken resim çalışmalarını sürdürdü.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 21, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=21',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Burhan Doğançay Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'BURHAN DOĞANÇAY HAKKINDA',
        ARTIST_NAME: 'Burhan Doğançay',
        ARTIST_SHORT_NAME: 'B. Doğançay',
        ARTIST_BORN_DEATH: '1929 - 2013',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/TOC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TOC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/TOC/0.d.webp?w=1200&h=998',
            './assets/art_products/TOC/1.d.webp?w=1200&h=991',
            './assets/art_products/TOC/2.d.webp?w=1200&h=990', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TOC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TOC/0.m.webp?w=450&h=374',
            './assets/art_products/TOC/1.m.webp?w=350&h=372',
            './assets/art_products/TOC/2.m.webp?w=450&h=371', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TOC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TOC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TOC/-1.m.thumbnail.webp'
    },
    MES: {
        NAME: 'Mesnevi',
        NAME_UPPERCASE: 'MESNEVİ',
        YEAR: '2008',
        MEDIUM: 'Ahşap Üzerine Karışık Teknik',
        SIZE: '255cm x 94cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Ergin İnan, uluslararası tanınırlığa sahip çok sayıda sergilerde bulunmuş ve ödüle layık görülmüş bir ressamdır.' +
          ' Çağdaş Türk sanatında alışagelmedik bir yaklaşıma sahip sanatçı, eserlerinde genelde karışık medyum ve baskı teknikleri kullanarak kavramsal çalışmalar yapmıştır.' +
          ' Ergin İnan’ın eserlerinde sık rastlanan imgelere; soyutlanmış insan figürleri, yazı imgeleri ve böcek tasvirleri örnek gösterilebilir.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ergin İnan Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ERGİN İNAN HAKKINDA',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/MES/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MES/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/MES/0.d.webp?w=482&h=1200',
            './assets/art_products/MES/1.d.webp?w=449&h=1200'
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MES/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MES/0.m.webp?w=181&h=450',
            './assets/art_products/MES/1.m.webp?w=168&h=449', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MES/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MES/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MES/-1.m.thumbnail.webp'
    },
    NUH: {
        NAME: 'Nuh\'un Gemisi',
        NAME_UPPERCASE: 'NUH\'UN GEMISI',
        YEAR: '2023',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '180cm x 250cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Ertuğrul Ateş, 1954 Adana doğumlu bir ressamdır. Sanat yolculuğuna lisede Duran Karaca ile başlamış ve Gazi Eğitim Enstitüsü Resim-İş Bölümünde eğitimini sürdürerek, 1976\'da mezun olmuştur. ' +
          'Akademik eğitiminin ardından, 1977\'de Londra\'ya taşınarak Kingsway Princeton Yüksekokulu ve Bethnal Green Enstitüsü\'nde sanat eğitimini derinleştirmiştir. ' +
          '1987\'de Amerika Birleşik Devletleri\'ne yerleşerek New York\'ta kariyerine devam eden Ateş, 2006 yılına kadar Amerika\'da çalışmalarını sürdürmüş ve ardından Türkiye\'ye dönmüştür.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 22, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=22',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Ertuğrul Ateş Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ERTUĞRUL ATEŞ HAKKINDA',
        ARTIST_NAME: 'Ertuğrul Ateş',
        ARTIST_SHORT_NAME: 'E. Ateş',
        ARTIST_BORN_DEATH: '1954-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/NUH/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/NUH/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/NUH/0.d.webp?w=1200&h=848', './assets/art_products/NUH/1.d.webp?w=1200&h=1094'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NUH/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NUH/0.m.webp?w=450&h=318', './assets/art_products/NUH/0.m.webp?w=450&h=410'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NUH/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/NUH/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NUH/-1.m.thumbnail.webp'
    },
    BURC: {
        NAME: 'Sarıda Burçlar',
        NAME_UPPERCASE: 'SARIDA BURÇLAR',
        YEAR: '2000',
        MEDIUM: 'Tuval Üzerine Akrilik',
        SIZE: '140cm x 140cm',
        SIGNATURE: 'İmzalı',
        INFO: '20. yüzyıl Türkiye sanatında önemli bir yeri olan Adnan Çoker; en çok Bizans, Selçuklu ve Osmanlı mimari formlarını resimsel konturlara dönüştürerek özgün bir estetik üslup yaratmasıyla tanınır.' +
          ' 1970ler sonrası bu çizgide işler üreten sanatçı resimlerinde kalıp-biçim olarak nitelendirdiği biçimleri, siyah üzerinde soyut bir mekânsal boyut içerisinde işler.' +
          ' Anıtsal mimarinin, iç uzamını dış dünyaya açan sivri kemerli kapı ve pencere motifinden yola çıkılarak oluşturulan uyum, sanatçının sözleriyle bir kalıp biçime dayanır.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=8',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Adnan Çoker Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ADNAN ÇOKER HAKKINDA',
        ARTIST_NAME: 'Adnan Çoker',
        ARTIST_SHORT_NAME: 'A. Çoker',
        ARTIST_BORN_DEATH: '1927 - 2022',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BURC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BURC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/BURC/0.d.webp?w=1200&h=1200',
            './assets/art_products/BURC/1.d.webp?w=1200&h=1200',
            './assets/art_products/BURC/2.d.webp?w=1200&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BURC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BURC/0.m.webp?w=450&h=450',
            './assets/art_products/BURC/1.m.webp?w=450&h=450',
            './assets/art_products/BURC/2.m.webp?w=450&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BURC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BURC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BURC/-1.m.thumbnail.webp'
    },
    FLOW: {
        NAME: 'Ayçiçekleri ve Kedi',
        NAME_UPPERCASE: 'AYÇİÇEKLERİ VE KEDİ',
        YEAR: '-',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '100cm x 98cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Orhan Peker, 27 Mayıs 1927\'de Trabzon\'da doğmuş ve 28 Mayıs 1978\'de İstanbul\'da vefat etmiştir. İlkokul eğitimini Trabzon\'da tamamladıktan sonra, lise eğitimine İstanbul\'da Avusturya Lisesi\'nde devam etmiştir. ' +
          '1946-1951 yılları arasında İstanbul Devlet Güzel Sanatlar Akademisi\'nde Bedri Rahmi Eyüboğlu\'nun atölyesinde ve Fransa\'da resim eğitimi almıştır. ' +
          '1947 yılında, sanatçı arkadaşlarıyla birlikte "Onlar Grubu"nu kurmuştur.',
        ROUTER_LINK: {
            TEXT: 'Devamı için buraya tıklayınız.',
            LINK: {url: '/about-artist', artist_announcement_index: 23, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=23',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'Orhan Peker Hakkında',
        ARTIST_ABOUT_UPPERCASE: 'ORHAN PEKER HAKKINDA',
        ARTIST_NAME: 'Orhan Peker',
        ARTIST_SHORT_NAME: 'O. Peker',
        ARTIST_BORN_DEATH: '1927 - 1978',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/FLOW/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/FLOW/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/FLOW/0.d.webp?w=1164&h=1200',
            './assets/art_products/FLOW/1.d.webp?w=1200&h=1225'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/FLOW/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/FLOW/0.m.webp?w=436&h=450',
            './assets/art_products/FLOW/1.m.webp?w=450&h=459'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/FLOW/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/FLOW/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/FLOW/-1.m.thumbnail.webp'
    },
    ...test_art_products_config_tr,
};
